/* eslint-disable indent */
import { notification } from "antd";
import { StreamFile, UploadFileInfo } from "common/define";
import { GlobalState } from "common/global";
import DashboardHelper, { loadFiles } from "helper/dashboard.helper";
import React, { useCallback, useContext, useEffect, useReducer } from "react";
import Utils from "utils/utils";
import { useContextBoostrap } from "./boostrap.context";

const mapOptionSelectedSearch: Record<number, { op: ItemRadioFilter, search: string }> = {
    0: { op: 'All', search: '' },
    1: { op: 'PDF', search: 'pdf' },
    2: { op: 'RVT', search: 'rvt' },
    3: { op: 'DWG', search: 'dwg' },
    4: { op: 'DOC', search: 'doc' }
}
export type ItemRadioFilter = 'All' | 'PDF' | 'RVT' | 'DWG' | 'DOC';
type ContextType = {
    visible: boolean,
    event?: React.MouseEvent,
    itemSelected?: StreamFile,
    from?: 'file' | 'folder'
}
export interface ListOpenFileContextState {
    state: State,
    dispatchContext: React.Dispatch<ActionContext>,
    callActions: (action: CallActionsListType) => void,
}
export interface State {
    loading: boolean,
    scrollIdx: number,
    isUpload: boolean,
    searchText: string,
    folders: StreamFile[],
    currentPath: string,
    uploadedFiles: UploadFileInfo[],
    optionSelected: ItemRadioFilter,
    arrSelected: StreamFile[],
    dataContext: ContextType,
    recentUploadFile: StreamFile | null,
    dataDialogFolder: {
        visible: boolean,
        data: unknown
    },
    loadingFiles: StreamFile[],
    cacheOptionsVisible: boolean,
    includes2D: boolean,
    includesProps: boolean,
}
type ClearCacheType = 'single' | 'multiple';
export type ActionContext =
    { type: 'SET_LOADING', payload: boolean } |
    { type: 'SET_SCROLL_IDX', payload: number } |
    { type: 'SET_IS_UPLOAD', payload: boolean } |
    { type: 'SEARCH', payload: string } |
    { type: 'FILTER_OPTION', payload: number } |
    { type: 'SELECT_FOLDER', payload: StreamFile } |
    { type: 'SELECT_ITEM', payload: StreamFile } |
    { type: 'DATA_CONTEXT', payload: ContextType } |
    { type: 'ARR_SELECTED', payload: StreamFile[] } |
    { type: 'VISIBLE_DIALOG', payload: { visible: boolean, data: unknown } } |
    { type: 'FOLDER_SELECT', payload: StreamFile } |
    { type: 'FIRST_LOAD_FILE' } |
    { type: 'OPEN_FILE_LOCATION', payload: StreamFile } |
    { type: 'UPLOAD_FILE', payload: UploadFileInfo } |
    { type: 'SET_RECENT_UPLOADED_FILE', payload: StreamFile | null } |
    { type: 'SET_LOADING_FILES', payload: StreamFile[] } |
    { type: 'SET_VISIBLE_CACHE_OPTIONS', payload: boolean } |
    { type: 'SET_INCLUDES_2D', payload: boolean } |
    { type: 'SET_INCLUDES_PROPS', payload: boolean };

export type CallActionsListType =
    { type: 'add-folder', val: string } |
    { type: 'clear-cache', val: ClearCacheType };

/** define context, functions */
export const ListOpenFileContext = React.createContext<ListOpenFileContextState | undefined>(undefined);
export function useContextListOpenFile(): ListOpenFileContextState {
    return useContext(ListOpenFileContext) as ListOpenFileContextState
}

function reducerContext(state: State, action: ActionContext) {
    switch (action.type) {
        case 'SET_LOADING': {
            return Utils.setPartialState(state, { loading: action.payload })
        }
        case 'SET_SCROLL_IDX': {
            return Utils.setPartialState(state, { scrollIdx: action.payload })
        }
        case 'SET_IS_UPLOAD': {
            return Utils.setPartialState(state, { isUpload: action.payload })
        }
        case 'UPLOAD_FILE': {
            const val = action.payload;
            const { uploadedFiles } = state;
            //uploadedFiles.push(val);
            const index = uploadedFiles.findIndex(item => item.filename === val.filename);
            if (index !== -1) {
                uploadedFiles[index] = val;
            }
            else {
                uploadedFiles.push(val);
            }
            const data: Partial<State> = {
                uploadedFiles: [...uploadedFiles]
            }
            return Utils.setPartialState(state, data);
        }
        case 'SET_RECENT_UPLOADED_FILE': {
            const val = action.payload;
            const data: Partial<State> = {
                recentUploadFile: val
            }
            return Utils.setPartialState(state, data);
        }
        case 'SEARCH': {
            const val = action.payload;
            const data: Partial<State> = {
                searchText: val
            }
            // Nam_dd comment that:
            // if (folders.length > 1) {  
            //     const temp = folders[0];
            //     const path = DashboardHelper.getPath();
            //     data.folders = [temp];
            //     data.currentPath = path
            // }
            if (val.toLowerCase() === 'dwg') {
                data.optionSelected = 'DWG'
            } else if (val.toLowerCase() === 'rvt') {
                data.optionSelected = 'RVT'
            } else if (val.toLowerCase() === 'pdf') {
                data.optionSelected = 'PDF'
            } else if (val.toLowerCase() === 'doc' || val.toLowerCase() === 'docx') {
                data.optionSelected = 'DOC'
            } else {
                data.optionSelected = 'All'
            }
            return Utils.setPartialState(state, data)
        }
        case 'FILTER_OPTION': {
            const val = action.payload;
            const { op, search } = mapOptionSelectedSearch[val];
            const data: Partial<State> = {
                optionSelected: op,
                searchText: search
            }
            // nam_dd commented that
            //const {folders} = state;
            //if (folders.length > 1) {
            //    const temp = folders[0];
            //    const path = DashboardHelper.getPath();
            //    data.folders = [temp];
            //    data.currentPath = path
            //}
            state.recentUploadFile && (data.recentUploadFile = null);
            return Utils.setPartialState(state, data)
        }
        case "FIRST_LOAD_FILE": {
            const path = DashboardHelper.getPath();
            const root: StreamFile = {
                filename: 'Root',
                baseFileId: '',
                baseMajorRev: 0,
                baseMinorRev: 0,
                isChecked: false,
                status: null,
                child: [],
                isDirectory: true,
                createdDate: '',
                cacheStatus: 0,
                modelFileId: '',
                id: '',
                originalFilePath: '.',
                convertOptions: {
                    convert3DModel: 1,
                    convert2DSheet: 0,
                    extractProperties: 1,
                    childModels: 0
                },
                drawingConvertStatus: {
                    convert2DSheet: 0,
                    convert3DModel: 0,
                    extractProperties:0
                }
            };
            return Utils.setPartialState(state, {
                currentPath: path,
                folders: [...state.folders, root]
            })
        }
        case 'SELECT_FOLDER': {
            const folder = action.payload;
            const { searchText, folders } = state;
            if (searchText !== '') {
                // set empty string for search input when click to a folder
                state.searchText = '';
            }
            const idx = folders.findIndex(val => val.baseFileId === folder.baseFileId);
            const temp = folders.slice(0, idx + 1);
            let path = DashboardHelper.getPath();
            if (folder.filename !== 'Root') {
                path = `${folder.originalFilePath}/${folder.filename}`;
            }
            const data: Partial<State> = {
                currentPath: path,
                folders: [...temp],
                optionSelected: "All"
            }
            state.recentUploadFile && (data.recentUploadFile = null);
            return Utils.setPartialState(state, data)
        }
        case "SELECT_ITEM": {
            const item = action.payload;
            const arrSelected = state.arrSelected;
            const arrSelectedMapId = arrSelected.map(f => f.baseFileId);
            let arr = [...arrSelected, item];
            if (arrSelectedMapId.includes(item.baseFileId)) {
                arr = arrSelected.filter(it => it.baseFileId !== item.baseFileId)
            }
            return Utils.setPartialState(state, { arrSelected: arr, isUpload: false })
        }
        case "DATA_CONTEXT": {
            const data = { ...state.dataContext, ...action.payload };
            return Utils.setPartialState(state, { dataContext: data })
        }
        case "ARR_SELECTED": {
            return Utils.setPartialState(state, { arrSelected: action.payload })
        }
        case "VISIBLE_DIALOG": {
            return Utils.setPartialState(state, { dataDialogFolder: action.payload })
        }
        case "FOLDER_SELECT": {
            const item = action.payload;
            let path = DashboardHelper.getPath();
            if (item.filename !== 'Root') {
                path = `${item.originalFilePath}/${item.filename}`;
            }
            return Utils.setPartialState(state, {
                searchText: '',
                currentPath: path,
                folders: [...state.folders, item]
            })
        }
        case "OPEN_FILE_LOCATION": {
            const item = action.payload;
            const root: StreamFile = {
                filename: 'Root',
                baseFileId: '',
                baseMajorRev: 0,
                baseMinorRev: 0,
                isChecked: false,
                status: null,
                child: [],
                isDirectory: true,
                createdDate: '',
                cacheStatus: 0,
                modelFileId: '',
                id: '',
                originalFilePath: '.',
                convertOptions: {
                    convert3DModel: 1,
                    convert2DSheet: 0,
                    extractProperties: 1,
                    childModels: 0
                },
                drawingConvertStatus: {
                    convert2DSheet: 0,
                    convert3DModel: 0,
                    extractProperties:0
                }
            };
            let path = DashboardHelper.getPath();
            const lstFolderPath = item.originalFilePath.split('/');
            const lstFolders = GlobalState.getNestedFolderFile(lstFolderPath);
            if (item.filename !== 'Root') {
                path = `${item.originalFilePath}`;
            }
            return Utils.setPartialState(state, {
                optionSelected: 'All',
                searchText: '',
                currentPath: path,
                folders: [root, ...lstFolders]
            })
        }
        case "SET_LOADING_FILES": {
            return Utils.setPartialState(state, { loadingFiles: action.payload })
        }
        case 'SET_VISIBLE_CACHE_OPTIONS': {
            return Utils.setPartialState(state, { cacheOptionsVisible: action.payload })
        }
        case 'SET_INCLUDES_2D': {
            return Utils.setPartialState(state, { includes2D: action.payload })
        }
        case 'SET_INCLUDES_PROPS': {
            return Utils.setPartialState(state, { includesProps: action.payload })
        }
        default:
            return state;
    }
}

const initStateReducerView: State = {
    loading: false,
    scrollIdx: -1,
    isUpload: false,
    searchText: '',
    folders: [],
    currentPath: '',
    uploadedFiles: [],
    optionSelected: 'All',
    arrSelected: [],
    dataContext: { visible: false },
    recentUploadFile: null,
    dataDialogFolder: {
        visible: false,
        data: null
    },
    loadingFiles: [],
    cacheOptionsVisible: false,
    includes2D: false,
    includesProps: true
}

export function useReducerFileListOpen(): ListOpenFileContextState {
    const [state, dispatchContext] = useReducer(reducerContext, initStateReducerView);
    const { dispatch: dispatchBoostrap, openConfirm } = useContextBoostrap();

    useEffect(() => {
        loadFiles().then(fileLoads => {
            if (fileLoads) {
                GlobalState.setAllFile(fileLoads);
                dispatchContext({ type: "FIRST_LOAD_FILE" })
            }
        }).catch();
    }, [])

    function updateStatusFileExtends(type: ClearCacheType, singleSelected: StreamFile | undefined, arrSelected: StreamFile[]) {
        const { currentPath, searchText } = state;
        const all = GlobalState.getAllFile();
        let allUpdates: StreamFile[] = [];
        let arrSelect: StreamFile[] = [];
        if (type === 'single' && singleSelected) {
            const temp = all.find(val => val.id === singleSelected.id);
            if (temp) {
                const fileClearCache: StreamFile = { ...temp, cacheStatus: 0, createdDate: "" };
                allUpdates = all.map(x => (x.id === singleSelected.id) ? fileClearCache : x);
                const arraySelectedUpdates = arrSelected.map(x => (x.baseFileId === fileClearCache.baseFileId) ? fileClearCache : x);
                arrSelect = [...arraySelectedUpdates];
            }
        } else if (type === 'multiple') {
            const arrSelectedMapId = arrSelected.map(f => f.baseFileId);
            for (let i = 0; i < all.length; i++) {
                if (arrSelectedMapId.indexOf(all[i].baseFileId) === -1) {
                    allUpdates.push(all[i]);
                }
                else {
                    const file: StreamFile = { ...all[i], cacheStatus: 0, createdDate: "" };
                    allUpdates.push(file);
                    arrSelect.push(file);
                }
            }
        }

        dispatchContext({ type: 'ARR_SELECTED', payload: arrSelect });
        GlobalState.setAllFile(allUpdates);
        const list = GlobalState.sortFiles(currentPath, searchText);
        GlobalState.updateListFiles(list);
    }

    const callActions = useCallback((action: CallActionsListType) => {
        switch (action.type) {
            case 'clear-cache': {
                const type = action.val;
                const content = `Are you sure to delete ${type === 'single' ? 'cache' : 'caches'}?`;
                openConfirm({ content }).subscribe(val => {
                    if (val === 'yes') {
                        if (type === 'single' && state.dataContext.itemSelected?.cacheStatus === 0) return
                        dispatchContext({ type: 'SET_LOADING', payload: true });
                        const arrSelected = state.arrSelected.filter(f => f.cacheStatus !== 0).map(f => f.id);
                        const selected = state.dataContext.itemSelected ? [state.dataContext.itemSelected.id] : [''];
                        const arrId = type === 'single' ? selected : arrSelected;

                        DashboardHelper.clearCaches(arrId).subscribe({
                            next: (val) => updateStatusFileExtends(type, state.dataContext.itemSelected, state.arrSelected),
                            error: (err) => {
                                notification.open({
                                    placement: 'bottomRight',
                                    message: 'Clear cache',
                                    description: 'Clear cache fail!',
                                });
                                dispatchContext({ type: 'SET_LOADING', payload: false });
                            },
                            complete: () => dispatchContext({ type: 'SET_LOADING', payload: false })
                        })
                    }
                })
                break;
            }
            case 'add-folder': {
                const nameFolder = action.val;
                let link = nameFolder;
                const { itemSelected } = state.dataContext;
                if (itemSelected) {
                    let path = itemSelected.originalFilePath;
                    if (state.dataDialogFolder.data !== 'root') {
                        link = `${itemSelected.originalFilePath}/${itemSelected.filename}/${nameFolder}`;
                        path = `${itemSelected.originalFilePath}/${itemSelected.filename}`;
                    }
                    DashboardHelper.addFolderExtend(link).subscribe({
                        next: (result) => {
                            const newFolder: StreamFile = {
                                filename: nameFolder,
                                status: {},
                                isDirectory: true,
                                baseFileId: Communicator.UUID.create(),
                                child: [],
                                cacheStatus: 0,
                                baseMajorRev: 0,
                                baseMinorRev: 0,
                                isChecked: false,
                                createdDate: '',
                                modelFileId: '',
                                id: '',
                                originalFilePath: path,
                                convertOptions: {
                                    convert3DModel: 1,
                                    convert2DSheet: 0,
                                    extractProperties: 1,
                                    childModels: 0
                                },
                                drawingConvertStatus: {
                                    convert2DSheet: 0,
                                    convert3DModel: 0,
                                    extractProperties:0
                                }
                            };
                            const { searchText, currentPath } = state;
                            const { all } = GlobalState.addFile(newFolder, currentPath, searchText);
                            const indx = all.findIndex(item => item.baseFileId === itemSelected.baseFileId && item.originalFilePath === itemSelected.originalFilePath);
                            if (indx !== -1) all[indx].child.push({ ...newFolder });
                            // if (index !== -1) {
                            //     dispatchContext({ type: 'SET_SCROLL_IDX', payload: index });
                            // }
                            GlobalState.updateListFiles(all);
                        }
                    })
                }
                break
            }
            default: return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchBoostrap, openConfirm, state])

    return {
        state,
        dispatchContext,
        callActions
    }
}