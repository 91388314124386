import Popover, { PopoverPosition } from '@material-ui/core/Popover';
import { StreamFile } from 'common/define';
import CDialog from 'components/CDialog';
import CScrollVirtual from 'components/CScrollVirtual';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React, { useEffect, useRef } from 'react';
import { FixedSizeList as ListWindow } from 'react-window';
import ContextMenuItem from './ContextMenuItem';
import FolderDialogHandle from './FolderDialogHandle';
import RowTable from './RowBodyTable';

interface PropsBodyTableFile extends Props {
    lists: StreamFile[];
}

const BodyTableFile = (props: PropsBodyTableFile): JSX.Element => {
    const refScrollList = useRef<ListWindow | null>(null);
    const lists: StreamFile[] = props.lists;
    const {state: {scrollIdx, dataDialogFolder, dataContext, searchText}, dispatchContext} = useContextListOpenFile();

    useEffect(() => {
        if (refScrollList && refScrollList.current) {
            const idx = scrollIdx;
            refScrollList.current.scrollToItem(idx, "center");
        }
    }, [refScrollList, scrollIdx])

    function handleCloseDialog() {
        dispatchContext({type: 'VISIBLE_DIALOG', payload: {visible: false, data: false}})
    }
    function getPositionFromMouseEvent(mouseEventInput: React.MouseEvent | undefined): PopoverPosition | undefined {
        if (mouseEventInput) {
            return {
                top: mouseEventInput.clientY,
                left: mouseEventInput.clientX
            }
        }
        return undefined;
    }
    function handleCloseContext() {
        dispatchContext({type: 'DATA_CONTEXT', payload: {visible: false}})
    }
    return (
        <div className="w-full h-full">
            <CScrollVirtual
                ref={refScrollList}
                dataSource={lists}
                itemKey={(i: number, data: StreamFile[]) => data[i].baseFileId}
                itemRender={RowTable}
                sizeItem={searchText ? 50 : 40} />
            <CDialog 
                visible={dataDialogFolder.visible} 
                hasMaskBackground={false}
                onCancel={handleCloseDialog}
                content={<FolderDialogHandle handleCloseDialog={handleCloseDialog} />}/>
            <Popover 
                open={dataContext.visible} 
                anchorReference="anchorPosition" 
                anchorPosition={getPositionFromMouseEvent(dataContext.event)} 
                onClose={handleCloseContext}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <ContextMenuItem handleCloseContext={handleCloseContext} />
            </Popover>
        </div>
    )
}

export default BodyTableFile;