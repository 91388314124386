import { StreamFile } from 'common/define';
import IconSvg from "components/CIconSvg";
import { useContextListOpenFile } from 'context/listopenfile.context';
import React from 'react';
import { CTooltip } from 'components/CTooltip'

type PropsRow = StreamFile;

const RowTableFolderComponent = (props: PropsRow): JSX.Element => {
    const {state: {searchText}, dispatchContext} = useContextListOpenFile();

    const onClickFolder = () => {
        dispatchContext({type: "FOLDER_SELECT", payload: props})
    }

    const openFileLocation = () => {
        dispatchContext({type: "OPEN_FILE_LOCATION", payload: props})
    }
    function handleContext(event: React.MouseEvent) {
        dispatchContext({type: 'DATA_CONTEXT', payload: {
            event,
            visible: true,
            from: 'folder',
            itemSelected: props
        }})
    }

    return (
        <div className="grid grid-cols-12" onContextMenu={handleContext}>
            <div className="col-span-6">
                <div className="grid grid-cols-12">
                    <div className="col-span-1">
                        <IconSvg name='folder' svgSize="medium" colorSvg='default' />
                    </div>
                    <div className="col-span-11">
                        <div>
                            <p className="cursor-pointer mb-0 truncate ..." onClick={onClickFolder}>{props.filename}</p>
                        </div>
                        <div>
                            {searchText !== '' &&
                                <div className="flex justify-start text-blue-500">
                                    <CTooltip title={props.originalFilePath}>
                                        <p className="mb-0 cursor-pointer hover:underline truncate ..." onClick={openFileLocation}>{props.originalFilePath}</p>
                                    </CTooltip>
                                </div>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
const RowTableFolder = React.memo(RowTableFolderComponent);
export default RowTableFolder;