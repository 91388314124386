import { MarkupCircleItem } from '../markup-items/markup.circle.item';
import { MarkupBaseOperator } from './markup.base.operator';
export class MarkupCircleOperator extends MarkupBaseOperator {
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
    }

    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        this._markupItem = new MarkupCircleItem(this._viewer);
        this._markupItem.setLineFormat(this._lineColor, this._lineWeight, this._lineOpacity, this._fillColor, this._fillColorOption, this._lineStyle);
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
        const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
        if (cameraPoint) {
            (this._markupItem as MarkupCircleItem).setPosition(cameraPoint);
            (this._markupItem as MarkupCircleItem).setRadiusPoint(cameraPoint);
        }
    }

    public updateMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        if (this._markupItem) {
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupCircleItem).setRadiusPoint(cameraPoint);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }
}

