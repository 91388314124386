/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GlobalState } from "common/global";
import WalkKeyboardOperator from "../custom-operator/walk-keyboard.operator";
import CPanOperator from "../extends/CPanOperator";
import BaseOperator from "./base.operator";
import { SetOperator } from "./operator3D.helper";

export default class WalkOperator extends BaseOperator {
    setOperator!: SetOperator;
    keyboardWalkOperator: WalkKeyboardOperator;
    handleOperator: any;
    constructor(viewer: Communicator.WebViewer) {

        super(viewer);
        this.Name = 'walkThrough';
        this.keyboardWalkOperator = new WalkKeyboardOperator(viewer);
        this.handleOperator = viewer.operatorManager.registerCustomOperator(
            this.keyboardWalkOperator,
        );
        this.listKeyUse.push('w|false|false');
        this.listKeyUse.push('a|false|false');
        this.listKeyUse.push('s|false|false');
        this.listKeyUse.push('d|false|false');
        this.listKeyUse.push('x|false|false');
        this.listKeyUse.push('c|false|false');
        this.listKeyUse.push('q|false|false');
        this.listKeyUse.push('e|false|false');
        this.listKeyUse.push('r|false|false');
        this.listKeyUse.push('f|false|false');
        this.listKeyUse.push('arrowleft|false|false');
        this.listKeyUse.push('arrowright|false|false');
        this.listKeyUse.push('arrowup|false|false');
        this.listKeyUse.push('arrowdown|false|false');

    }
    /**
   * Active
   *
   */
    public async Active() {
        if (this.Status === false) {

            this.Viewer.operatorManager.remove(Communicator.OperatorId.Navigate)
            this.Viewer.operatorManager.push(this.handleOperator);
            GlobalState.mapOperatorCursor.set('walkThrough', 'url("/assets/icons/walk.svg"), auto');
            GlobalState.primaryOperator.set(this.Viewer, 'walkThrough');
            super.SetCursor('url("/assets/icons/walk.svg"), auto');
            this.Viewer.operatorManager.push(Communicator.OperatorId.Pan)
            const pan = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan);
            if (pan instanceof CPanOperator) {
                pan.setInWalkMode(true)
            }
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Middle);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).setMapping(Communicator.Button.Middle);

            this.Status = true;
        }
    }

    /**
     * InActive
     *
     */
    public async InActive() {
        if (this.Status === true) {
            this.keyboardWalkOperator && this.keyboardWalkOperator.onDeactivate();
            await this.keyboardWalkOperator.resetDefaultWalkSpeeds()
            this.handleOperator && this.Viewer.operatorManager.unregisterCustomOperator(this.handleOperator);
            this.Viewer.floorplanManager.deactivate()
            const pan = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan);
            if (pan instanceof CPanOperator) {
                pan.setInWalkMode(false)
            }
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).clearMapping();

            // không thể reset default operator, vì các operator đã được custome ở walk.
            // add zoom operator vào, vì sau khi thoát mode bị mất zoom operator
            this.Viewer.operatorManager.push(Communicator.OperatorId.Zoom)
            // this.ResetDefautOperators()

            // this.Viewer.operatorManager.remove(Communicator.OperatorId.Pan)
            // setTimeout(() => {
            //     this.fixByOldCamera(camera);
            // }, 100);
            // a: pos, b: target
            // const cam = this.Viewer.view.getCamera().copy();
            // const ab = this.cameraBk.getTarget().copy().subtract(this.cameraBk.getPosition());
            // const at = cam.getPosition().copy().add(ab);
            // this.cameraBk.setPosition(cam.getPosition());
            // this.cameraBk.setTarget(at);
            // this.Viewer.view.setCamera(this.cameraBk);
            this.Status = false;
        }
    }
}
