import { LineStyle, MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
interface PointColor {
    r: number;
    b: number;
    g: number;
    a: number;
}

export class BaseElementCanvas {
    public baseCanvas: HTMLCanvasElement = document.createElement('canvas');
    public textboxCanvas: HTMLTextAreaElement | HTMLDivElement | null = null;
    public noteCanvas: HTMLImageElement | null = null;
    public pinMarkerCanvas: HTMLDivElement | null = null;
    public firstPoint = Communicator.Point2.zero();
    public strokeWidth = 5;
    public strokeColor: Communicator.Color | undefined = undefined;
    public fillColor: Communicator.Color = new Communicator.Color(0, 255, 0);
    public fillType: MarkupFillType = MarkupFillType.None;
    public offSet = 2;
    public _lineStyle: LineStyle = LineStyle.continous;
    public lineJoin: CanvasLineJoin = 'miter';
    public lineCap: CanvasLineCap = 'square';
    public fillOpacity = 1;
    public size = Communicator.Point2.zero();
    private _rotation = 0;
    setFirstPoint(point: Communicator.Point2): void {
        this.firstPoint = point;
    }

    getFirstPoint(): Communicator.Point2 {
        return this.firstPoint;
    }
    setSize(size: Communicator.Point2): void {
        this.size = size;
        if (!this.size) return;
        const d = (size.length() / 2) / (3 * Math.E);
        this.offSet = d > 4 * this.strokeWidth ? 2 * d : this.strokeWidth * 8;
    }

    getSize(): Communicator.Point2 {
        return this.size;
    }

    setStrokeWidth(stroke: number): void {
        this.strokeWidth = stroke;
        if (!this.size) return;
        const d = (this.size.length() / 2) / (3 * Math.E);
        this.offSet = d > 4 * this.strokeWidth ? 2 * d : this.strokeWidth * 8;
    }

    getStrokeWidth(): number {
        return this.strokeWidth;
    }

    setStrokeColor(color: Communicator.Color): void {
        this.strokeColor = color;
    }

    setFillColor(color: Communicator.Color): void {
        this.fillColor = color;
    }

    setFillType(fillType: MarkupFillType): void {
        this.fillType = fillType;
    }

    setFillOpacity(fillOpacity: number): void {
        this.fillOpacity = fillOpacity;
    }

    setLineStyle(style: LineStyle): void {
        if (style) {
            this._lineStyle = style;
        } else {
            this._lineStyle = LineStyle.continous;
        }
    }

    getLineStyle(): LineStyle {
        return this._lineStyle;
    }

    hit(point: Communicator.Point2): boolean {
        function pointHasColor(pointColor: PointColor) {
            const hexColor = MathHelper.rgbToHex(pointColor.r, pointColor.g, pointColor.b);
            return (hexColor === '#000000' && pointColor.a !== 0) || hexColor !== '#000000';
        }
        if (!this.baseCanvas || !this.baseCanvas.style.left || !this.baseCanvas.style.top) return false;

        const pos = new Communicator.Point2(point.x - parseInt(this.baseCanvas.style.left, 10),
            point.y - parseInt(this.baseCanvas.style.top, 10));

        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return false;
        const imgData = ctx.getImageData(pos.x - 5, pos.y - 5, 2 * 5, 2 * 5).data;
        const dataLen = imgData.length / 4;
        for (let i = 0; i < dataLen; i++) {
            const pointRGB: PointColor = {
                r: imgData[4 * i + 0], g: imgData[4 * i + 1], b: imgData[4 * i + 2], a: imgData[4 * i + 3],
            };
            if (pointHasColor(pointRGB)) {
                return true;
            }
        }

        return false;
    }

    getRotation(): number {
        return this._rotation;
    }
    setRotation(rotation: number): void {
        this._rotation = rotation;
    }
}