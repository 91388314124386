
import {SystemConfig} from "../common/type-state";


export enum APIHosts {
    Identity = 'hostIdentity',
    Stream = 'hostStream',
    File = 'hostFile',
    Conversion = 'hostConversion',
    Bim = 'hostBim'
}

export class ApiReduxHelpers {
    config = { } as SystemConfig;
    getHost = (apiHost: APIHosts):string => {

        if (!this.config[apiHost]) {
            return ""
        }

        return this.config[apiHost]
    }
    setConfig = (config: SystemConfig):this => {
        this.config=config;
        return this;
    }
}
