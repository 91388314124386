import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined, SettingOutlined } from '@ant-design/icons';
import { Checkbox, Modal } from 'antd';
import { CTooltip } from 'components/CTooltip'
import AceEditor from 'react-ace';
import clsx from 'clsx';
import { StreamFile } from 'common/define';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React, { useState } from 'react';
import Utils from 'utils/utils';

// Import theme and mode
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'

const RowBodySelectList = (props: StreamFile): JSX.Element => {
    const {state: {arrSelected}, dispatchContext} = useContextListOpenFile();
    const arrSelectedMapId = arrSelected.map(f => f.baseFileId);
    const header = {
        "HEADER": {
            "TOPLEFTTEXT": "",
            "TOPCENTERTEXT": "",
            "TOPRIGHTTEXT": "",
            "BOTTOMLEFTTEXT": "",
            "BOTTOMCENTERTEXT": "",
            "BOTTOMRIGHTTEXT": "",
            "DISABLEHEADERS": false
        },
        "WATERMARK": {
            "TEXT": "Watermark",
            "FONTNAME": "Arial",
            "FONTSIZE": 24,
            "FONTSTYLE": 1,
            "ORIENTATION": 1,
            "POSITION": "Center",
            "XFACTOR": 0.5,
            "YFACTOR": 0.5,
            "COLOR": "#0f0f0f",
            "ALPHA": 0.8,
            "DISABLEWATERMARK": true
        }
    }

    const [visible, setVisible] = useState(false);
    const [headerValue, setHeaderValue] = useState(JSON.stringify(header, null, 2));

    function onChange() {
        dispatchContext({type: 'SELECT_ITEM', payload: props})
    }

    const openSettingPopup = () => {
        setVisible(true);
    }

    function onOkModal() {
        setVisible(false);
        localStorage.setItem('headerSetting', headerValue);
    }

    const changeAceEditor = (value: string) => {
        setHeaderValue(value);
    }

    return (

        <div className={
            clsx(
                Utils.convertStatus(props.cacheStatus) === 'Cached' && 'bg-gray-200'
            )
        }>
            <div className="grid grid-cols-12">
                <div className='col-span-10 cursor-pointer' onClick={onChange}>
                    <div className="grid grid-cols-12">
                        <div className='col-span-1 pt-4 flex justify-center'>
                            {(props.cacheStatus === 2 && !props.isDirectory) &&
                            <CTooltip title="Cached">
                                <CheckCircleOutlined />
                            </CTooltip>
                            }
                            {(props.cacheStatus === 1 && !props.isDirectory) &&
                            <CTooltip title="Caching">
                                <WarningOutlined />
                            </CTooltip>
                            }
                            {((!props.cacheStatus || props.cacheStatus === 3) && !props.isDirectory) &&
                            <CTooltip title="No-cache">
                                <ExclamationCircleOutlined />
                            </CTooltip>
                            }
                        </div>
                        <div className='col-span-1'></div>
                        <div className='col-span-10'>
                            <div>
                                <div>
                                    {props.filename}
                                </div>
                                <div className="flex justify-start text-blue-500">
                                    {props.originalFilePath}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 cursor-pointer" onClick={openSettingPopup}>
                    <CTooltip title="Setting">
                        <SettingOutlined style={{fontSize: '17px', marginTop: '14px'}}/>           
                    </CTooltip>
                </div>
                <div className="col-span-1 pt-3" onClick={onChange}>
                    <Checkbox
                        checked={arrSelectedMapId.includes(props.baseFileId)}
                        onClick={e => e.stopPropagation()}
                        onChange={onChange}
                    ></Checkbox>
                </div>
            </div>
            <Modal
                title={`${props.filename} - Header & watermark option`}
                centered
                visible={visible}
                onOk={onOkModal}
                onCancel={() => setVisible(false)}
                okText='Save'
                width={550}
            >
                <AceEditor
                    mode="json"
                    theme="monokai"
                    name="UNIQUE_ID_OF_DIV"
                    value={headerValue}
                    editorProps={{
                        $blockScrolling: true
                    }}
                    onChange={value => changeAceEditor(value)}
                    width='500px'
                />
            </Modal>
        </div>

    )
}

export default RowBodySelectList;