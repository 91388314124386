/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileInfo, FileInfoResponse, PayloadOriginFile, StreamFile } from "common/define";
import { SystemConstants } from "common/system-constants";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import Utils from "utils/utils";
import HttpClient from "./http-client";
import {APIHosts, ApiReduxHelpers} from "./reduxHelpers";

class ConversionApiController extends ApiReduxHelpers {

    constructor() {
        super();
    }
    ApiHost = APIHosts.Conversion
    getCacheByListFile(
        data: PayloadOriginFile[]
    ): Observable<FileInfoResponse[]> {
        return HttpClient.post(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_LIST_FILE}`,
            data
        ).pipe(map((res) => res as FileInfoResponse[]));
    }
    updateModelId(
        data: PayloadOriginFile
    ): Observable<number> {
        return HttpClient.put(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_CONVERSION.API.CACHE.UPDATE_MODEL_FILE_ID}`,
            data
        ).pipe(map((res) => res as number));
    }
    getAllCache(): Observable<any[]> {
        return HttpClient.get(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_CONVERSION.API.CACHE.GET_ALL}`
        ).pipe(map((res) => res as any[]));
    }
    cacheFile(data: StreamFile) {
        const path = "?overwrite=true&ignore_line_weight=1";
        return HttpClient.post(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_CONVERSION.API.CACHE.GET}${path}`,
            data
        ).pipe(map((res) => res as FileInfoResponse));
    }

    uploadFile(data: any, path: string): Observable<string> {
        return HttpClient.upload(
            `${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_CONVERSION.API.FILE.UPLOAD}?path=${path}`,data
        ).pipe(map((res) => res as string));
    }

    clearFileCached(id: string): Observable<number> {
        const newHeaders = {
            Accept: "text/plain"
        };
        const api = SystemConstants.SERVER_CONVERSION.API.CACHE.DELETE(id);
        return HttpClient.delete(`${this.getHost(this.ApiHost)}/${api}`, {headers: newHeaders}).pipe(
            map((res) => res as number)
        );
    }
    addFolder(path: string): Observable<boolean> {
        const data = ``;
        const api = `${SystemConstants.SERVER_CONVERSION.API.FILE.ADD}?path=${path}`;
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${api}`, data).pipe(
            map((res) => res as boolean)
        );
    }
    uploadThumnail(file: FileInfo, thumnailSrc: string): Observable<unknown> {
        const pathObj = {
            baseFileId: file.baseFileId,
            majorVersion: file.baseMajorRev? file.baseMajorRev.toString() : '0',
            minVersion: file.baseMinorRev? file.baseMinorRev.toString() : '0'
        }
        const formData = new FormData();
        formData.append('file', Utils.DataURIToBlob(thumnailSrc), `${Utils.getFileNameWithoutExtension(file.filename)}.png`)
        const param = Utils.parseUrl(pathObj);
        const api = `${SystemConstants.SERVER_STREAM.API.THUMNAIL.UPLOAD}?${param.toString()}`;
        return HttpClient.upload(`${this.getHost(this.ApiHost)}/${api}`, formData).pipe(
            map((res) => res)
        )
    }
}

export const ConversionApi = new ConversionApiController();
