import { IPinMarkerData } from 'common/define';
import Utils from 'utils/utils';
import { MarkupBaseOperator } from '../markup-operators/markup.base.operator';
import { MarkupPinMarkerItem } from './markup.pinmarker.item';

export class MarkupPinMarkerOperator extends MarkupBaseOperator {
    private _mouseDown = false;
    private _pinMarkerData: IPinMarkerData;
    public constructor(
        viewer: Communicator.WebViewer,
        pinMarkerData: IPinMarkerData
    ) {
        super(viewer);
        this.cursorSprite = true;
        this._pinMarkerData = pinMarkerData;
    }

    public async onMouseUp(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (!this.isActive()) return;
        if (event.getButton() === Communicator.Button.Left) {
            event.setHandled(true);
            this._mouseDown = false;
            const position = event.getPosition();
            const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.All);
            let selectionPos: Communicator.Point3 | null = null;
            if (this._viewer.view.getNavCube().insideOverlay(position)) return;
            this._viewer.view.pickFromPoint(position, pickConfig).then(async (selection) => {
                if (selection.getSelectionType() === Communicator.SelectionType.None) {
                    this.finishCommand();
                    return;
                }
                if (selection.isFaceSelection()) {
                    selectionPos = Utils.getRayCastPoint(selection, position, this._viewer);
                } else {
                    selectionPos = selection.getPosition();
                }
                if (!selectionPos) return;
                const pinData = this._pinMarkerData;
                if (pinData.nodes.length === 0) {
                    const pickConfig = new Communicator.PickConfig(
                        Communicator.SelectionMask.Face,
                    );
                    const node = 
                        await this._viewer.view.pickFromPoint(event.getPosition(), pickConfig)
                            .then(selectionItem =>{
                                return selectionItem.getNodeId()
                            })
                    if (node) pinData.nodes = [node]
                    this._viewer.selectPart(node)
                }
                this._markupItem = new MarkupPinMarkerItem(this._viewer, this._pinMarkerData);
                const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
                this._markupItem.setMarkupItemId(itemId);
                (this._markupItem as MarkupPinMarkerItem).setFirstPoint(selectionPos);
                super.onMouseUp(event);
                this.finishCommand();
            })
        }
    }
    finishCommand(): void {
        this._startOperator = false;
        if (this._operatorEndCallback != null) {
            this._operatorEndCallback();
        }
        this._markupItem = null;
    }
    public get pinMarkerData(): IPinMarkerData {
        return this._pinMarkerData;
    }
}

