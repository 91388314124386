/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable indent */
import { KeyCode } from "common/define";
import { MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { innerPad } from "utils/utils";
import { BaseElementCanvas } from "./markup.base-canvas.element";
export class TextboxElementCanvas extends BaseElementCanvas {
    public _textContent = '';
    private _textColor = 'red';
    private _isBold = false;
    private _isItalic = false;
    private _isUnderline = false;
    private _textFontFamily = 'Arial';
    private _textFontSize = 20;
    private _onBlurCallback: any;
    private _onChangeCallback: any;
    private _onAutoSizeCallback: any;
    private _autoSize = Communicator.Point2.zero();
    private _innerPad = innerPad;
    private _canRotateOnFocus = false;
    public get innerPad(): Communicator.Point2 {
        return this._innerPad;
    }
    constructor(
        onBlurCallback: () => void,
        onChangeCallback: () => void,
        onAutoSizeCallback?: () => void,
    ) {
        super();
        this._onBlurCallback = onBlurCallback;
        this._onChangeCallback = onChangeCallback;
        this._onAutoSizeCallback = onAutoSizeCallback;
        this.offSet = 2;
        this.textboxCanvas = document.createElement('textarea');
        if (this.textboxCanvas instanceof HTMLDivElement) {
            this.textboxCanvas.contentEditable = 'true';
            this.textboxCanvas.dataset.placeholder = 'Enter text here...';
        }
        if (this.textboxCanvas instanceof HTMLTextAreaElement) {
            this.textboxCanvas.rows = 1;
            this.textboxCanvas.placeholder = 'Enter text here...';
        }
        this.textboxCanvas.style.position = 'absolute';
        this.textboxCanvas.style.zIndex = '2';
        this.textboxCanvas.style.width = '0';
        this.textboxCanvas.style.height = '0';
        this.textboxCanvas.style.cursor = "auto";
        this.textboxCanvas.style.resize = "none";
        this.textboxCanvas.style.wordWrap = 'break-word';
        this.textboxCanvas.style.background = 'transparent';
        this.textboxCanvas.style.pointerEvents = 'none';
        this.textboxCanvas.spellcheck = false;
        this.textboxCanvas.style.overflow = 'hidden';
    }

    getTextboxCanvas(): HTMLElement {
        this.draw();
        return this.textboxCanvas!;
    }

    draw(): void {
        if (!this.firstPoint || !this.textboxCanvas) return;
        const padding = this.strokeWidth / 2;
        const width = this.size.x - padding * 2;
        const height = this.size.y - padding * 2;
        this.textboxCanvas.style.left = `${this.firstPoint.x + padding}px`;
        this.textboxCanvas.style.top = `${this.firstPoint.y + padding}px`;
        this.textboxCanvas.style.width = `${width}px`;
        this.textboxCanvas.style.height = `${height}px`;
        this.textboxCanvas.style.color = this._textColor;

        if (this.textboxCanvas instanceof HTMLDivElement) {
            this.textboxCanvas.classList.add('editable-placeholder');
        }
        if (this.textboxCanvas instanceof HTMLTextAreaElement) {
            this.textboxCanvas.style.setProperty('--placeholder-color', this._textColor);
            this.textboxCanvas.classList.add('textbox-placeholder');
        }
        this.textboxCanvas.style.padding = `${Math.min(height, this._innerPad.y)}px ${Math.min(width, this._innerPad.x)}px`;
        this.textboxCanvas.style.lineHeight = `${this._textFontSize * 1.5}px`;
        this.textboxCanvas.style.transform = `rotate(${this.getRotation()}deg)`;

        if (this.strokeColor) this.textboxCanvas.style.outline = `${this.strokeWidth}px solid rgb(${this.strokeColor.r},${this.strokeColor.g},${this.strokeColor.b})`;
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
            if (color) {
                this.textboxCanvas.style.backgroundColor = color;
            }
        } else {
            this.textboxCanvas.style.backgroundColor = 'transparent';
        }

        this.textboxCanvas.style.fontWeight = this._isBold ? 'bold' : 'normal';
        this.textboxCanvas.style.textDecoration = this._isUnderline ? 'underline' : 'none';
        this.textboxCanvas.style.fontStyle = this._isItalic ? 'italic' : 'normal';
        this.textboxCanvas.style.fontFamily = this._textFontFamily;
        this.textboxCanvas.style.fontSize = `${this._textFontSize}px`;
        this.textboxCanvas.innerHTML = this._textContent;

        this.textboxCanvas.onfocus = (event: any) => {
            event.stopPropagation();
            if (!this.textboxCanvas) return;
            if (!this._onAutoSizeCallback) {
                this.textboxCanvas.classList.add('custom-scrollbar');
                this.textboxCanvas.classList.remove('no-scroll');
                this.textboxCanvas.classList.add('auto-scroll');
                this.textboxCanvas.style.transform = `rotate(${0}deg)`;
                this.setTransitionDuration(0.15);
            }
            this.textboxCanvas.style.pointerEvents = 'auto';
        }
        this.textboxCanvas.onblur = (event) => {
            event.stopPropagation();
            if (!this.textboxCanvas) return;
            this._onBlurCallback && this._onBlurCallback();
            if (!this._onAutoSizeCallback) {
                this.textboxCanvas.classList.add('no-scroll');
                this.textboxCanvas.classList.remove('custom-scrollbar');
                this.textboxCanvas.style.transform = `rotate(${this.getRotation()}deg)`;
            }
        }
        this.textboxCanvas.oninput = (event: any) => {
            this.handleSetTextContent(event)
            this._onChangeCallback && this._onChangeCallback();
            this._onAutoSizeCallback && this.handleAutoResize();
        }
        this.textboxCanvas.onkeydown = (event) => {
            event.stopPropagation();
            if (event.code === KeyCode.escape) {
                this.blurTextArea();
            }
        };
    }

    handleSetTextContent(event: any): void {
        const attr = this.textboxCanvas instanceof HTMLDivElement ? 'innerHTML' : 'value';
        const str = event.target[attr] as string;
        this._textContent = str.replace('\n', ' ');
    }
    handleAutoResize(): void {
        if (this.textboxCanvas) {
            this.textboxCanvas.style.height = 'auto';
            this.autoSize = new Communicator.Point2(
                this.size.x,
                this.textboxCanvas.scrollHeight + this.innerPad.y / 2
            );
            this._onAutoSizeCallback();
        }
    }
    blurTextArea(): void {
        this.textboxCanvas!.blur();
        this.textboxCanvas!.style.pointerEvents = 'none';
        this.textboxCanvas!.style.resize = 'none';
        if (!this._onAutoSizeCallback) {
            this.textboxCanvas!.classList.remove('auto-scroll');
            this.textboxCanvas!.classList.add('no-scroll');
        }
    }

    focusTextArea(): void {
        this.textboxCanvas!.focus();
        if (!this._onAutoSizeCallback) {
            this.textboxCanvas!.classList.add('custom-scrollbar');
            this.textboxCanvas!.classList.remove('no-scroll');
            this.textboxCanvas!.classList.add('auto-scroll');
        }
        this.textboxCanvas!.style.pointerEvents = 'auto';
    }
    setTextColor(hexColor: string): void {
        this._textColor = hexColor;
    }

    getTextColor(): string {
        return this._textColor;
    }
    getTextContent(): string {
        return this._textContent;
    }
    setTextContent(textContent: string): void {
        this._textContent = textContent;
    }
    setTextBold(isBold: boolean): void {
        this._isBold = isBold;
    }
    setTextItalic(isItalic: boolean): void {
        this._isItalic = isItalic;
    }
    setTextUnderline(isUnderline: boolean): void {
        this._isUnderline = isUnderline;
    }
    setTextFontFamily(fontFamily: string): void {
        this._textFontFamily = fontFamily;
    }
    setTextFontSize(fontSize: number): void {
        this._textFontSize = fontSize;
    }

    public get autoSize() {
        return this._autoSize;
    }
    public set autoSize(size: Communicator.Point2) {
        this._autoSize = size;
    }

    public set canRotateOnFocus(can: boolean) {
        this._canRotateOnFocus = can;
        if (can && this.textboxCanvas) this.textboxCanvas.style.zIndex = '5';
    }

    setTransitionDuration(duration: number) {
        if (!this.textboxCanvas) return;
        this.textboxCanvas.style.transition = `transform ${duration}s`;
    }
}
