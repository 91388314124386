import { BookmarkCustom } from "common/define";
import { GlobalState } from "common/global";
import PdfHelper from "container/pdf-viewer/helper/pdf.helper";
import { Observable } from "rxjs";
import { Lodash } from "utils/utils";

export type PayloadCUDBookmark = {viewId: ViewId, name: string, id: string, key: 'create' | 'update' | 'delete'};

function createCurrentRecBoundary(pdfViewer: PdfViewer): Boundary {
    const {x, y, width, height} = PdfHelper.getRect(pdfViewer);
    const boundary: Boundary = {
        left: x, top: y, right: x + width, bottom: y + height,
    };
    return boundary
}
export function createBookmarkFunc(viewId: ViewId, nameBm: string): BookmarkCustom | undefined {
    const pdfCreate = GlobalState.mapPdfViewer.get(viewId);
    const scale = GlobalState.mapPdfScale.get(viewId);
    if (pdfCreate && scale) {
        const rect = createCurrentRecBoundary(pdfCreate.pdfViewer);
        const newBm: BookmarkCustom = {
            scale,
            title: nameBm,
            page: PdfHelper.currentPageIndex(pdfCreate.pdfViewer),
            rect,
            id: Communicator.UUID.create()
        }
        return newBm
    }
}
export function getBookmarks(viewId: ViewId): BookmarkCustom[] | undefined {
    return GlobalState.mapBookmark.get(viewId);
}
export function setBookmark(viewId: ViewId, data: BookmarkCustom[]): void {
    GlobalState.mapBookmark.set(viewId, data)
}
export function cudBookmarkHelper(payload: PayloadCUDBookmark, data: BookmarkCustom[]): Observable<{data: BookmarkCustom[], bmId: string}> {
    return new Observable<{data: BookmarkCustom[], bmId: string}>(subscriber => {
        const {name, key, id, viewId} = payload;
        const cloneData = Lodash.cloneDeep(data);
        let result: BookmarkCustom[] = [];
        let bmId = '';
        switch (key) {
        case 'create': {
            const newBm = createBookmarkFunc(viewId, name)
            if (newBm) {
                bmId = newBm.id;
                result = [...cloneData, newBm]
            }
            break
        }
        case 'update': {
            const bm = cloneData.find(b => b.id === id);
            if (bm) {
                bm.title = name;
                result = cloneData
            }
            break
        }
        case 'delete': {
            result = Lodash.remove(cloneData, b => b.id !== id);
            break
        }
        default: break
        }
        subscriber.next({
            data: result,
            bmId
        });
        subscriber.complete();
    })
}