import { RGBColor } from "react-color";
import { IPinMarkerData, NameMarkupItem } from "./define";
import { IconName } from "./define-name-icon";
import { ModeRightPanel } from "./type-state";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type GlobalMarkupOperator =
    'DrawLine' |
    'DrawArrow' |
    'DrawCircle' |
    'DrawEllipse' |
    'DrawRectangle' |
    'DrawPolygon' |
    'DrawPolyline' |
    'DrawText' |
    'DrawTextBox' |
    'DrawNote' |
    'DrawImage' |
    'DrawDrawing' |
    'DrawEraser' |
    'DrawHighlight' |
    'DrawRectHighlight' |
    'Undo' |
    'Redo' |
    'Save' |
    'Cancel' |
    'ShowPolygonInput' |
    'SelectImageFilePath' |
    'DrawPolygonCloud' |
    'DrawArc' | 'DrawNotePin' |
    'DrawMeasurementArea' |
    'DrawMeasurementDistance' |
    'DrawMeasurementPerimeter' |
    'DrawStamp' |
    'DrawSignature' |
    'DrawCallout' |
    'DrawCloud' |
    'DrawPinMarker'
    ;

export enum MarkupFillType {
    None = 1,
    Opaque = 2,
}
export enum LineStyle {
    continous = 1,
    largeDash,
    smallDash,
    center,
    phantom,
    cloud,
    zigzag,
}

export interface MarkupEntity {
    _id?: string; // id from db
    uniqueId: string;
    title?: string;
    status: MarkupStatus;
    uniqueViewId: string;
    type: MarkupType | string;
    createdDate: string;
    modifiedDate: string;
    createdBy: UserInfo;
    lastModifiedBy?: UserInfo;
    uniqueGroupId: string;
    originData: any;
    modeMarkup: MarkupMode;
    ModelFileId?: string;
    baseFileId?: string;
    baseMajorRev?: number;
    baseMinorRev?: number;
    isHide?: boolean;
    length?: number;
    area?: number;
    persistentIds?: string[]
}
export enum MarkupStatus {
    Open = 1,
    Approved,
    Rejected,
    Archived,
}

export enum MarkupType {
    Freehand = 0,
    Square = 1,
    Oval = 2,
    Text = 3,
    Arrow = 4,
    Line = 5,
    CallOut = 6,
    Cloud = 7,
    Note = 8,
    HighLight = 9,
    NotePin = 10,
    Polygon = 11,
    Polyline = 12,
    PolygonCloud = 13,
    TextNote = 14,
    None = 15,
    Arc = 16,
    Stamp = 17,
    Signature = 18,
    PinMarker = 19,
}


export interface UserInfo {
    id: string;
    userName: string;
    email?: string;
    phoneNumber?: string;
}
export enum MarkupMode {
    Mode2d = 0,
    Mode3d,
}
export interface IMarkupGroup {
    _id?: string; // id from db
    uniqueId: string;
    title: string;
    createdDate: number;
    modifiedDate: number;
    createdBy: UserInfo;
    description: string;
    comment?: any[];
    status: MarkupStatus;
    tags?: Tag[];
    ModelFileId?: string;
    baseFileId?: string;
    baseMajorRev?: number;
    baseMinorRev?: number;
    isVisible: boolean;
}
export interface Tag {
    name: string;
    color: string;
}
export interface MarkupView {
    viewType: ViewType;
    ModelFileId?: string;
    markup?: any;
}
export enum ViewType {
    SaveView = 0,
    MarkupView,
}

export interface FileIdRev {
    baseFileId: string;
    baseMajorRev: number;
    baseMinorRev: number;
}
export interface ResponseMarkups {
    markupEntities: MarkupEntity[];
    listGroups: IMarkupGroup[];
    markupViews: MarkupView[];
    fileIdRev?: FileIdRev;
}

export interface AnnotInfoExtra {
    iconName: IconName;
    shapeName: string;
    markupMode: ModeRightPanel;
    rotation?: number;
}
export interface AnnotData extends AnnotInfoExtra, AnnotJson { }

export interface AnnotBorderInfo {
    cloudIntensity?: number,
    dashPhase?: number,
    dashes?: number[],
    style?: string,
    width: number,
}

export enum AnnotLineStyle {
    Solid = 'solid',
    Dashed = 'dashed',
    Underline = 'underline',
    Beveled = 'beveled',
    Inset = 'inset',
    Cloudy = 'cloudy'
}

export interface DefaultFreeTextAppearance {
    textColor: string,
    textSize: number,
    fontName: string,
}
export interface Annot {
    setDefaultAppearance(arg0: DefaultFreeTextAppearance): void;
    getDefaultAppearance(): any;
    setEndingStyle(startOrEnd: boolean, endingStyle: number): Promise<any>;
    resetAppearanceStream(): boolean;
    setBorderInfo(value: AnnotBorderInfo): Promise<any>;
    setFillColor(hexColor: string): Promise<any>;
    isMarkup(): boolean;
    setBorderColor(value: string): Promise<any>;
    api: any;
    engine: any;
    eventEmitter: EventEmitter;
    groupElements: any[];
    id: string;
    info: AnnotInfo;
    markedStates: any[];
    page: PDFPage;
    isSpecial?: boolean;
    type: string;
    flags?: string;
    exportToJSON: () => AnnotJson;
    getId: () => string;
    setRotation: (annotRotation: number) => Promise<Annot[]>;
    setContent: (content: string) => Promise<Annot[]>;
    getFillColor: () => number | undefined;
    setTitle: (title: string) => Promise<any>;
    setOpacity: (value: number) => Promise<any>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    setFlags: (flag: PDFAnnotFlag, callback?: Function) => Promise<any>;
    getUniqueID: () => any;
    setImage: (buffer: any) => Promise<boolean>;
    setDictionaryEntry: (key: string, value: string) => Promise<boolean>;
    setIntent: (intent: string) => Promise<Annot[]>;
    getMeasureUnit: () => string;
}

export interface EventEmitter {
    on: (eventName: FoxitEvents, callback: (...args: any[]) => void) => void;
    emit: (eventName: FoxitEvents, ...args: any[]) => void;
    removeAllListeners: () => void;
}
export interface AnnotInfo {
    type?: string;
    action?: AnnotAction;
    subject: string;
    intent?: string;
    borderInfo: AnnotBorderInfo;
    content: string;
    title?: string;
    rect?: AnnotRect;
    defaultAppearance?: any;
    endPoint?: Point2;
    startPoint?: Point2;
    vertexes?: [Point2];
}
export interface AnnotAction {
    uri?: string;
    destination: any;
}
export interface AnnotBorderInfo {
    width: number;
}

export interface AnnotRect {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

export type Usage = 'print' | 'view';
export type Rotation = 0 | 1 | 2 | 3;
export type PdfZoomType = number | 'fitWidth' | 'fitHeight';
export type PrintFlag = ['page', 'annot'] | ['page'];

export interface AnnotJson {
    type: string;
    title: string;
    rect: string | AnnotRect;
    subject: string;
    rotate?: number;
    flags: number | string;
    'interior-color'?: number;
    page?: number;
    name?: string;
    style?: string;
    status?: any;
    intent?: string;
    start?: any;
    end?: any;
    vertices?: string;
    inklist?: string;
    color?: string;
    callout?: any;
    coords?: string;
    startPoint?: Point;
    date?: string;
    creationdate?: string;
    contents?: string;
    width?: number;
    customEntries?: any;
    opacity?: number;
    raw?: any;
    rotation?: number;
}

export interface CanvasData {
    buffer: Uint8Array | Uint16Array | Uint32Array | Uint8ClampedArray;
    width: number;
    height: number;
}
export interface Point {
    x: number;
    y: number;
}

export enum PDFAnnotFlag {
    Invisible = 1,
    Hidden = 2,
    Print = 4,
    NoZoom = 8,
    NoRotate = 16,
    NoView = 32,
    ReadOnly = 64,
    Locked = 128,
    ToggleNoView = 256,
    LockedContents = 512
}

export interface RectPosition {
    x: number,
    y: number,
}
export interface NoteData {
    annotId: any,
    pos: RectPosition,
}

export interface NoteEntity {
    annotId: any,
    content: string,
}

export interface MarkupViewEntities {
    camera: any,
    colors: [],
    cuttingData: any,
    defaultVisibility: boolean,
    explodeMagnitude: number,
    faceVisibility: boolean,
    imageSrc: string,
    lineVisibility: boolean,
    name: string,
    sheetId: any,
    uniqueId: string,
    visibilityExceptions: any[],
    modelFileId: string,
    createdDate: string,
    modifiedDate: string,
    lastModifiedBy: UserInfo,
    viewType: ViewType,
    markupEntities: MarkupEntity[],
    markup?: any;
}

export type ViewMode3D = 'viewMode' | 'markupViewMode' | 'editMode';

export interface NotePosition {
    left: number,
    top: number,
    width: number,
    height: number,
}
export interface MarkupBaseJson {
    className: string,
    lineColor: RGBColor,
    lineWeight: number,
    lineOpacity?: number,
    fillColor?: RGBColor,
    fillColorOption?: boolean,
    lineStyle?: LineStyle,
    textFontSize?: number,
    textColor?: RGBColor,
    textFontFamily?: string,
    textIsBold?: boolean,
    textIsItalic?: boolean,
    textIsUnderline?: boolean,
    iconName: IconName,
    shapeName: NameMarkupItem,
    uniqueIdGroup: string,
    fixedPoint?: Communicator.Point2,
    width?: number,
    startLineShapeType?: number,
    endLineShapeType?: number,
    points?: any[],
    uniqueId: string,
    rotation?: number,
    textBoxSize?: Communicator.Point2,
    arrowPoint2?: Communicator.Point2,
    modifiedDate: string,
}

export interface MarkupPinMarkerJson {
    className: string,
    iconName: IconName,
    shapeName: NameMarkupItem,
    uniqueIdGroup: string,
    startPoint: Communicator.Point3,
    uniqueId: string,
    modifiedDate: string,
    lastModifiedBy: UserInfo,
    is3DMarkup: boolean,
    pinMarkerData: IPinMarkerData,
}
export interface Point3D {
    x: number,
    y: number,
    z: number,
}

export enum LineCapStyle {
    None = 0, // No special line ending.
    Square, // A square filled with the annotation's interior color if any.
    Circle, // A circle filled with the annotation's interior color if any.
    Diamond, // A diamond filled with the annotation's interior color if any.
    OpenArrow, // Two short lines meeting in an acute angle to form an open arrowhead.
    ClosedArrow, // Two short lines meeting in an acute angle as in the OpenArrow style and connected by a third line to form a triangular closed arrowhead filled with the annotation's interior color if any.
    Butt, // A short line at the endpoint perpendicular to the line itself.
    ReverseOpenArrow, // Two short lines in the reverse direction from OpenArrow.
    ReverseClosedArrow, // A triangular closed arrowhead in the reverse direction from ClosedArrow.
    Slash, // A short line at the endpoint approximately 30 degrees clockwise from perpendicular to the line itself.
}

export enum MarkupAttachType {
    None = 1,
    Face = 2,
    Vertex = 3,
    Edge = 4,
}

export enum LinkingEnum {
    Linked = 0,
    Unlinked,
    Disabled,
    Active,
}