/* eslint-disable @typescript-eslint/no-non-null-assertion */
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { MarkupArrowItem } from "./markup-items/markup.arrow.item";
import { MarkupBaseItem } from "./markup-items/markup.base.item";
import { MarkupCircleItem } from "./markup-items/markup.circle.item";
import { MarkupEllipseItem } from "./markup-items/markup.ellipse.item";
import { MarkupLineItem } from "./markup-items/markup.line.item";
import { MarkupNoteItem } from "./markup-items/markup.note.item";
import { MarkupPolygonItem } from "./markup-items/markup.polygon.item";
import { MarkupPolylineItem } from "./markup-items/markup.polyline.item";
import { MarkupRectangleItem } from "./markup-items/markup.rectangle.item";
import { MarkupSignatureItem } from "./markup-items/markup.signature.item";
import { MarkupTextBoxItem } from "./markup-items/markup.textbox.item";
import { MarkupAction } from "./markup.action";
import { MarkupData } from "./markup.data";

export class MarkupCopyPaste {
    private viewer: Communicator.WebViewer | null;
    public markupData: MarkupData;
    public markupAction: MarkupAction;
    public constructor(viewer: Communicator.WebViewer | null, data: MarkupData, action: MarkupAction) {
        this.viewer = viewer;
        this.markupData = data;
        this.markupAction = action;
    }

    pasteElement(elements: MarkupBaseItem[], target: Communicator.Point2): void {
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.shapeName === 'Arrow' && element instanceof MarkupArrowItem) {
                this.pasteArrow(element as MarkupArrowItem, target);
            }
            if (element.shapeName === 'Circle' && element instanceof MarkupCircleItem) {
                this.pasteCircle(element as MarkupCircleItem, target);
            }
            if (element.shapeName === 'Ellipse') {
                this.pasteEllipse(element as MarkupEllipseItem, target);
            }
            if (element.shapeName === 'Line') {
                this.pasteLine(element as MarkupLineItem, target);
            }
            if (element.shapeName === 'Note') {
                this.pasteNote(element as MarkupNoteItem, target);
            }
            if (element.shapeName === 'Polygon') {
                this.pastePolygon(element as MarkupPolygonItem, target);
            }
            if (element.shapeName === 'Polyline') {
                this.pastePolyline(element as MarkupPolylineItem, target);
            }
            if (element.shapeName === 'Rectangle') {
                this.pasteRectangle(element as MarkupRectangleItem, target);
            }
            if (element.shapeName === 'Freehand') {
                this.pasteSignature(element as MarkupSignatureItem, target);
            }
            if (element.shapeName === 'Text Box') {
                this.pasteTextBox(element as MarkupTextBoxItem, target);
            }
            element.isSelected = false;
            this.markupData.updateMarkupItems();
            this.markupAction.setMarkupVisible(true);
        }
    }

    pasteArrow(element: MarkupArrowItem, target: Communicator.Point2): void {
        if (this.viewer) {
            const view = this.viewer.view;
            const markupItem = new MarkupArrowItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const pt = new Communicator.Point2(element._firstPoint!.x, element._firstPoint!.y);
            const pt1 = new Communicator.Point2(element._secondPoint!.x, element._secondPoint!.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            const cameraPoint1 = view.getCamera().getCameraPlaneIntersectionPoint(pt1, view);
            if (cameraPoint !== null && cameraPoint1 !== null) {
                markupItem.setFirstPoint(cameraPoint);
                markupItem.setSecondPoint(cameraPoint1);
            }
            this.markupData.markupItems.push(markupItem);
        }
    }

    pasteCircle(element: MarkupCircleItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupCircleItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const pt = new Communicator.Point2(element._point1!.x, element._point1!.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            if (cameraPoint !== null) {
                markupItem.setPosition(cameraPoint);
                markupItem.setRadiusPoint(cameraPoint);
            }
            this.markupData.markupItems.push(markupItem);
        }

    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pasteEllipse(element: MarkupEllipseItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const pt = new Communicator.Point2(element._point1!.x, element._point1!.y);
            const pt1 = new Communicator.Point2(element._point2!.x, element._point2!.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            const cameraPoint1 = view.getCamera().getCameraPlaneIntersectionPoint(pt1, view);
            const markupItem = new MarkupEllipseItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            if (cameraPoint !== null && cameraPoint1 !== null) {
                markupItem.setPosition(cameraPoint);
                markupItem.setRadiusPoint(cameraPoint1);
            }
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pasteLine(element: MarkupLineItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupLineItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const pt = new Communicator.Point2(element._firstPoint!.x, element._firstPoint!.y);
            const pt1 = new Communicator.Point2(element._secondPoint!.x, element._secondPoint!.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            const cameraPoint1 = view.getCamera().getCameraPlaneIntersectionPoint(pt1, view);
            if (cameraPoint !== null && cameraPoint1 !== null) {
                markupItem.setFirstPoint(cameraPoint);
                markupItem.setSecondPoint(cameraPoint1);
            }
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pasteNote(element: MarkupNoteItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupNoteItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            markupItem.setTextFormat(
                element._textFontSize,
                element._textColor,
                element._textFontFamily,
                element._textIsBold,
                element._textIsItalic,
                element._textIsUnderline);
            markupItem.setTextValue(element.textValue);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const pt = new Communicator.Point2(element._firstPoint!.x, element._firstPoint!.y);
            const pt1 = new Communicator.Point2(element._secondPoint!.x, element._secondPoint!.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            const cameraPoint1 = view.getCamera().getCameraPlaneIntersectionPoint(pt1, view);
            if (cameraPoint !== null && cameraPoint1 !== null) {
                markupItem.setFirstPoint(cameraPoint);
                markupItem.setSecondPoint(cameraPoint1);
            }
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pastePolygon(element: MarkupPolygonItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupPolygonItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const points = element.getPoints();
            for (let i = 0; i < points.length; i++) {
                const pt = new Communicator.Point2(points[i].x, points[i].y);
                const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
                if (cameraPoint) {
                    markupItem.addPoint(cameraPoint);
                }
            }
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pastePolyline(element: MarkupPolylineItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupPolylineItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const points = element.getPoints();
            for (let i = 0; i < points.length; i++) {
                const pt = new Communicator.Point2(points[i].x, points[i].y);
                const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
                if (cameraPoint) {
                    markupItem.addPoint(cameraPoint);
                }
            }
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pasteRectangle(element: MarkupRectangleItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupRectangleItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            markupItem.setPoint1(MathHelper.parseDataToPoint3(element._point1));
            markupItem.setPoint2(MathHelper.parseDataToPoint3(element._point2));
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pasteSignature(element: MarkupSignatureItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupSignatureItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption,
                element._lineStyle);
            const pt = new Communicator.Point2(target.x, target.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            if (cameraPoint !== null) {
                markupItem.addPoint(cameraPoint);
            }
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const points = element._points;
            for (let i = 0; i < points.length; i++) {
                markupItem.addPoint(points[i]);
            }
            this.markupData.markupItems.push(markupItem);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pasteTextBox(element: MarkupTextBoxItem, target: Communicator.Point2): void {
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const pt = new Communicator.Point2(element._point1!.x, element._point1!.y);
            const pt1 = new Communicator.Point2(element._point2!.x, element._point2!.y);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(pt, view);
            const cameraPoint1 = view.getCamera().getCameraPlaneIntersectionPoint(pt1, view);
            const markupItem = new MarkupTextBoxItem(this.viewer);
            markupItem.setLineFormat(
                element._lineColor,
                element._lineWeight,
                element._lineOpacity,
                element._fillColor,
                element._fillColorOption);
            markupItem.setTextFormat(
                element._textFontSize,
                element._textColor,
                element._textFontFamily,
                element._textIsBold,
                element._textIsItalic,
                element._textIsUnderline);
            if (cameraPoint !== null && cameraPoint1 !== null) {
                markupItem.setPoint1(cameraPoint);
                markupItem.setPoint2(cameraPoint1);
            }
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            this.markupData.markupItems.push(markupItem);
        }
    }
}