import React from 'react';
import { StreamFile } from 'common/define';
import RowBodyTableFile from './RowBodyTableFile';
import RowTableFolder from './RowBodyTableFolder';

type PropsRow = StreamFile;
const RowTableComponent = (props: PropsRow): JSX.Element => {
    return (
        <div>
            {
                props.isDirectory ? 
                    <RowTableFolder {...props}/> : 
                    <RowBodyTableFile {...props}/>
            }
        </div>
    );
}
const RowTable = React.memo(RowTableComponent);
export default RowTable;