import { createSelector } from "@reduxjs/toolkit";
import { selectSelfApp } from "./self.selector";

export const getAppState = createSelector(
    selectSelfApp,
    (state) => state
)
export const getAppLoading = createSelector(
    selectSelfApp,
    (state) => state.loading
)