/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalState } from "common/global";
import GeneralOperator from "../custom-operator/general.operator";
import SelectWindow from "../custom-operator/select-window.operator";
import BaseOperator from "./base.operator";

export default class AreaSelectOperator extends BaseOperator {
    generalOperator: GeneralOperator;
    generalHandle: any;
    selectWindowOperator: SelectWindow;
    selectWindowHandle: any;
    constructor(private viewer: Communicator.WebViewer) {
        super(viewer);
        this.Name = 'areaSelect';
        this.generalOperator = new GeneralOperator(viewer);
        this.generalHandle = viewer.operatorManager.registerCustomOperator(
            this.generalOperator,
        );

        this.selectWindowOperator = new SelectWindow(viewer);
        this.selectWindowHandle = viewer.operatorManager.registerCustomOperator(
            this.selectWindowOperator,
        );
    }
    /**
     * Active
     */
    public Active(): void {
        if (this.Status === false) {
            this.Viewer.operatorManager.push(this.generalHandle);
            this.Viewer.operatorManager.push(this.selectWindowHandle);
            this.Viewer.operatorManager.getOperator(this.selectWindowHandle).setMapping(Communicator.Button.Left);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(
                Communicator.Button.Left,
                Communicator.KeyModifiers.Control,
            );
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).setMapping(Communicator.Button.Middle);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Middle);

            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Right);
            this.Status = true;
            GlobalState.mapOperatorCursor.set('areaSelect', 'crosshair');
            GlobalState.primaryOperator.set(this.Viewer, 'areaSelect');
            this.SetCursor('crosshair')
        }
        if (this.Status === true) { // using for update the mode changed
        }


    }

    /**
     * InActive
     *
     */
    public InActive(): void {
        if (this.Status === true) {
            this.Viewer.operatorManager.remove(this.selectWindowHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.selectWindowHandle);
            this.Status = false;
            this.SetCursor('')
        }
    }
}
