/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */

import { NotepinCategory, NotepinJson, PinMarkerType } from "common/define";
import Utils from "utils/utils";
import { MarkupBaseItem } from "../markup-items/markup.base.item";

export class MarkupNotepinItem extends MarkupBaseItem {
    public static className = 'MarkupNotepinItem';
    public _viewer: Communicator.WebViewer;

    private _noteTextManager: Communicator.Markup.Note.NoteTextManager;

    private _uniqueId: Communicator.Uuid = Communicator.UUID.create();

    private _noteElementId: string | null = null;

    private _sphereInstanceId?: Communicator.NodeId;

    private _stemInstanceId?: Communicator.NodeId;

    private _position: Communicator.Point3 = Communicator.Point3.zero();

    private _selectionPosition: Communicator.Point3;

    private _selectionNormal: Communicator.Point3;

    private _partId: Communicator.PartId;

    private _pinBoundingBox?: Communicator.Box;

    private _text = '';
    private _type = '';

    private _color: Communicator.Color = Communicator.Color.white();

    private _sphereRadius = 0.015;

    private _deleted = false;

    private _active = false;

    private _callbacks: Communicator.CallbackMap | null = null;

    public constructor(
        viewer: Communicator.WebViewer,
        noteTextManager: Communicator.Markup.Note.NoteTextManager,
        selectionPosition: Communicator.Point3,
        selectionNormal: Communicator.Point3,
        partId: Communicator.PartId,
        notepinCategory: NotepinCategory,
    ) {
        super(viewer);

        this._viewer = viewer;
        this._noteTextManager = noteTextManager;
        this._selectionPosition = selectionPosition;
        this._selectionNormal = selectionNormal;
        this._partId = partId;
        this.iconName = 'pushpin';
        this.shapeName = 'Note Pin';
        const rgbColor = Utils.hexToRgb(notepinCategory.color);
        if (rgbColor) {
            const { r, g, b } = rgbColor;
            this._color = new Communicator.Color(r, g, b);
        }
        this._type = notepinCategory.type;
    }

    public async getInfo(): Promise<void> {
        const matrix = this._createPinTransformationMatrix(this._selectionPosition, this._selectionNormal);
        const [stemInstanceId, sphereInstanceId] = await Promise.all([
            this._createPinStemInstance(matrix),
            this._createPinSphereInstance(matrix),
        ]);

        this._stemInstanceId = stemInstanceId;
        this._sphereInstanceId = sphereInstanceId;

        await this._restore(false);
        this._callbacks = {
            visibilityChanged: () => {
                this._matchPartVisibility();

            },
        };
        this._viewer.setCallbacks(this._callbacks);
    }

    private _matchPartVisibility(): void {
        if (this._sphereInstanceId === undefined || this._stemInstanceId === undefined) {
            return;
        }

        const { model } = this._viewer;
        const partVisibility = model.getNodeVisibility(this._partId);
        const pinVisibility = model.getNodeVisibility(this._sphereInstanceId);

        // match pin visibility to associated part visibility
        if (partVisibility !== pinVisibility && !this._noteTextManager.getExplodeActive()) {
            model.setNodesVisibility([this._stemInstanceId, this._sphereInstanceId], partVisibility) as Communicator.Internal.UnusedPromise;
        }

        // hide note text window if the associated part is hidden
        const activeItem = this._noteTextManager.getActiveItem();
        if (activeItem !== null && activeItem.getStemInstanceId() === this._stemInstanceId && !partVisibility) {
            this.hide();
        }
    }

    public async updatePosition(): Promise<void> {
        if (this._sphereInstanceId === undefined) {
            return;
        }
        const box = await this._viewer.model.getNodeRealBounding(this._sphereInstanceId);
        this._pinBoundingBox = box;
        this._position = this._pinBoundingBox.center();
        this.setText(this._text);
    }

    private async _restore(triggerEvent: boolean): Promise<void> {
        this._noteTextManager.setActiveItemHandle(this._viewer.markupManager.registerMarkup(this));
        this._show(triggerEvent);
        this._updateColor();
        await this.draw();
    }

    public async restore(): Promise<void> {
        return this._restore(true);
    }

    public setText(text: string): void {
        this._text = text;
        this._noteTextManager.getNoteTextElement().setText(text);
    }

    public saveTextValue(): void {
        this._text = this._noteTextManager.getNoteTextElement().getText();
    }

    public async draw(): Promise<void> {
        if (this._deleted || !this._active) {
            return;
        }

        this._behindView = false;

        await this.updatePosition();

        const screenPos = this._viewer.view.projectPoint(this._position);
        if (screenPos.z <= 0.0) {
            this._behindView = true;
        }

        if (this._behindView) {
            if (this._noteElementId !== null && document.getElementById(this._noteElementId) !== null) {
                this._viewer.markupManager.removeMarkupElement(this._noteElementId);
                this._noteElementId = null;
            }
        }
    }

    public hit(point: Communicator.Point2): boolean {
        return this.hitWithTolerance(point, 0);
    }

    public hitWithTolerance(point: Communicator.Point2, pickTolerance: number): boolean {
        const noteTextElement = this._noteTextManager.getNoteTextElement();
        const screenPos = noteTextElement.getPosition();
        const size = noteTextElement.getSize();

        return Communicator.Util.isPointInRect2d(point, screenPos, size, pickTolerance);
    }

    public getClassName(): string {
        return MarkupNotepinItem.className;
    }

    public getUniqueId(): Communicator.Uuid {
        return this._uniqueId;
    }

    public getSphereInstanceId(): Communicator.NodeId | undefined {
        return this._sphereInstanceId;
    }

    public getStemInstanceId(): Communicator.NodeId | undefined {
        return this._stemInstanceId;
    }

    public onSelect(): void {
        this._noteTextManager.getNoteTextElement().focus();
    }

    public onDeselect(): void {
        this._noteTextManager.getNoteTextElement().blur();
    }

    public hide(): void {
        const element = this._noteTextManager.getNoteTextElement();
        element.hide();
        this.setText(element.getText());
        this._noteTextManager.setActiveItem(null);
        this._active = false;
    }

    private _show(triggerEvent: boolean): void {
        this._active = true;
    }

    public show(): void {
        this._show(true);
    }

    public async remove(): Promise<void> {
        if (this._callbacks !== null) {
            this._viewer.unsetCallbacks(this._callbacks);
            this._callbacks = null;
        }

        const { model } = this._viewer;
        const ps: Promise<void>[] = [];

        // delete pin instance
        if (this._stemInstanceId !== undefined) {
            ps.push(model.deleteMeshInstances([this._stemInstanceId]));
        }

        // TODO: figure out why this throws an error
        if (this._sphereInstanceId !== undefined) {
            ps.push(model.deleteMeshInstances([this._sphereInstanceId]));
        }
        this.hide();
        this._deleted = true;
        await Communicator.Util.waitForAll(ps);
        super.remove();
    }

    public getRemoved(): boolean {
        return this._deleted;
    }

    public setColor(color: Communicator.Color): Communicator.DeprecatedPromise {
        this._color = color;
        this._updateColor();
        return Promise.resolve();
    }

    public getColor(): Communicator.Color {
        return this._color;
    }

    public getPartId(): Communicator.PartId {
        return this._partId;
    }

    private _updateColor(): void {
        if (this._sphereInstanceId !== undefined) {
            this._viewer.model.setNodesFaceColor([this._sphereInstanceId], this._color);
        }
    }

    // pin methods
    private _createPinTransformationMatrix(selectionPosition: Communicator.Point3, normal: Communicator.Point3): Communicator.Matrix {
        // rotate
        let i = 0;
        let min = normal.x;
        if (Math.abs(normal.y) < Math.abs(min)) {
            min = normal.y;
            i = 1;
        }
        if (Math.abs(normal.z) < Math.abs(min)) {
            i = 2;
        }

        const x = [0, 0, 0];
        x[i] = 1;
        const point = Communicator.Point3.createFromArray(x);

        const tangent0 = Communicator.Point3.cross(normal, point).normalize();
        const tangent1 = Communicator.Point3.cross(normal, tangent0);

        let matrix = new Communicator.Matrix();
        matrix.m = [
            normal.x, normal.y, normal.z, 0,
            tangent0.x, tangent0.y, tangent0.z, 0,
            tangent1.x, tangent1.y, tangent1.z, 0,
            0, 0, 0, 1];

        matrix = Communicator.Matrix.multiply(
            matrix, new Communicator.Matrix().setScaleComponent(this._sphereRadius, this._sphereRadius, this._sphereRadius),
        );

        matrix.setTranslationComponent(selectionPosition.x, selectionPosition.y, selectionPosition.z);

        return matrix;
    }

    private async _createPinStemInstance(matrix: Communicator.Matrix): Promise<Communicator.NodeId> {
        const pinStemMeshId = this._noteTextManager.getPinStemMeshId();
        if (pinStemMeshId === null) {
            throw new Communicator.CommunicatorError("stem mesh hasn't been created yet");
        }

        const meshInstanceData = new Communicator.MeshInstanceData(
            pinStemMeshId,
            matrix,
            'pin-stem-instance',
            undefined,
            this._color,
        );

        meshInstanceData.setOpacity(1);

        const instanceFlags = Communicator.MeshInstanceCreationFlags.SuppressCameraScale
            || Communicator.MeshInstanceCreationFlags.DoNotCut
            || Communicator.MeshInstanceCreationFlags.DoNotExplode
            || Communicator.MeshInstanceCreationFlags.DoNotSelect
            || Communicator.MeshInstanceCreationFlags.DoNotXRay
            || Communicator.MeshInstanceCreationFlags.OverrideSceneVisibility
            || Communicator.MeshInstanceCreationFlags.AlwaysDraw;
        meshInstanceData.setCreationFlags(instanceFlags);
        const { model } = this._viewer;
        return model.createMeshInstance(meshInstanceData, undefined, true);
    }

    private async _createPinSphereInstance(matrix: Communicator.Matrix): Promise<Communicator.NodeId> {
        const pinSphereMeshId = this._noteTextManager.getPinSphereMeshId();
        if (pinSphereMeshId === null) {
            throw new Communicator.CommunicatorError("sphere mesh hasn't been created yet");
        }

        const meshInstanceData = new Communicator.MeshInstanceData(
            pinSphereMeshId,
            matrix,
            'pin-sphere-instance',
            this._color,
            undefined,
        );

        meshInstanceData.setOpacity(1);
        const instanceFlags = Communicator.MeshInstanceCreationFlags.SuppressCameraScale
            || Communicator.MeshInstanceCreationFlags.DoNotCut
            || Communicator.MeshInstanceCreationFlags.DoNotExplode
            || Communicator.MeshInstanceCreationFlags.DoNotXRay
            || Communicator.MeshInstanceCreationFlags.ExcludeBounding
            || Communicator.MeshInstanceCreationFlags.OverrideSceneVisibility
            || Communicator.MeshInstanceCreationFlags.AlwaysDraw;
        meshInstanceData.setCreationFlags(instanceFlags);
        const { model } = this._viewer;

        return model.createMeshInstance(meshInstanceData, undefined, true);
    }

    // Serialization methods

    /**
     * Creates an object ready for JSON serialization.
     * @returns The prepared object.
     */
    public toJson(): NotepinJson {
        const { r, g, b } = this._color;
        return {
            uniqueId: this._uniqueId,
            className: this.getClassName(),
            selectionPosition: this._selectionPosition.toJson(),
            selectionNormal: this._selectionNormal.toJson(),
            text: this._text,
            color: this._color,
            hexColor: Utils.rgbToHex(r, g, b),
            partId: this._partId,
            type: this._type,
            createdAt: new Date().toISOString(),
            createdBy: '',
            description: '',
            title: '',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            sphereInstanceId: this._sphereInstanceId!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            stemInstanceId: this._stemInstanceId!,
        };
    }

    /** @deprecated Use [[toJson]] instead. */
    public forJson(): Object {
        return this.toJson();
    }

    private static _fromJson(
        objData: any,
        viewer: Communicator.WebViewer,
        noteTextManager: Communicator.Markup.Note.NoteTextManager,
    ): MarkupNotepinItem | null {
        const obj = objData as ReturnType<MarkupNotepinItem['toJson']>;
        if (!noteTextManager.findById(obj.uniqueId)) {
            const selectionPosition = Communicator.Point3.fromJson(obj.selectionPosition);
            const selectionNormal = Communicator.Point3.fromJson(obj.selectionNormal);
            const { partId, color, type } = obj;
            const newNotepinCate: NotepinCategory = {
                color: Utils.rgbToHex(color.r, color.g, color.b),
                type: type as PinMarkerType,
            }
            const noteText = new MarkupNotepinItem(viewer, noteTextManager, selectionPosition, selectionNormal, partId, newNotepinCate);

            noteText._uniqueId = obj.uniqueId;
            noteText.setText(obj.text);
            noteText.setColor(Communicator.Color.fromJson(obj.color));

            return noteText;
        }
        return null;
    }

    /**
     * Creates a new [[NoteText]] from an object given by [[toJson]].
     * @param An object given by [[toJson]].
     * @returns The prepared object.
     */
    public static async fromJson(
        obj: any,
        viewer: Communicator.WebViewer,
        noteTextManager: Communicator.Markup.Note.NoteTextManager,
    ): Promise<MarkupNotepinItem | null> {
        const result = MarkupNotepinItem._fromJson(obj, viewer, noteTextManager);
        return result;
    }
}
