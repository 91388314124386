import { Drawer } from "antd";
import CScrollView from 'components/CScrollView';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React from "react";
import RowBodySelectList from './RowBodySelectLists';

type DrawerListFileSelectedProps = Props & {
    visible: boolean,
    onClose: () => void,
    onOpen: () => void
}

export default function DrawerListFileSelected({visible, onClose, onOpen, ...props}: DrawerListFileSelectedProps): JSX.Element {
    const {state: {arrSelected}} = useContextListOpenFile();
    
    return (
        <Drawer
            title={<div className='flex justify-center'>
                {arrSelected.length} selected file
            </div>}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            getContainer={false}
            style={{ position: 'absolute' }}
            width="50%"
        >
            <CScrollView>
                <div>
                    {arrSelected.map((item, index) => (
                        <div key={index} className='p-1'>
                            <RowBodySelectList {...item} />
                        </div>
                    ))}
                </div>
            </CScrollView>
        </Drawer>
    )
}