/* eslint-disable @typescript-eslint/no-explicit-any */
import { Subject } from 'rxjs';
import { MarkupBaseItem } from './markup-items/markup.base.item';
import { MarkupTextBoxItem } from './markup-items/markup.textbox.item';
import { MarkupSelectionOperator } from './markup-operators/markup.selection.operator';
import { MarkupData, UndoRedoAction, UndoRedoActionInfor } from './markup.data';


export class MarkupUndoRedo {
    public viewer: Communicator.WebViewer | null;
    public markupData: MarkupData | null;
    public undoStack: UndoRedoActionInfor[] = [];
    public redoStack: UndoRedoActionInfor[] = [];
    public enableMarkupUndoRedoSaveButton$ = new Subject<{ undo: number, redo: number }>();
    public endMoveGripMode = false;
    public selectionOperatorId: Communicator.OperatorId | null = null;
    public selectOperator: MarkupSelectionOperator | null = null;
    public editTextMode = false;
    public isDeleteItem = false;
    public constructor(viewer: Communicator.WebViewer | null, data: MarkupData) {
        this.viewer = viewer;
        this.markupData = data;
    }

    undo(): void {
        if (this.viewer) {
            if (this.undoStack && this.undoStack.length > 0) {
                const top = this.undoStack.pop();
                const markup = this.viewer.markupManager;
                if (top) {
                    if (top.action === UndoRedoAction.Add) {
                        const redo: UndoRedoActionInfor = { ...top, action: UndoRedoAction.Delete };
                        this.redoStack.push(redo);
                        top.item.forEach(it => {
                            if (it instanceof MarkupTextBoxItem) 
                                it.blurTextArea();
                            this.removeMarkupItem(it);
                            it.setSelected(false)
                            const id = it.getMarkupItemId();
                            if (id) {
                                markup.unregisterMarkup(id);
                            }
                        })
                    }
                    if (top.action === UndoRedoAction.Delete) {
                        const redo: UndoRedoActionInfor = { ...top, action: UndoRedoAction.Add };
                        this.redoStack.push(redo);
                        top.item.forEach(it => {
                            const id = markup.registerMarkup(it);
                            if (it instanceof MarkupTextBoxItem) 
                                it.blurTextArea();
                            it.setMarkupItemId(id);
                            this.appendMarkupItem(it);
                        })
                    }
                    if (top.action === UndoRedoAction.Edit) {
                        const { item, backUp } = top;
                        const data: any[] = []
                        item.forEach(it => {
                            data.push(it.toJson());
                            const matchMarkup = backUp.find((stack: any) => stack.uniqueId === it.uniqueId);
                            if (matchMarkup)
                                it.fromJson(matchMarkup);
                        })
                        const edit: UndoRedoActionInfor = { action: UndoRedoAction.Edit, item, backUp: data };
                        this.redoStack.push(edit);
                    }
                    markup.refreshMarkup();
                    this.updateEnableUndoSaveButton();
                }
            }
        }
    }

    redo(): void {
        if (this.viewer) {
            if (this.redoStack && this.redoStack.length > 0) {
                const top = this.redoStack.pop();
                const markup = this.viewer.markupManager;
                if (top) {
                    if (top.action === UndoRedoAction.Delete) {
                        const undo: UndoRedoActionInfor = { ...top, action: UndoRedoAction.Add };
                        this.undoStack.push(undo);
                        top.item.forEach(it => {
                            const id = markup.registerMarkup(it);
                            if (it instanceof MarkupTextBoxItem) 
                                it.blurTextArea();
                            it.setMarkupItemId(id);
                            this.appendMarkupItem(it);
                        })
                    }
                    if (top.action === UndoRedoAction.Add) {
                        const undo: UndoRedoActionInfor = { ...top, action: UndoRedoAction.Delete };
                        this.undoStack.push(undo);
                        const markup = this.viewer.markupManager;
                        top.item.forEach(it => {
                            this.removeMarkupItem(it);
                            if (it instanceof MarkupTextBoxItem) 
                                it.blurTextArea();
                            it.setSelected(false);
                            const id = it.getMarkupItemId();
                            if (id) {
                                markup.unregisterMarkup(id);
                            }
                        })
                    }
                    if (top.action === UndoRedoAction.Edit) {
                        const { item, backUp } = top;
                        const data: any[] = [];
                        item.forEach(it => {
                            data.push(it.toJson());
                            const matchMarkup = backUp.find((stack: any) => stack.uniqueId === it.uniqueId);
                            if (matchMarkup)
                                it.fromJson(matchMarkup);
                        })
                        const edit: UndoRedoActionInfor = { action: UndoRedoAction.Edit, item, backUp: data };
                        this.undoStack.push(edit);
                    }
                    markup.refreshMarkup();
                    this.updateEnableUndoSaveButton();
                }
            }
        }
    }

    pushToUndoStack(item: UndoRedoActionInfor): void {
        this.undoStack.push(item);
        if (this.redoStack.length > 0)
            this.redoStack = [];
        this.updateEnableUndoSaveButton();
    }

    appendMarkupItem(item: MarkupBaseItem): void {
        if (item && this.markupData) {
            const idx = this.markupData.markupItems.findIndex(t => t === item);
            if (idx === -1) {
                this.markupData.markupItems.push(item);
                this.markupData.updateMarkupItems();
            }
        }
    }

    removeMarkupItem(item: MarkupBaseItem): void {
        if (item && this.markupData) {
            const idx = this.markupData.markupItems.findIndex(t => t === item);
            if (idx >= 0) {
                this.markupData.markupItems.splice(idx);
                this.markupData.updateMarkupItems();
            }
        }
    }

    deleteSelected(): void {
        if (this.viewer && this.markupData) {
            const markup = this.viewer.markupManager;
            if (this.markupData.markupItems && this.markupData.markupItems.length > 0) {
                const nonSelectItems: MarkupBaseItem[] = [];
                const deleteMarkup: UndoRedoActionInfor = {
                    action: UndoRedoAction.Delete,
                    item: this.markupData.markupItems
                }
                this.pushToUndoStack(deleteMarkup);
                this.markupData.markupItems.forEach(v => {
                    if (v.isSelected && v.getMarkupVisible()) {
                        const id = v.getMarkupItemId();
                        if (!id) return;
                        markup.unregisterMarkup(id);
                        if (!this.isDeleteItem) this.isDeleteItem = true;

                    } else {
                        nonSelectItems.push(v);
                    }
                })
                markup.refreshMarkup();
                this.markupData.markupItems = nonSelectItems;
                this.markupData.updateMarkupItems();
            }
        }
    }

    updateEnableUndoSaveButton(): void {
        const undo = this.undoStack.length;
        const redo = this.redoStack.length;
        this.enableMarkupUndoRedoSaveButton$.next({ undo, redo });
    }
    clearUndoRedoStack(): void {
        this.undoStack = [];
        this.redoStack = [];
        this.updateEnableUndoSaveButton();
    }
}




