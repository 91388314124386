import { BaseElementCanvas } from "./markup.base-canvas.element";



export class RotateTooltipElement extends BaseElementCanvas {

    private _rotateTooltipCanvas: HTMLDivElement;
    private pos = Communicator.Point2.zero();
    private deg = '';
    constructor() {
        super();
        this._rotateTooltipCanvas = document.createElement('div');
        this._rotateTooltipCanvas.innerHTML = `${this.deg}°`;
        this._rotateTooltipCanvas.style.position = 'absolute';
        this._rotateTooltipCanvas.style.display = 'none';
        this._rotateTooltipCanvas.style.width = 'fit-content';
        this._rotateTooltipCanvas.style.height = 'fit-content';
        this._rotateTooltipCanvas.style.zIndex = '50';
        this._rotateTooltipCanvas.style.fontSize = '11px';
        this._rotateTooltipCanvas.style.fontFamily = 'sans-serif';
        this._rotateTooltipCanvas.style.color = 'black';
        this._rotateTooltipCanvas.style.textShadow = `2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff`;
    }

    getRotateTooltip(): HTMLDivElement {
        return this._rotateTooltipCanvas;
    }

    draw(): void {
        this._rotateTooltipCanvas.innerHTML = `${this.deg}°`;
        this._rotateTooltipCanvas.style.left = `${this.pos.x + 20}px`;
        this._rotateTooltipCanvas.style.top = `${this.pos.y + 10}px`;
    }

    setPosition(pos: Communicator.Point2): void {
        this.pos = pos;
    }

    setRotateValue(deg: number): void {
        const positive = (deg + 360) % 360;
        this.deg = positive.toFixed(2);
    }

    setVisible(vis: boolean): void {
        this._rotateTooltipCanvas.style.display = vis ? 'initial' : 'none';
    }
}