/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LineCapStyle, MarkupBaseJson } from 'common/type-markup';
import { MarkupBaseBounding, GripPoint } from '../markup-canvas-elements/markup.bounding.element';
import { CustomGrippointElement } from '../markup-canvas-elements/markup.custom-grippoint.element';
import { LineElementCanvas } from '../markup-canvas-elements/markup.line-canvas.element';
import { MarkupBaseItem } from './markup.base.item';
export class MarkupArrowItem extends MarkupBaseItem {
    private _uniqueId: string;
    private lineArrowHTML: LineElementCanvas = new LineElementCanvas();
    private startGrippoint: CustomGrippointElement;
    private endGrippoint: CustomGrippointElement;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupArrow';
        this.shapeName = 'Arrow';
        this._uniqueId = this.uniqueId;
        const lineArrowItem = this;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(false);
        this.redlineBounding.setGripDirection([]);
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();

        this.startGrippoint = new CustomGrippointElement(
            viewer,
            (point: Communicator.Point2) => {
                lineArrowItem.customGripPointDragStartCallback(point);
            },
            (point: Communicator.Point2, isForcing) => {
                lineArrowItem.startGripPointDragMoveCallback(point, isForcing);
            },
            (point: Communicator.Point2) => {
                lineArrowItem.customGrippointDragEndCallback(point);
            },
        );

        this.endGrippoint = new CustomGrippointElement(
            viewer,
            (point: Communicator.Point2) => {
                lineArrowItem.customGripPointDragStartCallback(point);
            },
            (point: Communicator.Point2, isForcing) => {
                lineArrowItem.endGripPointDragMoveCallback(point, isForcing);
            },
            (point: Communicator.Point2) => {
                lineArrowItem.customGrippointDragEndCallback(point);
            },
        );

    }
    private _update(): void {
        if (!this._firstPoint) return
        this.lineArrowHTML.setStrokeWidth(this._lineWeight);
        this.lineArrowHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.lineArrowHTML.setLineStyle(this._lineStyle);
        this.lineArrowHTML.setStartLineCapStyle(LineCapStyle.OpenArrow);
        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._firstPoint.copy()),
        );
        let secondPoint = firstPoint.copy();
        if (this._secondPoint !== null) {
            secondPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._secondPoint.copy()),
            );

            // Calculate size of rectangle
            this.lineArrowHTML.setFirstPoint(firstPoint);
            this.lineArrowHTML.setSecondPoint(secondPoint);
            this.lineArrowHTML.createLine();
            this._isReady = true;
        }

        this.updateBoundingBox(firstPoint, secondPoint);
        this.updateCustomGrippoint(firstPoint, secondPoint);
    }

    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.lineArrowHTML.getLine(),
                );
            }
        } else if (this._redlineElementId) {
            this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
        }

        this.handleBoundingRectInteraction(() => {
            if (!this.boundingIds) return;
            const startEnt = this.startGrippoint.getGrippointElement();
            this.boundingIds.push(this._viewer.markupManager.addMarkupElement(startEnt));

            const endEnt = this.endGrippoint.getGrippointElement();
            this.boundingIds.push(this._viewer.markupManager.addMarkupElement(endEnt));
        });
    }

    updateBoundingBox(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        if (!firstPoint || !secondPoint) return;
        const pos = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
        const size = this.lineArrowHTML.getSize();
        const stroke = this.lineArrowHTML.getStrokeWidth();
        const boxPos = new Communicator.Point2(pos.x - stroke / 2, pos.y - stroke / 2);
        if (this.redlineBounding) {
            this.redlineBounding.setPosition(boxPos);
            this.redlineBounding.setSize(new Communicator.Point2(size.x + stroke, size.y + stroke));
        }
    }

    updateCustomGrippoint(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        const temp = 5;
        const p1 = new Communicator.Point2(firstPoint.x - temp, firstPoint.y - temp);
        const p2 = new Communicator.Point2(secondPoint.x - temp, secondPoint.y - temp);
        this.startGrippoint.setPosition(p1);
        this.endGrippoint.setPosition(p2);
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupArrowItem";
    }
    isSelectedByRectangle(pt1: Communicator.Point2, pt2: Communicator.Point2, withIn: boolean): boolean {
        return false
    }
    getOsnapPoint(point: Communicator.Point2, osnapPoint: Communicator.Point2): boolean {
        return false;
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineStyle: this._lineStyle,
            lineWeight: this._lineWeight,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            startPoint: this._firstPoint?.copy(),
            endPoint: this._secondPoint?.copy(),
            uniqueId: this.uniqueId,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineStyle = data.lineStyle;
        this._lineWeight = data.lineWeight;
        this._fillColor = data.fillColor
        this._fillColorOption = data.fillColorOption;
        this._firstPoint = data.startPoint;
        this._secondPoint = data.endPoint;
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }

    onDragStart(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit || !this._firstPoint || !this._secondPoint) return false;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this._firstPoint.add(c);
            this._secondPoint.add(c);
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }

    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.gripPointDragType = type;
        this.previousGripDragPoint = point;
    }

    gripPointDragMoveCallback(point: Communicator.Point2) {
        if (!this._isCanEdit) return;
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);

        if (b) {
            const firstPoint = this.lineArrowHTML.getFirstPoint();
            const size = this.lineArrowHTML.getSize();
            const secontPoint = new Communicator.Point2(firstPoint.x + size.x, firstPoint.y + size.y);
            switch (this.gripPointDragType) {
                case GripPoint.top:
                    {
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(new Communicator.Point2(firstPoint.x, point.y), a);
                        this._firstPoint!.assign(newPos!);
                        break;
                    }
                case GripPoint.bottom:
                    {
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(new Communicator.Point2(secontPoint.x, point.y), a);
                        this._secondPoint!.assign(newPos!);
                        break;
                    }
                case GripPoint.right:
                    {
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(new Communicator.Point2(point.x, secontPoint.y), a);
                        this._secondPoint!.assign(newPos!);
                        break;
                    }
                case GripPoint.left:
                    {
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(new Communicator.Point2(point.x, firstPoint.y), a);
                        this._firstPoint!.assign(newPos!);
                        break;
                    }
                case GripPoint.topLeft:
                case GripPoint.topRight:
                case GripPoint.bottomLeft:
                case GripPoint.bottomRight:
                    {
                        this._secondPoint!.assign(b);
                        break;
                    }
                default:
                    break;
            }
        }
        this._viewer.markupManager.refreshMarkup();
    }

    /**
     * Update first point to top left, second point to bottom right
     */
    updateDefinePoints() {
        if (!this._firstPoint || !this._secondPoint) return;
        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._firstPoint.copy()),
        );
        const secondPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._secondPoint.copy()),
        );

        if (firstPoint && secondPoint) {
            switch (this.gripPointDragType) {
                case GripPoint.top:
                case GripPoint.bottom:
                case GripPoint.left:
                case GripPoint.right:
                    {
                        const minPnt = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        const maxPnt = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(minPnt, view)!);
                        this._secondPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(maxPnt, view)!);
                        break;
                    }
                // Set pick point to second point and opposite to first point
                case GripPoint.topLeft:
                    {
                        const frstPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._secondPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.topRight:
                    {
                        const frstPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._secondPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.bottomLeft:
                    {
                        const frstPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._secondPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.bottomRight:
                    {
                        const frstPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._secondPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                default:
                    break;
            }
        }
    }

    gripPointDragEndCallback(point: Communicator.Point2) {
        this.gripPointDragType = null;
        this.previousGripDragPoint = null;
        if (this.isUpdate) {
            this.isUpdate = false;
            this.triggerOnMarkupUpdated();
        }
    }

    customGripPointDragStartCallback(point: Communicator.Point2) {
        this.isUpdate = false;
    }

    startGripPointDragMoveCallback(point: Communicator.Point2, isForcing: boolean) {
        if (!this._isCanEdit) return;
        this.isUpdate = true;
        const { view } = this._viewer;
        const pos = point;
        if (isForcing) {
            const secondPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._secondPoint!.copy()),
            );
            const tempPos = this.updatePos(point, secondPoint);
            pos.assign(tempPos);
        }
        this._firstPoint!.assign(view.getCamera().getCameraPlaneIntersectionPoint(pos, view)!);
        this._viewer.markupManager.refreshMarkup();
    }

    endGripPointDragMoveCallback(point: Communicator.Point2, isForcing: boolean) {
        if (!this._isCanEdit) return;
        this.isUpdate = true;
        const { view } = this._viewer;
        const pos = point;
        if (isForcing) {
            const firstPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._firstPoint!.copy()),
            );
            const tempPos = this.updatePos(point, firstPoint);
            pos.assign(tempPos);
        }
        this._secondPoint!.assign(view.getCamera().getCameraPlaneIntersectionPoint(pos, view)!);
        this._viewer.markupManager.refreshMarkup();
    }

    public updatePos(position: Communicator.Point2, handlePoint: Communicator.Point2): Communicator.Point2 {
        // Rectangle force to Square when holding shift
        const pos = position.copy();
        if (handlePoint) {
            const deltaX = Math.abs(position.x - handlePoint.x);
            const deltaY = Math.abs(position.y - handlePoint.y);
            const range = 10;
            if (deltaX < range || deltaY < range) {
                const p = position.copy();
                if (deltaX < range) {
                    p.x = handlePoint.x;
                }
                if (deltaY < range) {
                    p.y = handlePoint.y;
                }
                pos.assign(p);
            } else {
                const delta = deltaX > deltaY ? deltaX : deltaY;
                pos.x = position.x - handlePoint.x > 0 ? handlePoint.x + delta : handlePoint.x - delta;
                pos.y = position.y - handlePoint.y > 0 ? handlePoint.y + delta : handlePoint.y - delta;
            }
        }

        return pos;
    }

    customGrippointDragEndCallback(point: Communicator.Point2) {
        this.isUpdate = false;
        this.triggerOnMarkupUpdated();
    }
    hit(point: Communicator.Point2) {
        return this.lineArrowHTML.hit(point);
    }
    setMarkupVisible(visible: boolean): void {
        this.lineArrowHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        this.startGrippoint.setVisibleGripPoints(visible);
        this.endGrippoint.setVisibleGripPoints(visible);
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.lineArrowHTML.baseCanvas;
    }
    setBoundingGripPointVisible(vis: boolean): void {
        this.startGrippoint.setVisible(vis);
        this.endGrippoint.setVisible(vis)
    }
}