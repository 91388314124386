/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { MarkupBaseJson, MarkupFillType } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { boundPadding, cloudPadding } from 'utils/utils';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { CircleElementCanvas } from '../markup-canvas-elements/markup.circle-canvas.element';
import { MarkupBaseItem } from './markup.base.item';
export class MarkupCircleItem extends MarkupBaseItem {
    private _uniqueId: string;
    private circleHTML: CircleElementCanvas = new CircleElementCanvas();
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupCircle';
        this.shapeName = 'Circle';
        this._uniqueId = this.uniqueId;
        const circleItem = this;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                circleItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2) => {
                circleItem.gripPointDragMoveCallback(point);
            },
            (point: Communicator.Point2) => {
                circleItem.gripPointDragEndCallback(point);
            });
        this.redlineBounding.setRotateGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                circleItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2) => {
                circleItem.rotateGripPointDragMoveCallback(point);
            },
            (point: Communicator.Point2) => {
                circleItem.rotateGripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }
    setPosition(point: Communicator.Point3) {
        this._point1 = point;
    }
    setRadiusPoint(radPoint: Communicator.Point3) {
        this._point2 = radPoint;
    }

    private _update(): void {
        if (!this._point1) return;
        this.circleHTML.setStrokeWidth(this._lineWeight);
        this.circleHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.circleHTML.setFillType(this._fillColorOption ? MarkupFillType.Opaque : MarkupFillType.None);
        this.circleHTML.setFillColor(new Communicator.Color(this._fillColor.r, this._fillColor.g, this._fillColor.b));
        this.circleHTML.setFillOpacity(this._lineOpacity);
        this.circleHTML.setLineStyle(this._lineStyle);

        this.circleHTML.setRotation(this._rotation);
        if (this.redlineBounding) {
            this.redlineBounding.padding = this._lineStyle > 5 ? cloudPadding : boundPadding;
            this.redlineBounding.setRotation(this._rotation);
            this.redlineBounding.updateGripPoints();
            this.redlineBounding.updateRotationTransform(this._rotation);
        }
        this.updateRotateTransform(this._rotation);

        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._point1.copy()),
        );
        this.circleHTML.setFirstPoint(firstPoint);
        let secondPoint = firstPoint.copy();
        if (this._point2 !== null) {
            secondPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._point2.copy()),
            );
            this._isReady = true;
            this.circleHTML.setFirstPoint(firstPoint);
            this.circleHTML.setRadius(secondPoint.copy().subtract(firstPoint).length());
        }
        this.updateBoundingBox(firstPoint, secondPoint);
    }

    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.circleHTML.getCircleCanvas(),
                );
            }
        } else if (this._redlineElementId) {
            this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
        }

        this.handleBoundingRectInteraction();
    }

    updateBoundingBox(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        const pos = this.circleHTML.getFirstPoint();
        const rad = this.circleHTML.getRadius();
        const stroke = this.circleHTML.getStrokeWidth();
        const boxPos = new Communicator.Point2(pos.x - rad - stroke / 2, pos.y - rad - stroke / 2);
        const size = new Communicator.Point2(rad * 2 + stroke, rad * 2 + stroke);
        if (this.redlineBounding) {
            this.redlineBounding.setCenter(firstPoint, secondPoint);
            this.redlineBounding.setPosition(boxPos);
            this.redlineBounding.setSize(size);
        }

    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupCircleItem";
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineOpacity: this._lineOpacity,
            lineStyle: this._lineStyle,
            lineWeight: this._lineWeight,
            fillColor: this._fillColor,
            fillColorOption: this._fillColorOption,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            uniqueId: this.uniqueId,
            point1: this._point1?.copy(),
            point2: this._point2?.copy(),
            rotation: this._rotation,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineOpacity = data.lineOpacity;
        this._lineStyle = data.lineStyle;
        this._lineWeight = data.lineWeight;
        this._fillColor = data.fillColor
        this._fillColorOption = data.fillColorOption;
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._point1 = data.point1;
        this._point2 = data.point2;
        this._rotation = data.rotation;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }
    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.gripPointDragType = type;
        this.updateOppositePoint();
    }

    gripPointDragMoveCallback(point: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let padding = 12;
        if (this.redlineBounding) padding = this.redlineBounding.padding;
        const center = this.circleHTML.getFirstPoint();
        const boundaryPadding = padding + this._lineWeight / 2;
        const p1 = Communicator.Point2.fromPoint3(
            a.projectPoint(this._point1!.copy()),
        );
        const p2 = Communicator.Point2.fromPoint3(
            a.projectPoint(this._point2!.copy()),
        );
        const horizontalDir = (center.x - p1.x > 0 ? 1 : -1);
        const verticalDir = (p1.y - p2.y > 0 ? -1 : 1);
        if (b !== null) {
            switch (this.gripPointDragType) {
                case GripPoint.top:
                case GripPoint.bottom:
                    {
                        const rad = Math.abs(center.y - point.y);
                        const secondPnt = new Communicator.Point2(center.x, center.y + rad - verticalDir * boundaryPadding);
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(secondPnt, a);
                        this._point2!.assign(newPos!);
                        break;
                    }
                case GripPoint.left:
                case GripPoint.right:
                    {
                        const rad = Math.abs(center.x - point.x);
                        const secondPnt = new Communicator.Point2(center.x, center.y + rad + horizontalDir * boundaryPadding);
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(secondPnt, a);
                        this._point2!.assign(newPos!);
                        break;
                    }
                default:
                    {
                        const rad1 = Math.abs(center.x - point.x);
                        const rad2 = Math.abs(center.y - point.y);
                        const rad = rad1 < rad2 ? rad1 : rad2;
                        const secondPnt = new Communicator.Point2(center.x, center.y + rad + verticalDir * horizontalDir * boundaryPadding);
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(secondPnt, a);
                        this._point2!.assign(newPos!);
                        break;
                    }
            }
        }
        this._viewer.markupManager.refreshMarkup();
    }
    rotateGripPointDragMoveCallback(point: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const boundRects = this.circleHTML.getCircleCanvas().getBoundingClientRect();
            const pointX = boundRects.left + boundRects.width / 2;
            const pointY = boundRects.top + boundRects.height / 2;
            const rotateAngle = ((Math.atan2(point.y - pointY, point.x - pointX) + Math.PI / 2) * 180 / Math.PI - 180) % 360;
            this.setRotation(rotateAngle);
            this._update();
            if (this.redlineBounding) {
                this.redlineBounding.setRotation(rotateAngle);
                this.redlineBounding.updateGripPoints();
            }
        }
    }
    onDragStart(point: Communicator.Point2) {
        this.isUpdate = false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this._point1!.add(c);
            this._point2!.add(c);
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }
    hit(point: Communicator.Point2) {
        let ct = Communicator.Point2.zero();
        if (this.redlineBounding) ct = this.redlineBounding.center;
        const pos = MathHelper.convertPointWithRotation(point, ct, 360 - this._rotation);
        return this.circleHTML.hit(pos);
    }
    setMarkupVisible(visible: boolean): void {
        this.circleHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.circleHTML.baseCanvas;
    }
}
