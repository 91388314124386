import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import  {  IdentityApi, ResponseLoginRegister } from "api/identity.api";
import { Login, Register, RootEpic, StateLogin, User } from "common/type-state";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import Utils from "utils/utils";

const initLoginState: StateLogin = {
    loading: false,
    errorMsg: "",
    success: false,
    user: null,
    token: null,
};


const login = createSlice({
    name: "login",
    initialState: initLoginState,
    reducers: {
        loginRequest: (state, action: PayloadAction<Login>) => {
            state.loading = true;
        },
        registerReq(state, action: PayloadAction<Register>) {
            state.loading = true
        },
        loginSuccess: (state, action: PayloadAction<{user: User, token: string}>) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            Utils.setLocalStorage('token', action.payload.token);
            Utils.setLocalStorage('userName', action.payload.user.userName);
            Utils.setLocalStorage('userID', action.payload.user.id);
            state.success = true;
            state.loading = false;
        },
        logout: (state) => {
            state.user = null;
            state.loading = false;
            Utils.removeItemLocalStorage('token');
            Utils.removeItemLocalStorage('userName');
            Utils.removeItemLocalStorage('userID');
        },
        errorLogin: (state, action: PayloadAction<string>) => {
            state.errorMsg = action.payload;
            state.user = null;
            state.loading = false;
            notification.open({
                placement: 'bottomRight',
                message: 'Error',
                description: action.payload,
            })
        }
    },
});

const login$: RootEpic = (action$)=> action$.pipe(
    filter(loginRequest.match),
    switchMap((re) => {
        return IdentityApi.login(re.payload).pipe(
            map((res) => {
                if (res && typeof res.errorCode !== 'undefined') {
                    const newRes = res as ResponseLoginRegister;
                    if (newRes.errorCode === 0) {
                        const user: User = {...newRes.info, setting: newRes.setting};
                        return loginSuccess({user, token: newRes.token});
                    }
                    return errorLogin(newRes.msg);
                }
                return errorLogin('Error body response null');
            }),
            catchError(err => [errorLogin('Error request')])
        )
    })
)
const register$: RootEpic = (action$)=> action$.pipe(
    filter(registerReq.match),
    switchMap((re) => {
        return IdentityApi.register(re.payload).pipe(
            map((res) => {
                if (res && typeof res.errorCode !== 'undefined') {
                    const newRes = res as ResponseLoginRegister;
                    if (newRes.errorCode === 0) {
                        const user: User = {...newRes.info, setting: newRes.setting};
                        return loginSuccess({user, token: newRes.token});
                    }
                    return errorLogin(newRes.msg);
                }
                return errorLogin('Error body response null');
            }),
            catchError(err => [errorLogin('Error request')])
        )
    })
)

export const loginEpic = [
    login$,
    register$
]
export const {
    loginRequest,
    loginSuccess,
    logout,
    errorLogin ,
    registerReq
} = login.actions;
export default login.reducer;
