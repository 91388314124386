import { createSelector } from "@reduxjs/toolkit";
import { selectSelfLineFormat } from "./self.selector";


export const getMarkupLineFormat = createSelector(
    selectSelfLineFormat,
    (state) => state
)

export const getMarkupLineWeight = createSelector(
    selectSelfLineFormat,
    (state) => state.lineWeight
)