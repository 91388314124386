import { AnnotRect } from "./type-markup";

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum FoxitState {
    hand = 'hand',                                          // STATE_HANDLER_HAND
    createFreeTextBox = 'createFreeTextBox',                // STATE_HANDLER_CREATE_TEXTBOX
    createFreeTextCallout = 'createFreeTextCallout',        // STATE_HANDLER_CREATE_FREE_TEXT_CALLOUT
    createCaret = 'createCaret',                            // STATE_HANDLER_CREATE_CARET
    createArrow = 'createArrow',                            // STATE_HANDLER_CREATE_ARROW
    createAreaHighlight = 'createAreaHighlight',            // STATE_HANDLER_CREATE_CREATE_AREA_HIGHLIGHT
    createCircle = 'createCircle',                          // STATE_HANDLER_CREATE_CIRCLE
    createFileAttachment = 'createFileAttachment',          // STATE_HANDLER_CREATE_FILE_ATTACHMENT
    createHighlight = 'createHighlight',                    // STATE_HANDLER_CREATE_HIGHLIGHT
    createImage = 'createImage',                            // STATE_HANDLER_CREATE_IMAGE
    createEditImage = 'createEditImage',                    // STATE_HANDLER_CREATE_EDIT_IMAGE
    createLink = 'createLink',                              // STATE_HANDLER_CREATE_LINK
    createLine = 'createLine',                              // STATE_HANDLER_CREATE_LINE
    createPencil = 'createPencil',                          // STATE_HANDLER_CREATE_PENCIL
    createPolygonCloud = 'createPolygonCloud',              // STATE_HANDLER_CREATE_POLYGON_CLOUD
    createPolygon = 'createPolygon',                        // STATE_HANDLER_CREATE_POLYGON
    createPolyline = 'createPolyline',                      // STATE_HANDLER_CREATE_POLYLINE
    createReplace = 'createReplace',                        // STATE_HANDLER_CREATE_REPLACE
    createSquare = 'createSquare',                          // STATE_HANDLER_CREATE_SQUARE
    createArc = 'createArc',                                // STATE_HANDLER_CREATE_ARC
    createSquiggly = 'createSquiggly',                      // STATE_HANDLER_CREATE_SQUIGGLY
    createStamp = 'createStamp',                            // STATE_HANDLER_CREATE_STAMP
    createStrikeOut = 'createStrikeOut',                    // STATE_HANDLER_CREATE_STRIKE_OUT
    createText = 'createText',                              // STATE_HANDLER_CREATE_TEXT
    createUnderline = 'createUnderline',                    // STATE_HANDLER_CREATE_UNDERLINE
    marquee = 'marquee',                                    // STATE_HANDLER_MARQUEE
    eraser = 'eraser',                                      // STATE_HANDLER_ERASER
    loupe = 'loupe',                                        // STATE_HANDLER_LOUPE
    createSignatureField = 'createSignatureField',          // STATE_HANDLER_CREATE_FIELD_SIGNATURE
    createDistance = 'createDistance',                      // STATE_HANDLER_CREATE_DISTANCE
    createPerimeter = 'createPerimeter',                    // STATE_HANDLER_CREATE_PERIMETER
    createArea = 'createArea',                              // STATE_HANDLER_CREATE_AREA
    createCircleArea = 'createCircleArea',                  // STATE_HANDLER_CREATE_CIRCLE_AREA
    // use this as default select mode
    selectAnnotation = 'select-annotation',                 // STATE_HANDLER_SELECT_ANNOTATION
    selectTextImage = 'select-text-image',                  // TATE_HANDLER_SELECT_TEXT_IMAGE
}

export interface DefaultAnnotAttribute {
    borderInfo: {
        width: number
    };
    color: number
}
export enum Navigate {
    Previous = 0,
    Next = 1,
}
export interface ResultEventSelectHyperLink {
    info: {
        action: {
            uri: string;
            destination: any
        }
    }
}
export enum FoxitZoomType {
    ZoomIn = 0,
    ZoomOut = 1,
    ZoomFit = 2,
    ZoomSelected = 3,
    ZoomInKeyboard = 4,
    ZoomOutKeyboard = 5,
    Other = 6,
}
export interface StampMarkup {
    type: string,
    rect: AnnotRect,
    icon: string,
    iconCategory: string,
}

export interface CustomizeStampIcon {
    annotType: string,
    fileType: string,
    url: string,
    category: string,
    name: string
    width?: number,
    height?: number,
    showUrl?: string
}

export interface PdfStampItem {
    category: string,
    name: string,
    fileType: string,
    url: string,
    localURL: string,
}

export interface AnnotActiveResult {
    id: string,
    active: boolean
}