import { EnterOutlined, FontSizeOutlined } from '@ant-design/icons';
import { Button, Input } from "antd";
import { CTooltip } from 'components/CTooltip'
import { useContextKeyBinding } from 'container/shortcut-keys/keybinding.context';
import React, { useEffect, useRef, useState } from "react";
import { Observable, Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import CLoading from "./CLoading";

interface DialogInputProps extends Props {
    title?: string;
    description?: string;
    handleCloseDialog: () => void;
    handleYesDialog: (text: string) => void;
    arrText: string[];
    loading: boolean
}

export default function CDialogInput(props: DialogInputProps): JSX.Element {
    const {title, description, arrText, loading, handleCloseDialog, handleYesDialog} = props;
    const [error, setError] = useState<string>('');
    const [input, setInput] = useState<string>();
    const refChangeInput = useRef<Subject<string>>(new Subject());
    const contextKeyBindingValue = useContextKeyBinding();
    
    useEffect(() => {
        if(contextKeyBindingValue && contextKeyBindingValue.callActions){
            contextKeyBindingValue.callActions({type: 'set-can-run-cmd', payload: false});
            return () => {contextKeyBindingValue.callActions({type: 'set-can-run-cmd', payload: true})}
        }
    }, [contextKeyBindingValue])

    useEffect(() => {
        const sub = refChangeInput.current.pipe(
            debounceTime(200),
            switchMap(text => {
                return new Observable<string>(obser => {
                    if (arrText.includes(text)) {
                        obser.error('error duplicate')
                    } else {
                        obser.next('clear')
                    }
                    obser.complete();
                })
            })
        ).subscribe({
            next: _ => error !== '' && setError(''),
            error: (err) => setError(err)
        })
        return () => {
            sub && sub.unsubscribe()
        }
    }, [arrText, error])

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setInput(value);
        refChangeInput.current.next(value)
    }

    const isDisable = !!error || !input || loading;
    function handleYes() {
        if (!isDisable && input) {
            handleYesDialog(input);
            handleCloseDialog()
        }
    }

    return (
        <div className={'confirm-dialog-yes-no'}>
            <div className='confirm-dialog-yes-no-title flex flex-col'>
                <span>{title || 'Enter text input'}</span>
                <CTooltip title={description?? ''}>
                    {description? <span className="text-gray-500 text-xs truncate">{description}</span> : <br/>}
                </CTooltip>
            </div>
            <CLoading visible={loading} noOpacityBackground={true} size='small' className='confirm-dialog-yes-no-content'>
                <Input className='custom-input' 
                    autoFocus={true} 
                    prefix={<FontSizeOutlined style={{paddingRight: 4, borderRight: '1px solid #AAB2BF'}} />} 
                    suffix={
                        <CTooltip title="Enter">
                            <EnterOutlined />
                        </CTooltip>
                    } 
                    value={input} 
                    onChange={onChange} 
                    onPressEnter={handleYes} />
                {error && <div style={{color: 'crimson', textAlign: 'initial', paddingTop: 8}}>*Error: {error}</div>}
            </CLoading>
            <div className='confirm-dialog-yes-no-footer'>
                <Button type="text" disabled={isDisable} onClick={handleYes}>Yes</Button>
                <Button type="text" onClick={handleCloseDialog}>No</Button>
            </div>
        </div>
    )
}
