/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { MarkupBaseJson, MarkupFillType } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { CustomGrippointElement } from '../markup-canvas-elements/markup.custom-grippoint.element';
import { PolylineElementCanvas } from '../markup-canvas-elements/markup.polyline-canvas.element';
import { MarkupBaseItem } from './markup.base.item';
export class MarkupPolygonItem extends MarkupBaseItem {
    private points: Communicator.Point3[] = [];
    public _polygonEdge = 5;
    private polygonHTML: PolylineElementCanvas = new PolylineElementCanvas();
    private _uniqueId: string;
    private customGrippoints: CustomGrippointElement[] = [];
    private customGrippointIndex = -1;
    private isClose = true;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupPolygon';
        this.shapeName = 'Polygon';
        this._uniqueId = this.uniqueId;
        const polygonItem = this;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(false);
        this.redlineBounding.setGripDirection([]);
        this.redlineBounding.setRotateGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                polygonItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2, tooltipPos: Communicator.Point2) => {
                this.rotateGripPointDragMoveCallback(point, tooltipPos);
            },
            (point: Communicator.Point2) => {
                polygonItem.rotateGripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }

    private _update(): void {
        this.polygonHTML.setStrokeWidth(this._lineWeight);
        this.polygonHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.polygonHTML.setLineStyle(this._lineStyle);
        this.polygonHTML.setFillType(this._fillColorOption ? MarkupFillType.Opaque : MarkupFillType.None);
        this.polygonHTML.setFillColor(new Communicator.Color(this._fillColor.r, this._fillColor.g, this._fillColor.b));
        this.polygonHTML.setFillOpacity(this._lineOpacity);

        this.polygonHTML.setRotation(this._rotation);
        if (this.redlineBounding) {
            this.redlineBounding.setRotation(this._rotation);
            this.redlineBounding.updateGripPoints();
            this.redlineBounding.updateRotationTransform(this._rotation);
        }
        this.updateRotateTransform(this._rotation);

        const { view } = this._viewer;
        this.polygonHTML.clearPoints();
        this.points.forEach((value) => {
            const pnt = Communicator.Point2.fromPoint3(view.projectPoint(value));
            this.polygonHTML.pushPoint(pnt);
        });
        if (this.isClose && this.points.length > 0) {
            const pnt = Communicator.Point2.fromPoint3(view.projectPoint(this.points[0]));
            this.polygonHTML.pushPoint(pnt);
        }
        if (this.points.length > 1) this._isReady = true;
        this.updateBoundingBox();
        this.updateCustomGrippoint();
    }
    updateBoundingBox() {
        const points = this.polygonHTML.getPoints();
        if (!points || !points.length) return;
        const stroke = this.polygonHTML.getStrokeWidth();
        let maxX = points[0].x;
        let maxY = points[0].y;
        let minX = points[0].x;
        let minY = points[0].y;
        if (!this.redlineBounding) return;
        points.forEach((value) => {
            if (value.x > maxX) maxX = value.x;
            if (value.y > maxY) maxY = value.y;
            if (value.x < minX) minX = value.x;
            if (value.y < minY) minY = value.y;
        });
        const pos = new Communicator.Point2(minX - stroke / 2, minY - stroke / 2);
        const size = new Communicator.Point2(maxX - minX + stroke, maxY - minY + stroke);
        this.redlineBounding.setCenter(
            new Communicator.Point2(minX, minY),
            new Communicator.Point2(maxX, maxY),
        );
        this.redlineBounding.setPosition(pos);
        this.redlineBounding.setSize(size);
        this.center = this.redlineBounding.center;
    }

    updateCustomGrippoint() {
        const points = this.polygonHTML.getPoints();
        if (!points || !this.customGrippoints || points.length - 1 !== this.customGrippoints.length) return;
        points.pop();
        points.forEach((v, i) => {
            const gripPt = this.customGrippoints[i];
            gripPt.center = this.center;
            gripPt.rotation = this._rotation;
            const point = MathHelper.convertPointWithRotation(v.copy(), this.center, this._rotation, gripPt.gripSize);
            gripPt.setPosition(point);
        })
    }


    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.polygonHTML.getCanvas(),
                );
            }
        } else if (this._redlineElementId) {
            this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
        }

        this.handleBoundingRectInteraction(() => {
            this.customGrippoints && this.customGrippoints.forEach((grPnt) => {
                if (grPnt && this.boundingIds) {
                    const id = this._viewer.markupManager.addMarkupElement(grPnt.getGrippointElement());
                    this.boundingIds.push(id);
                }
            });
        });
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupPolygonItem";
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineOpacity: this._lineOpacity,
            lineStyle: this._lineStyle,
            lineWeight: this._lineWeight,
            fillColor: this._fillColor,
            fillColorOption: this._fillColorOption,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            polygonEdge: this._polygonEdge,
            uniqueId: this.uniqueId,
            points: this.points.map(val => val.toJson()),
            rotation: this._rotation,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineOpacity = data.lineOpacity;
        this._lineStyle = data.lineStyle;
        this._lineWeight = data.lineWeight;
        this._fillColor = data.fillColor
        this._fillColorOption = data.fillColorOption;
        this._polygonEdge = data.polygonEdge;
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        const points = data.points as any[];
        this.points = [];
        if (points && points.length > 1) {
            points.forEach(val => this.addPoint(Communicator.Point3.fromJson(val)));
        }
        this._rotation = data.rotation;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }

    rotateGripPointDragMoveCallback(point: Communicator.Point2, tooltipPos: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const boundRects = this.getHTMLElement().getBoundingClientRect();
            const pointX = boundRects.left + boundRects.width / 2;
            const pointY = boundRects.top + boundRects.height / 2;
            const rotateAngle = ((Math.atan2(point.y - pointY, point.x - pointX) - Math.PI / 2) * 180 / Math.PI) % 360;
            this.setRotation(rotateAngle);
            this._update();
            if (this.redlineBounding) {
                this.redlineBounding.setRotation(rotateAngle);
                // this.redlineBounding.updateGripPoints();
            }
        }
    }

    onDragStart(point: Communicator.Point2) {
        this.isUpdate = false;
        if (!this._isCanEdit) return false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c: any = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this.points.forEach((value) => value.add(c));
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }

    onCustomGrippointDragStart(point: Communicator.Point2, index: number) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.customGrippointIndex = index;
    }

    onCustomGrippointDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return;
        this.isUpdate = true;
        const { view } = this._viewer;
        const newPoint = MathHelper.convertPointWithRotation(point, this.center, -this._rotation);
        const newPos = view.getCamera().getCameraPlaneIntersectionPoint(newPoint, view);
        if (this.customGrippointIndex >= 0 && this.customGrippointIndex < this.points.length) {
            this.points[this.customGrippointIndex].assign(newPos!);
            this._viewer.markupManager.refreshMarkup();
        }
    }

    onCustomGrippointDragEnd(point: Communicator.Point2) {
        if (this.isUpdate) {
            this.triggerOnMarkupUpdated();
        }
        this.isUpdate = false;
        this.customGrippointIndex = -1;
    }

    addPoint(point: Communicator.Point3) {
        this.points.push(point.copy());
        const index = this.points.length - 1;
        const grPoint = new CustomGrippointElement(
            this._viewer,
            (pnt: Communicator.Point2) => {
                this.onCustomGrippointDragStart(pnt, index);
            },
            (pnt: Communicator.Point2) => {
                this.onCustomGrippointDragMove(pnt);
            },
            (pnt: Communicator.Point2) => {
                this.onCustomGrippointDragEnd(pnt);
            },
        );
        this.customGrippoints.push(grPoint);
    }

    popPoint() {
        this.points.pop();
        this.customGrippoints.pop();
    }

    updateLastPoint(point: Communicator.Point3) {
        if (!point) return;
        this.points.pop();
        this.points.push(point);
    }

    getPoints() {
        const rets: any[] = [];
        this.points.forEach((value) => {
            rets.push(value.copy());
        });
        return rets;
    }

    setClose(value: boolean) {
        this.isClose = value;
    }

    dragTo(point: Communicator.Point2) {
        if (!point || !this.points || !this.points.length) return;
        const { view } = this._viewer;
        const newPos = view.getCamera().getCameraPlaneIntersectionPoint(point, view);
        const oldPos = this.points[0].copy();
        if (newPos && oldPos) {
            const tmp = Communicator.Point3.subtract(newPos, oldPos);
            this.points.forEach((value) => value.add(tmp));
        }
    }
    getBoundingBox(): Communicator.Point3[] {
        const ret: Communicator.Point3[] = [];
        this.points && this.points.forEach((value) => {
            if (value) {
                ret.push(value.copy());
            }
        });
        return ret;
    }

    hit(point: Communicator.Point2) {
        return this.polygonHTML.hit(point);
    }
    setMarkupVisible(visible: boolean): void {
        this.polygonHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        this.customGrippoints.forEach(v => v.setVisibleGripPoints(visible));
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.polygonHTML.baseCanvas;
    }
    setBoundingGripPointVisible(vis: boolean): void {
        this.customGrippoints.forEach(point => point.setVisible(vis));
    }
}
