import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupedNotepin, NotepinData, NotepinJson, NotepinSelection } from "common/define";
import { RootEpic } from "common/type-state";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";
import { merge } from "rxjs";
import { GlobalState } from "common/global";
import { updateSelectionItem } from "./viewer3D.slice";
interface NotepinActionState {
    activeTab: string;
    groupedData: GroupedNotepin[];
    currentInfoNotepin: GroupedNotepin | undefined;
    notepinSelectionArr: NotepinJson[];
    activeNodeSelectionArr: number[];
}

const initState: NotepinActionState = {
    activeTab: '1',
    groupedData: [],
    currentInfoNotepin: undefined,
    notepinSelectionArr: [],
    activeNodeSelectionArr: [],
}

const notepinActiveSlice = createSlice({
    name: 'notepinAction',
    initialState: initState,
    reducers: {
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload
        },
        setNewNotepinAction(state, action: PayloadAction<NotepinData>) { return },
        setGroupedNotepin(state, action: PayloadAction<GroupedNotepin[]>) {
            state.groupedData = action.payload;
        },
        setCurrentInfoNotepin(state, action: PayloadAction<GroupedNotepin | undefined>) {
            state.currentInfoNotepin = action.payload;
        },
        setNotepinSelectionArr(state, action: PayloadAction<NotepinJson[]>) {
            state.notepinSelectionArr = action.payload;
        },
        updateNotepinSelection(state, action: PayloadAction<NotepinSelection>) { return },
        setActiveNodeSelectionArr(state, action: PayloadAction<number[]>) {
            state.activeNodeSelectionArr = action.payload;
            GlobalState.activeNodeSelectionArr$.next(action.payload);
        },
    }
})

const setNewNotepinAction$: RootEpic = (action$, state$) => action$.pipe(
    filter(setNewNotepinAction.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { props, activePin, activeViewId } = action.payload;
        const { viewActive } = state.multiViewer;
        const viewer = GlobalState.getViewer3D(viewActive.viewId);
        if (props && viewer) {
            const newGroupedNotepinItem: GroupedNotepin = {
                nodeList: props,
                notePin: activePin.toJson(),
                activeViewId: activeViewId,
            }
            const newGroupedNotepinList = [...state.notepin.groupedData, ...[newGroupedNotepinItem]];
            viewer.selectionManager.clear();
            return merge([
                setGroupedNotepin(newGroupedNotepinList)
            ])
        }
        return []
    }))

const updateNotepinSelection$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateNotepinSelection.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const notepinSelection = action.payload;
        const currGroupedData = state.notepin.groupedData;
        const viewId = notepinSelection.viewId;
        const viewer = GlobalState.getViewer3D(viewId);
        let nodeForHighlight: number[] = [];
        if (notepinSelection.selectionArray.length > 0) {
            notepinSelection.selectionArray.forEach(v => {
                const matchedPin = currGroupedData.find(d => d.notePin.uniqueId === v.uniqueId);
                if (matchedPin) {
                    nodeForHighlight = [...nodeForHighlight, ...matchedPin.nodeList]
                }
            })
            nodeForHighlight = Array.from(new Set(nodeForHighlight));
            if (viewer) {
                viewer.model.setNodesHighlighted(nodeForHighlight, true);
            }
        } else {
            // remove all highlight
            viewer && viewer.model.setNodesHighlighted([viewer.model.getAbsoluteRootNode()], false);
        }
        return [
            updateSelectionItem({ viewId: viewId, selectionArray: nodeForHighlight, type: 'InModel' }),
            setNotepinSelectionArr(notepinSelection.selectionArray)
        ]
    }))

export const NotepinActionEpics = [
    setNewNotepinAction$,
    updateNotepinSelection$,
]

export const {
    setActiveTab,
    setNewNotepinAction,
    setGroupedNotepin,
    setCurrentInfoNotepin,
    setNotepinSelectionArr,
    updateNotepinSelection,
    setActiveNodeSelectionArr,
} = notepinActiveSlice.actions;

export default notepinActiveSlice.reducer;