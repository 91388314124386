import { createSelector } from "@reduxjs/toolkit";
import { GlobalState } from "common/global";
import { selectSelfMultiViewer, selectSelfTree } from "./self.selector";

export const getViewIdAndTreeState = createSelector(
    selectSelfTree,
    selectSelfMultiViewer,
    (tree, multiViewer) => {
        const { viewId, extension } = multiViewer.viewActive;
        const viewIdFinal = GlobalState.getViewId(viewId);
        return {
            treeState: tree,
            viewIdActive: viewId,
            viewIdFinal,
            extension
        }
    }
)
export const getTreeInfoByViewId = createSelector(
    selectSelfTree,
    selectSelfMultiViewer,
    (_: unknown, viewId: ViewId | undefined) => viewId,
    (tree, multiViewer, viewId) => {
        const viewIdTree = viewId || multiViewer.viewActive.viewId;
        return {
            viewIdTree: viewIdTree,
            viewIdFinal: !viewId ? GlobalState.getViewId(viewIdTree) : multiViewer.viewActive.viewId,
            treeState: tree
        }
    }
)
export const getCurrentModelFileId = createSelector(
    selectSelfTree,
    (state) => state.currModelFileId
)
export const getTreeLoading = createSelector(
    selectSelfTree,
    (state) => state.loadingTreeData
)
export const getCurrentPropertyData = createSelector(
    selectSelfTree,
    (_: unknown, modelFileId: string) => modelFileId,
    (state, modelFileId) => {
        const treeMap = state.currentTreeMap;
        if (treeMap) {
            const filterMap = new Map(treeMap);
            treeMap.forEach((v, k) => {
                if (!v?.Childs?.length) return;
                filterMap.delete(k);
            });
            return {
                loading: state.treeLoadingMap[modelFileId],
                treeData: filterMap,
            }
        }
        return {
            loading: false,
            treeData: treeMap,
        }
    }
)
export const getTreeMapData = createSelector(
    selectSelfTree,
    (state) => state.treeMapData
)
export const getCurrentTreeMap = createSelector(
    selectSelfTree,
    (state) => state.currentTreeMap
)
export const getVisibilityChangedCallback = createSelector(
    selectSelfTree,
    (state) => state.visibilityChangedCallback
)
export const getTreeLoadingMap = createSelector(
    selectSelfTree,
    (state) => state.treeLoadingMap
)
export const getUpdateShowHide = createSelector(
    selectSelfTree,
    (state) => state.updateShowHide
)
export const getUpdateHoopsVisibleState = createSelector(
    selectSelfTree,
    (state) => state.updateHoopsVisibleState
)
export const getTreeRefresh = createSelector(
    selectSelfTree,
    (state) => state.isRefresh
)