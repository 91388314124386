
import { createSelector } from "@reduxjs/toolkit";
import { selectSelfMultiViewer, selectSelfViewer3D } from "./self.selector";

export const getOperatorByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.operator[viewId]
    }
)
export const getWebViewerStateByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.webViewerState[viewId]
    }
)
export const getModelStructureReadyViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.modelStructureReady[viewId]
    }
)
export const getLoading3DByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.loadingViewer[viewId]
    }
)
export const getCuttingPlaneByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.cuttingPlane[viewId]
    }
)
export const getCuttingPlaneBoxByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.planeBoxChildState[viewId]
    }
)
export const getOperatorModelStructureReadyViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return {
            modelStructureReady: state.modelStructureReady[viewId],
            operatorState: state.operator[viewId]
        }
    }
)
export const getSelectionAndHidedItemByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return {
            selectionArray: state.selectionItem[viewId] || [],
            hidedItems: state.hidedItem[viewId] || []
        }
    }
)
export const getHidedItemByViewId = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => state.hidedItem[viewId]
)
export const getSelectionItem = createSelector(
    selectSelfViewer3D,
    selectSelfMultiViewer,
    (state3D, stateMulti) => {
        const {
            viewId: viewIdActive,
            // extension 
        } = stateMulti.viewActive;
        // const viewIdFinal = GlobalState.getViewId(viewIdActive);
        // let viewIdApply = viewIdActive;
        // if (viewIdActive !== viewIdFinal && extension === FileExtension.Revit) {
        //     viewIdApply = viewIdFinal
        // }
        return state3D.selectionItem[viewIdActive]
    }
)
export const getCurrentStateOperator = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        if (!viewId.includes('blank'))
            return state.operator[viewId].currentOperator[1]
    }
)
export const getCurrentDrawMode = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => state.operator[viewId].currentOperator[0]
)

export const get3DViewerState = createSelector(
    selectSelfViewer3D,
    (state) => state
)
export const getAllSelectionItem = createSelector(
    selectSelfViewer3D,
    (state) => state.selectionItem
)
export const getAllModelStructureReady = createSelector(
    selectSelfViewer3D,
    (state) => state.modelStructureReady
)
export const getOperatorState = createSelector(
    selectSelfViewer3D,
    (state) => state.operator
)
export const getAllWebViewerState = createSelector(
    selectSelfViewer3D,
    (state) => state.webViewerState
)

export const getViewIdForTree = createSelector(
    selectSelfViewer3D,
    selectSelfMultiViewer,
    (state3D, stateMulti) => {
        const {
            viewId: viewIdActive,
            // extension 
        } = stateMulti.viewActive;
        // const viewIdFinal = GlobalState.getViewId(viewIdActive);
        // let viewIdApply = viewIdActive;
        // if (viewIdActive !== viewIdFinal && extension === FileExtension.Revit) {
        //     viewIdApply = viewIdFinal
        // }
        return viewIdActive;
    }
)
export const getIsCuttingActive = createSelector(
    selectSelfViewer3D,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        const cuttingState = state.cuttingPlane[viewId];
        const length = (cuttingState && cuttingState.active)? cuttingState.active.length : 0;
        if (length === 0) return false
        return true
    }
)