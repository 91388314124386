import { createSelector } from "@reduxjs/toolkit";
import { selectSelfViews } from "./self.selector";

export const getViewsState = createSelector(
    selectSelfViews,
    (state) => state
)
export const getCurrentViewSelected = createSelector(
    selectSelfViews,
    (state) => state.currentViewSelected
)
export const getCurrentViews = createSelector(
    selectSelfViews,
    (state) => state.currentViews
)

export const getSaveViewsStateByViewId = createSelector(
    selectSelfViews,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        const {loading, currentViews} = state;
        return {
            saveViews: currentViews?.savedViews || [],
            loading
        }
    }
)