/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from "@reduxjs/toolkit";
import { selectSelfProperties } from "./self.selector";

export const getCurrentMapProperties = createSelector(
    selectSelfProperties,
    (state) => state.currentMapProperties
)
export const getLoadingProperties = createSelector(
    selectSelfProperties,
    (state) => state.loadingProperties
)
export const getCmicProperty = createSelector(
    selectSelfProperties,
    (state) => state.cmicProperty
)
export const getCmicLoading = createSelector(
    selectSelfProperties,
    (state) => state.cmicLoading
)



