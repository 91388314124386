/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GlobalState } from "common/global";
import AreaSelectionOperator from "../custom-operator/area-selection.operator";
import GeneralOperator from "../custom-operator/general.operator";
import BaseOperator from "./base.operator";

export default class ZoomWindowOperator extends BaseOperator {
    areaSelectionOperator: AreaSelectionOperator;

    areaHandle: any;

    generalOperator: GeneralOperator;

    generalHandle: any;
    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.Name = 'zoomWindow';
        this.areaSelectionOperator = new AreaSelectionOperator(viewer);
        this.areaHandle = viewer.operatorManager.registerCustomOperator(
            this.areaSelectionOperator,
        );

        this.generalOperator = new GeneralOperator(viewer);
        this.generalHandle = viewer.operatorManager.registerCustomOperator(
            this.generalOperator,
        );
    }
    public ClearTemp() {
        this.areaSelectionOperator.clearTemp();
    }

    /**
     * Active
     *
     */
    public Active() {
        if (this.Status === false) {
            this.Viewer.operatorManager.push(this.generalHandle);

            this.Viewer.operatorManager.push(this.areaHandle);
            this.Viewer.operatorManager
                .getOperator(this.areaHandle)
                .setMapping(Communicator.Button.Left);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(
                Communicator.Button.None,
                Communicator.KeyModifiers.Control,
            );
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).setMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan).addMapping(Communicator.Button.Right);

            this.Status = true;
            GlobalState.mapOperatorCursor.set('zoomWindow', 'url("/assets/cursor/zoom_window.cur"), auto');
            GlobalState.primaryOperator.set(this.Viewer, 'zoomWindow');
            super.SetCursor('url("/assets/cursor/zoom_window.cur"), auto');
        }

        if (this.Status === true) { // using for update the mode changed
        }
    }

    /**
     * InActive
     *
     */
    public InActive() {
        if (this.Status === true) {
            this.Escape();
            this.Viewer.operatorManager.remove(this.generalHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.generalHandle);
            this.Viewer.operatorManager.remove(this.areaHandle);
            this.Viewer.operatorManager.unregisterCustomOperator(this.areaHandle);
            this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Orbit).setMapping(Communicator.Button.Left);
            this.ResetDefautOperators();
            this.Status = false;
            super.SetCursor('');
        }
    }

    public Escape(): boolean {
        this.areaSelectionOperator.escape();
        return true;
    }

    GetIdOperator(): number {
        return this.areaHandle;
    }
}
