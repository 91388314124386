/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createUseStyles, ThemeProvider } from "react-jss";
const ThemeInput = require('./theme-define');
const {palette, zIndex} = ThemeInput;

export interface ThemePalette {
    primary: string;
    default: string;
    secondary: string;
    background: string;
    light: string;
    backgroundGray: string;
    text: string;
    disabled: string;
    border: string;
    gray: string;
    error: string;
    warning: string;
    origin: string;
    lightgray: string;
    lightgray2: string;
    lightgray3: string;
    lightgray4: string,
    gray2: string;
    gray3: string;
    lightBlue: string;
    lightBlue2: string;
    lightBlue3: string;
    lightBlue4: string;
    darkGray: string;
    darkGray2: string;
    red: string;
    gray4: string;
    yellow: string;
    dark: string;
    blue1: string;
    white: string;
}

export interface ThemeDefine {
    palette: ThemePalette;
    shadows: {[key: number]: string};
    shape: {
        borderRadius: string
    };
    zIndex: {
        dialog: number,
        loading: number,
        panel: number
    };
    spacing: (numY: number, numX?: number) => string;
}

const spacingVal = 8;
export const themeValue: ThemeDefine = {
    palette: {
        primary: palette.primary,
        default: palette.primary,
        secondary: palette.secondary,
        background: '#fff',
        light: '#fff',
        backgroundGray: palette['gray-bg'],
        text: 'rgba(0, 0, 0, 0.87)',
        disabled: palette.disabled,
        border: palette.border,
        gray: palette.gray,
        error: '#CF1322',
        warning: '#FFCB1C',
        origin: '',
        lightgray: '#f5f5f5',
        lightgray2: '#E5E7EB',
        lightgray3: '#D1D5DB',
        lightgray4: '#999999',
        gray2: "rgba(250, 250, 250, 1)",
        gray3: "rgba(199, 199, 199, 1)",
        lightBlue: '#4285F4',
        lightBlue2: 'rgb(218, 233, 245)',//'rgb(229,237,253)',
        lightBlue3: 'rgb(202,219,251)',
        lightBlue4: 'rgba(127, 167, 253, 1)',
        darkGray: '#6B7280',
        red: 'rgba(220, 38, 38, 0.6)',
        gray4: 'rgb(240,240,240)',
        yellow: 'rgba(255,255,0,0.7)',
        dark: 'rgba(51,51,51,1)',
        darkGray2: '#666989',
        blue1: '#3F95CC',
        white: 'rgb(255, 255, 255)'
    },
    shadows: {
        0: "none",
        1: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        2: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        3: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        4: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        5: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        6: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        7: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        8: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        9: "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        10: "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        11: "0px 3px 6px rgb(0 0 0 / 35%)",
    },
    shape: {
        borderRadius: '4px',
    },
    zIndex: {
        dialog: zIndex.default,
        loading: zIndex.loading,
        panel: zIndex.panel
    },
    spacing: Spacing,
}
function Spacing(numY: number, numX?: number): string {
    if (numX === undefined) {
        return `${numY * spacingVal}px`;
    }
    return `${numY * spacingVal}px ${numX * spacingVal}px`;
}

export function createStyles<T extends string>(fn: (data: ThemeDefine) => Record<T, any>) {
    return createUseStyles(fn);
}
export default function MainThemeProvider({children}: Props): JSX.Element {
    return (
        <ThemeProvider theme={themeValue}>
            {children}
        </ThemeProvider>
    )
}