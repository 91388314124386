import { GlobalState } from 'common/global';
import { Point } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { primaryBoundingColor } from 'utils/utils';
import { MarkupArrowItem } from '../markup-items/markup.arrow.item';
import { MarkupBaseItem } from '../markup-items/markup.base.item';
import { MarkupCalloutItem } from '../markup-items/markup.callout.item';
import { MarkupCircleItem } from '../markup-items/markup.circle.item';
import { MarkupCloudItem } from '../markup-items/markup.cloud.item';
import { MarkupEllipseItem } from '../markup-items/markup.ellipse.item';
import { MarkupLineItem } from '../markup-items/markup.line.item';
import { MarkupNoteItem } from '../markup-items/markup.note.item';
import { MarkupPolygonItem } from '../markup-items/markup.polygon.item';
import { MarkupPolylineItem } from '../markup-items/markup.polyline.item';
import { MarkupRectangleItem } from '../markup-items/markup.rectangle.item';
import { MarkupSignatureItem } from '../markup-items/markup.signature.item';
import { MarkupTextBoxItem } from '../markup-items/markup.textbox.item';
import { MarkupData } from '../markup.data';
export class RedlineRectangleSelection extends Communicator.Markup.Redline.RedlineItem {
    private _uniqueId: Communicator.Uuid = Communicator.UUID.create();
    private _point1: Communicator.Point2 = Communicator.Point2.zero();
    private _point2: Communicator.Point2 = Communicator.Point2.zero();
    private _rectangleShape: Communicator.Markup.Shape.Rectangle = new Communicator.Markup.Shape.Rectangle();

    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this._rectangleShape.setFillOpacity(0.1);
        this._rectangleShape.setStrokeColor(primaryBoundingColor.communicatorColor);
        this._rectangleShape.setStrokeWidth(0.5);
        this._rectangleShape.setFillColor(primaryBoundingColor.communicatorColor)
    }

    public setPoint1(point1: Communicator.Point2): void {
        this._point1.assign(point1);
    }

    public setPoint2(point2: Communicator.Point2): void {
        this._point2.assign(point2);
    }
    public getUniqueId(): Communicator.Uuid {
        return this._uniqueId;
    }
    public isValid(): boolean {
        const size = this._rectangleShape.getSize();
        return size.x > 1 && size.y > 1;
    }
    public update(): void {
        const screenPoint1 = this._point1;
        const screenPoint2 = this._point2;
        const min = new Communicator.Point2(Math.min(screenPoint1.x, screenPoint2.x), Math.min(screenPoint1.y, screenPoint2.y));
        const max = new Communicator.Point2(Math.max(screenPoint1.x, screenPoint2.x), Math.max(screenPoint1.y, screenPoint2.y));
        const size = Communicator.Point2.subtract(max, min);
        this._rectangleShape.setPosition(min);
        this._rectangleShape.setSize(size);
    }

    public draw(): void {
        this.update();
        this._viewer.markupManager.getRenderer().drawRectangle(this._rectangleShape);
    }

    public getClassName(): string {
        return "Communicator.Markup.Redline.RedlineRectangleSelection";
    }
}
export class RedlineOsnapPointItem extends Communicator.Markup.Redline.RedlineItem {
    private _uniqueId: Communicator.Uuid = Communicator.UUID.create();
    private _point: Communicator.Point2 = Communicator.Point2.zero();
    private _shape: Communicator.Markup.Shape.Circle = new Communicator.Markup.Shape.Circle();
    private _radiusSize = 9;
    private _isVisible = false;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        const borderColor = new Communicator.Color(208, 2, 27);
        this._shape.setStrokeWidth(2);
        this._shape.setCenter(this._point);
        this._shape.setStrokeColor(borderColor);
        this._shape.setRadius(9);
        this._shape.setFillOpacity(0);

    }
    public setVisible(vis: boolean): void {
        this._isVisible = vis;
    }
    public setPoint(point: Communicator.Point2): void {
        this._point.assign(point);
    }
    public getUniqueId(): Communicator.Uuid {
        return this._uniqueId;
    }
    public update(): void {
        this._shape.setCenter(this._point);
        this._shape.setRadius(this._radiusSize);
    }
    setSize(radius: number): void {
        this._radiusSize = radius;
    }
    public draw(): void {
        this.update();
        if (this._viewer && this._isVisible) {
            this._viewer.markupManager.getRenderer().drawCircle(this._shape);
        }
    }

    public getClassName(): string {
        return "Communicator.Markup.Redline.RedlineOsnapPointItem";
    }
}

interface MarkupPriority {
    markup: MarkupBaseItem,
    index: number
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars

export class MarkupSelectionOperator extends Communicator.Operator.OperatorBase {
    protected _thisOperatorId: Communicator.OperatorId = Communicator.OperatorId.None;
    private _selectRectangle: RedlineRectangleSelection | null = null;
    private _osnapRectangle: RedlineOsnapPointItem | null = null;
    private _selectRectangleHandle: string | null = null;
    private _osnapRectangleHandle: string | null = null;
    private _moveGripPointMode = false;
    private _doCleanMoveGripPoint = false;
    private _pickFirstPoint = false;
    private _editTextMode = false;
    private _lastTimeClick = 0;
    private _pickOnSnapPointItem: MarkupBaseItem | null = null;
    private _textEdittingItem: MarkupBaseItem | null = null;
    private _firstPoint: Communicator.Point2 = Communicator.Point2.zero();
    private _secondPoint: Communicator.Point2 = Communicator.Point2.zero();
    protected _editText: { (): void; };
    private _moveGripPoint: { (): void; };
    protected _endEditTextMode: { (): void; };
    protected _updateUI: { (): void; };
    private markupData: MarkupData | null = null;
    public firstMouseDownTime = 0;
    public firstMousePos: Point | undefined = undefined;
    public listMarkupContainMouseEvent: MarkupPriority[] = [];
    private _deadZone = 0.2;
    private _isDbClick = false;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this._moveGripPoint = () => null;
        this._editText = () => null;
        this._endEditTextMode = () => null;
        this._updateUI = () => null;
    }
    setMarkupData(data: MarkupData): void {
        this.markupData = data;
    }
    setEditTextModeCallback(fnc: () => void): void {
        this._editText = fnc;
    }
    setEndEditTextModeCallback(fnc: () => void): void {
        this._endEditTextMode = fnc;
    }
    setUpdateUICallback(fnc: () => void): void {
        this._updateUI = fnc;
    }
    private setSelectionRectangleLocation(pt1: Communicator.Point2, pt2: Communicator.Point2): void {
        if (this._selectRectangle !== null) {
            this._selectRectangle.setPoint1(pt1);
            this._selectRectangle.setPoint2(pt2);
        }
    }
    onMouseUp(event: Communicator.Event.MouseInputEvent): void {
        if (this._pickFirstPoint === true) {
            this._pickFirstPoint = false;
            this._secondPoint = event.getPosition();
            this.handleOnClickMarkup(event);
            this.doSelectItem();
        }
    }
    onMouseDown(event: Communicator.Event.MouseInputEvent): void {
        if (this._doCleanMoveGripPoint) {
            this.doResetMoveGrip();
        }
        if (event.getButton() === Communicator.Button.Left) {
            if (this._pickFirstPoint === true) {
                return
            } else {
                if (this._pickOnSnapPointItem) {
                    if (this._osnapRectangle && this._osnapRectangleHandle) {
                        this._osnapRectangle.setVisible(false);
                        this._viewer.markupManager.unregisterMarkup(this._osnapRectangleHandle);
                        const activeView = this._viewer.markupManager.getActiveMarkupView();
                        if (activeView) {
                            activeView.removeMarkup(this._osnapRectangle);
                        }
                        this._osnapRectangle = null;
                        this._osnapRectangleHandle = null;
                    }
                    this._moveGripPointMode = true;
                    if (this._moveGripPoint != null) {
                        this._moveGripPoint();
                    }
                }
                else {
                    this._pickFirstPoint = true;
                    const markup = this._viewer.markupManager;
                    this._firstPoint = event.getPosition();
                    this._selectRectangle = new RedlineRectangleSelection(this._viewer);
                    this.setSelectionRectangleLocation(event.getPosition(), event.getPosition());
                    this._selectRectangleHandle = this._viewer.markupManager.registerMarkup(this._selectRectangle);
                    const activeView = markup.getActiveMarkupView();
                    if (activeView) {
                        activeView.addMarkupItem(this._selectRectangle);
                    }
                    markup.refreshMarkup();
                }
            }
        }
    }

    onMouseMove(event: Communicator.Event.MouseInputEvent): void {
        this._pickOnSnapPointItem = null;
        if (this._osnapRectangle) {
            this._osnapRectangle.setVisible(false);
            this._viewer.markupManager.refreshMarkup();
        }
        if (this._viewer) {
            if (this._pickFirstPoint === true) {
                const markup = this._viewer.markupManager;
                this.setSelectionRectangleLocation(this._firstPoint, event.getPosition());
                markup.refreshMarkup();
            }
            else {
                const items = this.getAllMarkupItem();
                if (items && items.length > 0) {
                    for (let i = 0; i < items.length; i++) {
                        const check = this.isMouseEventOverMarkup(event, items[i]);
                        if (check) {
                            this.listMarkupContainMouseEvent.push({ markup: items[i], index: i });
                        }
                    }
                    const selectedMarkup = this.listMarkupContainMouseEvent.pop();
                    items.forEach(v => {
                        v.setHovered(false);
                    })
                    if (selectedMarkup) {
                        selectedMarkup.markup.setHovered(true);
                    }
                    this.listMarkupContainMouseEvent = [];
                }
            }
        }
    }

    handleOnClickMarkup(event: Communicator.Event.MouseInputEvent): void {
        const currTime = Date.now();
        const interval = currTime - this.firstMouseDownTime;
        const mousePos: Point = { x: event.getPosition().x, y: event.getPosition().y };
        let eventDist = 1000;
        if (this.firstMousePos) {
            eventDist = MathHelper.dist(this.firstMousePos, mousePos);
        }
        this.firstMousePos = mousePos;
        if (interval < 400 && eventDist < 20) this._isDbClick = true;
        this.handleOnClick(event);
        this.firstMouseDownTime = currTime;
        this._isDbClick = false;
    }
    handleOnClick(event: Communicator.Event.MouseInputEvent): void {
        if (this._viewer) {
            const items = this.getAllMarkupItem();
            if (items && items.length > 0) {
                const listMarkupContain = items.filter((val, index) => {
                    if (event.getModifiers() !== Communicator.KeyModifiers.Control) val.setSelected(false);
                    const check = this.isMouseEventOverMarkup(event, val);
                    return check;
                })
                const selectedMarkup = listMarkupContain.pop();
                if (selectedMarkup) {
                    selectedMarkup.setSelected(true);
                    this.handleOnDbClick(event, selectedMarkup);
                    GlobalState.selectionEvent = false;
                } else {
                    GlobalState.selectionEvent = true;
                }
            }
        }
    }
    handleOnDbClick(event: Communicator.Event.MouseInputEvent, selectedMarkup: MarkupBaseItem): void {
        if (this._isDbClick) {
            return
        }
    }

    isMouseEventOverMarkup(event: Communicator.Event.MouseInputEvent, markup: MarkupBaseItem, isClick = true): boolean {
        const { x, y } = event.getPosition();
        return markup.hit(new Communicator.Point2(x, y));
    }

    doSelectItem(): void {
        let isValidSelect = false;
        const markup = this._viewer.markupManager;
        const activeView = markup.getActiveMarkupView();
        if (activeView && this._selectRectangle) {
            activeView.removeMarkup(this._selectRectangle);
        }
        if (this._selectRectangleHandle) {
            this._viewer.markupManager.unregisterMarkup(this._selectRectangleHandle);
        }
        if (this._selectRectangle) {
            isValidSelect = this._selectRectangle.isValid();
        }
        this._selectRectangle = null;
        this._selectRectangleHandle = null;

        if (isValidSelect === false) {
            return;
        }
        const min = new Communicator.Point2(Math.min(this._firstPoint.x, this._secondPoint.x), Math.min(this._firstPoint.y, this._secondPoint.y));
        const max = new Communicator.Point2(Math.max(this._firstPoint.x, this._secondPoint.x), Math.max(this._firstPoint.y, this._secondPoint.y));
        const items = this.getAllMarkupItem();
        if (items) {
            items.forEach(v => {
                const checkSelected = this.isSelectedByRectangle(v, min, max)
                v.setSelected(checkSelected);
            })
            markup.refreshMarkup();
            if (this._updateUI != null) {
                this._updateUI();
            }
        }
    }

    getAllMarkupItem(): MarkupBaseItem[] {
        const allitems: MarkupBaseItem[] = [];
        if (this.markupData) {
            return this.markupData.markupItems;
        }
        return allitems;
    }
    isSelectedByRectangle(
        item: MarkupBaseItem,
        ptMin: Communicator.Point2,
        ptMax: Communicator.Point2): boolean {

        const rectLines = MathHelper.get4LineOfRect(ptMin, ptMax);
        const selectionRect = MathHelper.getRectFrom2Points(ptMin, ptMax);
        const rectPoints = [ptMin, new Communicator.Point2(ptMax.x, ptMin.y),
            ptMax, new Communicator.Point2(ptMin.x, ptMax.y)];

        if (item instanceof MarkupLineItem ||
            item instanceof MarkupArrowItem ||
            item instanceof MarkupNoteItem) {
            if (!item._firstPoint || !item._secondPoint) return false;
            const point1 = MathHelper.worldPointToScreenPoint(this._viewer, item._firstPoint);
            const point2 = MathHelper.worldPointToScreenPoint(this._viewer, item._secondPoint);
            const checkPointOnRect = MathHelper.pointRectCollide(point1, selectionRect) || MathHelper.pointRectCollide(point2, selectionRect);
            const checkLineRectCollide = rectLines.some(line => MathHelper.lineLineCollide(point1, point2,
                new Communicator.Point2(line.startPoint.x, line.startPoint.y),
                new Communicator.Point2(line.endPoint.x, line.endPoint.y))
            );
            return checkPointOnRect || checkLineRectCollide;
        }
        else if (item instanceof MarkupRectangleItem ||
            item instanceof MarkupTextBoxItem ||
            item instanceof MarkupCloudItem) {
            if (!item._point1 || !item._point2) return false;
            const point1 = MathHelper.worldPointToScreenPoint(this._viewer, item._point1);
            const point2 = MathHelper.worldPointToScreenPoint(this._viewer, item._point2);
            const rect1: Rectangle = {
                x: Math.min(point1.x, point2.x),
                y: Math.min(point1.y, point2.y),
                width: Math.abs(point1.x - point2.x),
                height: Math.abs(point1.y - point2.y),
            }
            return MathHelper.is2RectCollide(rect1, selectionRect);
        }
        else if (item instanceof MarkupCircleItem || item instanceof MarkupEllipseItem) {
            if (!item._point1 || !item._point2) return false;
            const point1 = MathHelper.worldPointToScreenPoint(this._viewer, item._point1);
            const point2 = MathHelper.worldPointToScreenPoint(this._viewer, item._point2);
            const rX = Math.abs(point1.x - point2.x);
            const rY = Math.abs(point1.y - point2.y);
            const rect1: Rectangle = {
                x: Math.min(point1.x, point2.x),
                y: Math.min(point1.y, point2.y),
                width: rX,
                height: rY,
            }
            return MathHelper.is2RectCollide(rect1, selectionRect);
        }
        else if (item instanceof MarkupPolygonItem || item instanceof MarkupPolylineItem) {
            const point2Ds = item.getPoints().map(val => MathHelper.worldPointToScreenPoint(this._viewer, val));
            const listLines = MathHelper.getArrStraightLine(point2Ds, item instanceof MarkupPolygonItem);
            const lineLineCollide = rectLines.some(rectLine =>
                listLines.some(line => MathHelper.lineLineCollide(
                    new Communicator.Point2(rectLine.startPoint.x, rectLine.startPoint.y),
                    new Communicator.Point2(rectLine.endPoint.x, rectLine.endPoint.y),
                    new Communicator.Point2(line.startPoint.x, line.startPoint.y),
                    new Communicator.Point2(line.endPoint.x, line.endPoint.y))
                )
            )
            if (lineLineCollide) return lineLineCollide;
            const pointPolyCollide = point2Ds.some(v => MathHelper.polyPointCollide(rectPoints, v));
            if (pointPolyCollide) return pointPolyCollide;
            const pointPolyReverseCollide = rectPoints.some(v => MathHelper.polyPointCollide(point2Ds, v));
            return pointPolyReverseCollide;
        }
        else if (item instanceof MarkupSignatureItem) {
            const pos = item.getBoundingPos();
            const size = item.getBoundingSize();
            const rect1: Rectangle = {
                x: pos.x,
                y: pos.y,
                width: size.x,
                height: size.y,
            }
            return MathHelper.is2RectCollide(rect1, selectionRect);
        }
        else if (item instanceof MarkupCalloutItem) {
            const { arrowPoint2, textBoxSize } = item.toJson();
            if (!arrowPoint2 || !textBoxSize) return false;
            const point1 = MathHelper.worldPointToScreenPoint(this._viewer, item._firstPoint);
            const point2 = MathHelper.worldPointToScreenPoint(this._viewer, item._secondPoint);
            const checkPointOnRect = MathHelper.pointRectCollide(point1, selectionRect) || MathHelper.pointRectCollide(arrowPoint2, selectionRect);
            const checkLineRectCollide = rectLines.some(line => MathHelper.lineLineCollide(point1, arrowPoint2,
                new Communicator.Point2(line.startPoint.x, line.startPoint.y),
                new Communicator.Point2(line.endPoint.x, line.endPoint.y))
            );
            const rect1: Rectangle = {
                x: point2.x - textBoxSize.x / 2,
                y: point2.y - textBoxSize.y / 2,
                width: textBoxSize.x,
                height: textBoxSize.y,
            }
            return MathHelper.is2RectCollide(rect1, selectionRect) || checkPointOnRect || checkLineRectCollide;
        }

        return false;
    }

    clearSelection(): void {
        if (this._viewer) {
            if (this._osnapRectangle && this._osnapRectangleHandle) {
                this._osnapRectangle.setVisible(false);
                this._viewer.markupManager.unregisterMarkup(this._osnapRectangleHandle);
                const activeView = this._viewer.markupManager.getActiveMarkupView();
                if (activeView) {
                    activeView.removeMarkup(this._osnapRectangle);
                }
                this._osnapRectangle = null;
                this._osnapRectangleHandle = null;
            }
            this._viewer.markupManager.refreshMarkup();
        }
    }

    setThisOperatorId(id: Communicator.OperatorId): void {
        this._thisOperatorId = id;
    }

    remove(): void {
        if (this._selectRectangleHandle) {
            this._viewer.markupManager.unregisterMarkup(this._selectRectangleHandle);
        }
        if (this._osnapRectangleHandle) {
            this._viewer.markupManager.unregisterMarkup(this._osnapRectangleHandle);
        }
    }

    setMoveGripPointCallback(fnc: () => void): void {
        this._moveGripPoint = fnc;
    }

    getMoveGripPointItem(): MarkupBaseItem | null {
        return this._pickOnSnapPointItem;
    }

    getTextEditItem(): MarkupBaseItem | null {
        return this._textEdittingItem;
    }

    doResetMoveGrip(): void {
        this._pickFirstPoint = false;
        this._editTextMode = false;
        this._firstPoint = Communicator.Point2.zero();
        this._moveGripPointMode = false;
        this._pickOnSnapPointItem = null;
        this._pickFirstPoint = false;
        const markup = this._viewer.markupManager;
        const activeView = markup.getActiveMarkupView();
        if (activeView && this._selectRectangle) {
            activeView.removeMarkup(this._selectRectangle);
        }
        if (this._selectRectangleHandle) {
            this._viewer.markupManager.unregisterMarkup(this._selectRectangleHandle);
        }
        this._selectRectangle = null;
        this._selectRectangleHandle = null;
        this._doCleanMoveGripPoint = false;
    }

    setEditTextItem(item: MarkupBaseItem | null): void {
        this._textEdittingItem = item;
        this._editTextMode = true;
        if (item) {
            this.markupData?.markupItems.forEach(markup => {
                if (markup.uniqueId !== item.uniqueId)
                    markup.setSelected(false)
            })
        }
    }

    endEditTextItem(): void {
        if (this._textEdittingItem) {
            this._textEdittingItem.endedEditText();
            this._textEdittingItem = null;
            this._editTextMode = false;
            if (this._endEditTextMode != null) {
                this._endEditTextMode();
            }
        }
    }

    endEditNoteItem(): void {
        if (this._textEdittingItem) {
            this._textEdittingItem = null;
            this._editTextMode = false;
        }
    }
}
