import { KeyCode } from "common/define";
import KeyCommand from "./key-command";

export type MapKey = {
    key: string,
    command: CommandType,
    keyBinding: KeysGroup[],
    keyConvertString: string,
    des: string
}

// new keybinding
export type CommandMap = Partial<Record<CommandType, KeyCommand>>;
export type LastKey = { keyType: CommandType, keyGroup: KeysGroup, mode: ModeSetLastKey };
export enum ModeSetLastKey {
    override = 'override',
    add = 'add'
}
export type ParamSetKeyBinding = {
    /**
     * Key type is sting
     */
    keyType: CommandType,
    /**
     * Is partial of type CommandValue
     */
    value: Partial<CommandValue>
};
export type MapKeyType = [string, KeyCommand];
export type KeysGroup = {
    key?: KeyCode,
    modified?: KeyCode[]
}
export type Priority = 1 | 2 | 3 | 4 | 5
export type CommandValue = {
    /**
     * Is type string
     */
    cmdType: CommandType,
    /**
     * A function will be called when all conditions are vaild
     * 
     * Return "true" if you want execute an array of command with same value of field "when" and "priority"
     */
    cmdExc: (() => void | boolean) | (() => Promise<void | boolean>),
    /**
     * Key group => Is a object key and modified of KeyCode
     */
    keyFinal: KeysGroup | KeysGroup[],
    /**
     * boolean || function return boolean
     */
    when: boolean | (() => boolean),
    /**
     * As a number, the larger number takes precedence.
     * 
     * Recommended: app = 1, left, right = 2, navigator = 3, markup = 4
     */
    priority: Priority,
    description: string,
    preventAndStopPropagation?: boolean
}

export type CommandType =
    'none' |
    'app.escape' | 'app.toggle.leftpanel' | 'app.toggle.rightpanel' | 'app.toggle.keybinding.setting' | 'app.toggle.markup' |
    'cuttingPlane.escape' |
    'property.escape' |
    'left.escape' |
    'right.escape' |
    'navigator.escape' | 'navigator.zoom.popover' |
    'markup.escape' |
    'markup.select.escape' | 'markup.select.delete' | 'markup.select.all' |
    'markup.select.undo' | 'markup.select.redo' | 'markup.settings.close' |
    'navigator.home' |
    'navigator.pan' |
    'navigator.select' |
    'navigator.save' |
    'navigator.zoomWindow' |
    'navigator.deleteMarkup' |
    'navigator.zoomIn' |
    'navigator.zoomOut' |
    'navigator.nextPage' |
    'navigator.homePage' |
    'navigator.endPage' |
    'navigator.previousPage' |
    'navigator.undoMarkup' |
    'navigator.redoMarkup' |
    'navigator.escape.selectMarkup' |
    'navigator.zoomInMarkup' |
    'navigator.zoomOutMarkup' |
    "navigator.rotateZ.unClockWise" |
    "navigator.rotateZ.clockWise" |
    "navigator.panLeft" |
    "navigator.panRight" |
    'navigator.zoomFit' |
    'markup3d.pinmarker.escape' |
    'markup3d.pinmarker.delete'
    ;
