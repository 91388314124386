import { MarkupBaseOperator } from './markup.base.operator';
import { MarkupSignatureItem } from '../markup-items/markup.signature.item';
export class MarkupSignatureOperator extends MarkupBaseOperator {
    private _mouseDown = false;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
    }
    /** @hidden */
    createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        if (this._markupItem === null) {
            this._markupItem = new MarkupSignatureItem(this._viewer);
            this._markupItem.setLineFormat(
                this._lineColor,
                this._lineWeight,
                this._lineOpacity,
                this._fillColor,
                this._fillColorOption,
                this._lineStyle);
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            cameraPoint && (this._markupItem as MarkupSignatureItem).addPoint(cameraPoint);
            const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
            this._markupItem.setMarkupItemId(itemId);
        }
        else {
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            cameraPoint && (this._markupItem as MarkupSignatureItem).addPoint(cameraPoint);
        }
    }
    /** @hidden */
    updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (!cameraPoint) return;
            (this._markupItem as MarkupSignatureItem).addPoint(cameraPoint);
            this._viewer.markupManager.refreshMarkup();
        }
    }
    /** @hidden */
    onMouseDown(event: Communicator.Event.MouseInputEvent): void {
        if (event.getButton() === Communicator.Button.Left) {
            this.setDrawingState(true);
            if (this._moveGripPointMode) {
                if (this._markupItem) {
                    this._markupItem.setJiggingMode(false);
                    this._markupItem.setMoveGripPointMode(false);
                    this._viewer.markupManager.refreshMarkup();
                }
                if (this._operatorEndCallback != null) {
                    this._operatorEndCallback();
                }
            }
            else {
                this.createMarkupItem(event.getPosition());
                this._mouseDown = true;
            }
            event.setHandled(true);
        }
    }
    /** @hidden */
    onMouseMove(event: Communicator.Event.MouseInputEvent): void {
        if (this._moveGripPointMode) {
            return
        }
        else {
            if (this._mouseDown === true) {
                this.updateMarkupItem(event.getPosition());
                event.setHandled(true);
            }
        }
    }
    /** @hidden */
    onMouseUp(event: Communicator.Event.MouseInputEvent): void {
        if (this._moveGripPointMode === false) {
            if (event.getButton() === Communicator.Button.Left) {
                event.setHandled(true);
                this._mouseDown = false;
                this.finishCommand();
            }
        }
    }
    finishCommand(): void {
        this._startOperator = false;
        if (this._operatorEndCallback != null) {
            this._operatorEndCallback();
        }
        this._markupItem = null;
    }
}

