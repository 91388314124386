/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperatorType } from "common/type-viewer";

export enum ModeOperator {
    Normal = 0,
    Markup = 1,
    MarkupView = 2,
    Markup3D = 3,
}

interface IBaseOperator {
    _name: OperatorType;
    _id: number;
    _status: boolean;
    _viewer: Communicator.WebViewer;
    _mode: ModeOperator;
    Active(): void;
    InActive(): void;
    clearTemp(): void;
    UpdateTargetPoint(newTarget: unknown): void;
    showSubInformation(isShow: boolean): void;
    onKeyDownEvent(event: KeyboardEvent): void;
    onKeyUpEvent(event: KeyboardEvent): void;
    setMarkupFormatData(data: unknown): void;
    ResetDefautOperators(): void;
}

export default class BaseOperator implements IBaseOperator {
    _name: OperatorType = '';

    public get Name(): OperatorType {
        return this._name;
    }

    public set Name(value: OperatorType) {
        this._name = value;
    }

    _id = 0;

    public get Id(): number {
        return this._id;
    }

    public set Id(value: number) {
        this._id = value;
    }

    _status = false;

    public get Status(): boolean {
        return this._status;
    }

    public set Status(value: boolean) {
        this._status = value;
    }

    listKeyUse: string[] = []; // key|control|shift --- a|false|true

    _viewer: Communicator.WebViewer;

    public get Viewer(): Communicator.WebViewer {
        return this._viewer;
    }

    public set Viewer(value: Communicator.WebViewer) {
        this._viewer = value;
    }

    _mode: ModeOperator = ModeOperator.Normal;

    public get Mode(): ModeOperator {
        return this._mode;
    }

    public set Mode(value: ModeOperator) {
        this._mode = value;
    }

    firstOperator = Communicator.OperatorId.Navigate;

    secondOperator = Communicator.OperatorId.Select;

    static listOperatorDefault: number[] = [
        Communicator.OperatorId.Navigate,
        Communicator.OperatorId.Select,
        Communicator.OperatorId.Cutting,
        Communicator.OperatorId.Handle,
        Communicator.OperatorId.NavCube,
        Communicator.OperatorId.AxisTriad,
    ];

    constructor(viewer: Communicator.WebViewer) {
        this._viewer = viewer;
        this.Name = '';
        this.Id = -1;
        this.listKeyUse = [];
    }
    Active(): void { return }
    InActive(): void { return }
    clearTemp(): void { return }
    showSubInformation(isShow: boolean): void { return }
    setMarkupFormatData(data: unknown): void { return }

    public SetCursor(nameCursor: string): void {
        // fix bug #438: Should show pan icon
        this.Viewer.getViewElement().style.cursor = nameCursor;
    }

    public UpdateTargetPoint(newTarget: Communicator.Point3): void { 
        return
    }

    /**
     * set active list default operator
     */
    public ResetDefautOperators(): void {
        this.Viewer.operatorManager.clear();
        BaseOperator.listOperatorDefault.forEach((opeID) => {
            this.Viewer.operatorManager.push(opeID);
        });
    }

    public ClearTemp(): void { 
        return
    }


    /**
     * result:
     *    - true: allow the next processing
     *    - false: do not allow the next processing
     */
    public Escape(): boolean {
        return true;
    }

    public onKeyUpEvent(event: KeyboardEvent): void {
        return
    }

    public onKeyDownEvent(event: KeyboardEvent): void {
        return
    }

    /**
     * return ID of operator
     */
    GetIdOperator(): number {
        return -1;
    }
}
