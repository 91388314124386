import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RGBColor } from 'react-color';
import { MarkupTextFormat } from 'common/define';
import { defaultTextFormatSetting } from 'utils/utils-extend';

const { textFontSize, textColor, textFontFamily, textIsBold, textIsItalic, textIsUnderline } = defaultTextFormatSetting;

const defaultValue: MarkupTextFormat = {
    textFontSize: textFontSize,
    textColor: textColor,
    textFontFamily: textFontFamily,
    textIsBold: textIsBold,
    textIsItalic: textIsItalic,
    textIsUnderline: textIsUnderline,
};


export const markupTextFormatSlice = createSlice(
    {
        name: 'markupTextFormat',
        initialState: defaultValue,
        reducers: {
            setTextFontSize: (state, action: PayloadAction<number>) => {
                state.textFontSize = action.payload;
            },
            setTextColor: (state, action: PayloadAction<RGBColor>) => {
                state.textColor = action.payload;
            },
            setTextFontFamily: (state, action: PayloadAction<string>) => {
                state.textFontFamily = action.payload;
            },
            setTextIsBold: (state, action: PayloadAction<boolean>) => {
                state.textIsBold = action.payload;
            },
            setTextIsItalic: (state, action: PayloadAction<boolean>) => {
                state.textIsItalic = action.payload;
            },
            setTextIsUnderline: (state, action: PayloadAction<boolean>) => {
                state.textIsUnderline = action.payload;
            },
        },
    });

export const {
    setTextFontSize,
    setTextColor,
    setTextFontFamily,
    setTextIsBold,
    setTextIsItalic,
    setTextIsUnderline,
} = markupTextFormatSlice.actions;

export default markupTextFormatSlice.reducer;