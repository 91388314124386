import { StreamApi } from "api/stream.api";
import { FileInfo, FileInfoResponse, PayloadOriginFile } from "common/define";
import { GlobalState } from "common/global";
import { FileItemThumnail } from "common/type-state";
import { Observable } from "rxjs";
import Utils, { Lodash } from "utils/utils";

/** interface, types */
export interface FilesListState {
    allCaches: FileInfoResponse[];
    filesList: FileInfo[];
    linkFilesList: FileInfo[];
    filesOrigin: FileInfo[];
    loading: boolean;
    filesListThumnail: FileItemThumnail[];
    refreshFileList: string
}
interface PreviousMerge {
    viewId: string;
    baseFileId: string;
    viewIdChildren: string[];
}
export interface ResultFileListState {
    newFileList?: FileInfo[];
    newFileThumnail?: FileItemThumnail[];
    newFilesOrigin?: FileInfo[];
}

/** functions export */
export function mapFileInfo(filesInfo: FileInfoResponse[]): { listMerge: FileInfo[], listOrigin: FileInfo[] } {
    // sort files info based on extraConvertOutput
    const sortResult: FileInfoResponse[] = [];
    let listFileInfo = filesInfo;
    while (listFileInfo?.length > 0) { // order sheet
        const firstElement = listFileInfo[0];
        const listTemp = listFileInfo.filter(f => f.baseFileId === firstElement.baseFileId);
        listFileInfo = listFileInfo.filter(f => f.baseFileId !== firstElement.baseFileId);   
        const rootFile = listTemp.find(f => f.isRootModel !== 0);    
        if (rootFile && rootFile.extraConvertOutput) {
            sortResult.push(rootFile);
            const extra = Utils.parseJson(rootFile.extraConvertOutput);
            if (typeof extra === 'object' && 'Sheets' in extra && Array.isArray(extra.Sheets)) {
                for (let i = 0; i < extra.Sheets.length; i++) {
                    const file = listTemp.find(f => f.filename === extra.Sheets[i].id);
                    if (file) sortResult.push(file);
                }
            }
        } else sortResult.push(firstElement);
    }
    // const rootFile = filesInfo.find(f => f.isRootModel !== 0);    
    // if (rootFile && rootFile.extraConvertOutput) {
    //     sortResult.push(rootFile);
    //     const extra = Utils.parseJson(rootFile.extraConvertOutput);
    //     if (typeof extra === 'object' && 'Sheets' in extra && Array.isArray(extra.Sheets)) {
    //         for (let i = 0; i < extra.Sheets.length; i++) {
    //             const file = filesInfo.find(f => f.filename === extra.Sheets[i].id);
    //             if (file) sortResult.push(file);
    //         }
    //     }
    //     sortResult = [...sortResult, ...filesInfo.filter(f => sortResult.indexOf(f) < 0)];
    // } else sortResult = filesInfo;
    //
    const re = sortResult.map((item) => {
        const uniqueId = Communicator.UUID.create();
        const newId = `new-${uniqueId}`;
        const newItem: FileInfo = { ...item, viewId: newId };
        return newItem;
    });
    const listMerge = mergeFileByBaseFileId(re);
    return {
        listMerge,
        listOrigin: re
    }
}

export function deleteFileObser(viewId: ViewId, fileListState: FilesListState): Observable<ResultFileListState> {
    return new Observable<ResultFileListState>(obser => {
        const { filesList, filesListThumnail, filesOrigin } = fileListState;
        const cloneListThumnail = [...filesListThumnail];
        const cloneFilesList = Lodash.cloneDeep(filesList);
        const cloneFilesOrigin = Lodash.cloneDeep(filesOrigin);
        const result: ResultFileListState = {};
        result.newFileList = Lodash.remove(cloneFilesList, f => f.viewId !== viewId);
        result.newFileThumnail = Lodash.remove(cloneListThumnail, f => f.viewId !== viewId);
        const originFile = filesList.find(f => f.viewId === viewId);
        if (originFile) {
            result.newFilesOrigin = Lodash.remove(cloneFilesOrigin, f => f.baseFileId !== originFile.baseFileId);
        }
        obser.next(result);
        obser.complete();
    })
}

/**
 * Filter list add
 * @param list
 * @param preList
 * @returns array file need add
 */
export function filterListAdd(list: PayloadOriginFile[], preList: FileInfo[]): PayloadOriginFile[] | undefined {
    const baseFileIdListAdd = list.map(f => f.baseFileId);
    const baseFileIdPreList = preList.map(f => f.baseFileId);
    const diff = Lodash.difference(baseFileIdListAdd, baseFileIdPreList);
    if (diff.length > 0) {
        return list.filter(f => diff.includes(f.baseFileId))
    }
    return undefined
}

/**
 * Helper function add filelist
 * @param filesInfo Array fileInfo response api
 * @param fileListState State File List Current
 * @returns Observable result filelist
 */
export function addFilesList$(filesInfo: FileInfoResponse[], fileListState: FilesListState): Observable<ResultFileListState> {
    return new Observable<ResultFileListState>(subscriber => {
        const result: ResultFileListState = {};
        if (filesInfo.length > 0) {
            const { filesList, filesListThumnail, filesOrigin } = fileListState;

            filesInfo.forEach(f => {
                if (f.linkFiles) {
                    const listFile: FileInfo[] = [];
                    f.linkFiles.forEach(f => {
                        const uniqueId = Communicator.UUID.create();
                        const newId = `new-${uniqueId}`;
                        const newItem: FileInfo = { ...f, viewId: newId };
                        listFile.push(newItem);
                    })
                    f.listFile = listFile;
                }
            })
            const { listMerge, listOrigin } = mapFileInfo(filesInfo);     
            const listThumbnail = mapFileListThumnail(listMerge, listOrigin);
            const cloneFilesList = Lodash.cloneDeep(filesList);
            const cloneFileOrigin = Lodash.cloneDeep(filesOrigin);
            const cloneListThumnail = Lodash.cloneDeep(filesListThumnail);
            result.newFileList = [...cloneFilesList, ...listMerge];
            result.newFilesOrigin = [...cloneFileOrigin, ...listOrigin];
            result.newFileThumnail = [...cloneListThumnail, ...listThumbnail];
        }
        subscriber.next(result);
        subscriber.complete()
    })
}
/** functions scope */

/**
 * Get file root or file active default
 * @param baseFileId base file id of file need check
 * @param lists list file info
 * @returns fileInfo or undefined
 */
function getFileDefaultActiveMerge(baseFileId: string, lists: FileInfo[]): FileInfo | undefined {
    const arrFileMergeByBaseFileId = lists.filter(f => f.baseFileId === baseFileId && !!f.multiStream);
    if (arrFileMergeByBaseFileId.length > 0) {
        const fileRoot = arrFileMergeByBaseFileId.find(f => f.isRootModel !== 0);
        if (fileRoot) {
            if (fileRoot.extraConvertOutput) {
                const parseExtra = Utils.parseJson(fileRoot.extraConvertOutput);
                if (typeof parseExtra === 'object' && 'Sheets' in parseExtra && Array.isArray(parseExtra.Sheets)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const sheetActive = parseExtra.Sheets.find((f: any) => !!f.isActive);
                    if (sheetActive) {
                        return arrFileMergeByBaseFileId.find(f => f.filename === sheetActive.id)
                    }
                }
            }
            if (fileRoot.isRootModel === -1) {
                return arrFileMergeByBaseFileId.find(f => f.viewId !== fileRoot.viewId);
            }

            return fileRoot
        }
    }
}

/**
 * Transform list file check merge mode
 * @param lists list file input
 * @returns list file after check merge mode
 */
function mergeFileByBaseFileId(lists: FileInfo[]): FileInfo[] {
    const map: string[] = [];
    const listFileParentMerge = lists.reduce((pre, file) => {
        const lengthFileSameBaseFileId = lists.filter(item => item.baseFileId === file.baseFileId).length;
        // check in list has item same baseFileId or item has field multistream
        if (lengthFileSameBaseFileId > 1 || file.multiStream) {
            map.push(file.viewId);
            const inPre = pre.find((i) => i ? i.baseFileId === file.baseFileId : false);
            if (!inPre) {
                // by default, show 3D
                const fileRoot = lists.find(f => f.isRootModel === 1 && f.baseFileId === file.baseFileId) || getFileDefaultActiveMerge(file.baseFileId, lists);
                if (fileRoot) {
                    const fileRootId = fileRoot.viewId;
                    const getItem: PreviousMerge = {
                        viewId: fileRootId,
                        baseFileId: fileRoot.baseFileId,
                        viewIdChildren: [fileRootId],
                    };
                    pre.push(getItem);
                    GlobalState.mapMergeChildParent.set(fileRootId, fileRootId);
                    GlobalState.mapMergeParentChild.set(fileRootId, [fileRootId]);
                    // if file root is not current file
                    if (fileRoot.viewId !== file.viewId && file.isRootModel !== -1) {
                        GlobalState.mapMergeChildParent.set(file.viewId, fileRootId);
                        GlobalState.mapMergeParentChild.set(fileRootId, [fileRootId, file.viewId]);
                        getItem.viewIdChildren = [fileRootId, file.viewId]
                    }
                }
            } else {
                if (inPre.viewId !== file.viewId && file.isRootModel !== -1) {
                    inPre.viewIdChildren.push(file.viewId);
                    GlobalState.mapMergeChildParent.set(file.viewId, inPre.viewId);
                    GlobalState.mapMergeParentChild.set(
                        inPre.viewId,
                        inPre.viewIdChildren
                    );
                }
            }
        }
        return pre;
    }, [] as PreviousMerge[]);
    const filterFileNotMerge = lists.filter(f => !map.includes(f.viewId));
    const filterFileMerge = lists.filter(f => listFileParentMerge.map(p => p.viewId).includes(f.viewId));
    return [...filterFileMerge, ...filterFileNotMerge];
}

/**
 * Map fileinfo -> file item thumbnail
 * @param filesInfo Array fileinfo
 * @param listOrigin
 * @returns Array FileItemThumnail
 */
function mapFileListThumnail(filesInfo: FileInfo[], listOrigin: FileInfo[]): FileItemThumnail[] {
    return filesInfo.map((f) => {
        let fileName = f.filename;
        let srcThumnail = StreamApi.getUrlImage(f);
        if (f.multiStream) {
            if (f.isRootModel === 0 && f.originalFile) {
                fileName = f.originalFile;
                const rootFile = listOrigin.find(file => file.baseFileId === f.baseFileId && (file.isRootModel === 1 || file.isRootModel === -1));
                if (rootFile) {
                    srcThumnail = StreamApi.getUrlImage(rootFile)
                }
            }
        }
        const re: FileItemThumnail = {
            viewId: f.viewId,
            fileName,
            srcThumnail
        };
        return re;
    });
}
