/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GlobalState } from 'common/global';
import { LineStyle } from 'common/type-markup';
import CursorSprite from 'container/viewer3d/custom-operator/cursor-sprite.operator';
import { RGBColor } from 'react-color';
import { defaultLineFormatSetting, defaultTextFormatSetting } from 'utils/utils-extend';
import { MarkupBaseItem } from '../markup-items/markup.base.item';
export class MarkupBaseOperator extends Communicator.Operator.OperatorBase {
    protected _markupItem: MarkupBaseItem | null = null;
    protected _markupOperatorId: Communicator.OperatorId = Communicator.OperatorId.None;
    protected _startOperator = false;
    protected _addMarkupItemToCurrentView = true;
    protected _moveGripPointMode = false;
    protected _lineColor: RGBColor;
    protected _lineWeight: number;
    protected _lineOpacity: number;
    protected _fillColor: RGBColor;
    protected _fillColorOption: boolean;
    protected _textFontSize: number;
    protected _textColor: RGBColor;
    protected _textFontFamily: string;
    protected _textIsBold = false;
    protected _textIsItalic = false;
    protected _textIsUnderline = false;
    protected _startLineShapeType: number;
    protected _endLineShapeType: number;
    protected _lineStyle: number;
    protected _operatorEndCallback: { (): void; };
    private _drawing = false;
    protected cursorSpriteOpe: CursorSprite | null = null;
    private _cursorSprite = false;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.cursorSpriteOpe = new CursorSprite(viewer);
        this._operatorEndCallback = () => null;
        const { lineColor, lineWeight, lineOpacity, fillColor, fillColorOption, startLineShapeType, endLineShapeType, lineStyle } = defaultLineFormatSetting;
        const { textFontSize, textColor, textFontFamily, textIsBold, textIsItalic, textIsUnderline } = defaultTextFormatSetting;

        this._lineColor = lineColor;
        this._lineWeight = lineWeight;
        this._lineOpacity = lineOpacity;
        this._fillColor = fillColor;
        this._fillColorOption = fillColorOption;

        this._textFontSize = textFontSize;
        this._textColor = textColor;
        this._textFontFamily = textFontFamily;
        this._textIsBold = textIsBold;
        this._textIsItalic = textIsItalic;
        this._textIsUnderline = textIsUnderline;
        this._startLineShapeType = startLineShapeType;
        this._endLineShapeType = endLineShapeType;
        this._lineStyle = lineStyle;
    }
    /** @hidden */
    createMarkupItem(position: Communicator.Point2): void {
        position as Communicator.Unreferenced;
    }
    /** @hidden */
    updateMarkupItem(position: Communicator.Point2): void {
        position as Communicator.Unreferenced;
    }

    /** @hidden */
    onMouseDown(event: Communicator.Event.MouseInputEvent): void {
        this.setDrawingState(true);
        super.onMouseDown(event);
        if (this.isActive()) {
            if (event.getButton() === Communicator.Button.Left || this._primaryTouchId !== null) {
                event.setHandled(true);
                if (this._moveGripPointMode) {
                    if (this._markupItem) {
                        this._markupItem.setJiggingMode(false);
                        this._markupItem.setMoveGripPointMode(false);
                        this._viewer.markupManager.refreshMarkup();
                    }
                    if (this._operatorEndCallback != null) {
                        this._operatorEndCallback();
                    }
                }
                else {
                    if (this._startOperator === false) {
                        this._startOperator = true;
                        this.createMarkupItem(event.getPosition());
                        if (this._addMarkupItemToCurrentView) {
                            const activeView = this._viewer.markupManager.getActiveMarkupView();
                            if (activeView && this._markupItem) {
                                this._markupItem.setJiggingMode(true);
                                activeView.addMarkupItem(this._markupItem);
                                this._viewer.markupManager.refreshMarkup();
                            }
                        }
                    }
                    else {
                        if (this._markupItem) {
                            this._markupItem.setJiggingMode(false);
                            this._markupItem.setMoveGripPointMode(false);
                            this.handleMarkupSizeTooSmall();
                        }
                        this._startOperator = false;
                        if (this._operatorEndCallback != null) {
                            this._operatorEndCallback();
                        }
                    }
                }
            }
        }
    }

    handleMarkupSizeTooSmall() {
        if (!this._markupItem) return;
        if ((this._markupItem._point1 &&
            this._markupItem._point2 &&
            Communicator.Point3.distance(this._markupItem._point1, this._markupItem._point2) === 0) ||
            (this._markupItem._firstPoint &&
                this._markupItem._secondPoint &&
                Communicator.Point3.distance(this._markupItem._firstPoint, this._markupItem._secondPoint) === 0)) {
            const id = this._markupItem.getMarkupItemId();
            if (!id) return;
            this._viewer.markupManager.unregisterMarkup(id);
            this._viewer.markupManager.removeMarkupElement(id);
            this._markupItem = null;
        }
    }

    /** @hidden */
    onMouseMove(event: Communicator.Event.MouseInputEvent): void {
        super.onMouseMove(event);
        this.updateCursorSprite(event);
        if (this._moveGripPointMode) {
            this.updateMarkupItem(event.getPosition());
            event.setHandled(true);
        }
        else {
            if (this._startOperator) {
                this.updateMarkupItem(event.getPosition());
                event.setHandled(true);
            }
        }
    }

    async updateCursorSprite(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this._cursorSprite && this.cursorSpriteOpe) {
            await this.cursorSpriteOpe.updateCursorSpriteImpl(event.getPosition(), true);
        }
    }
    removeCursorSprite(): void {
        if (this.cursorSpriteOpe) this.cursorSpriteOpe.removeCursorSprite()
    }
    setOperatorEndCallback(fnc: () => void): void {
        this._operatorEndCallback = fnc;
    }

    setMarkupOperatorId(id: Communicator.OperatorId): void {
        this._markupOperatorId = id;
    }
    getOperatorId(): Communicator.OperatorId {
        return this._markupOperatorId;
    }
    setLineFormat(
        lineColor: RGBColor,
        lineWeight: number,
        lineOpacity: number,
        fillColor: RGBColor,
        fillColorOption: boolean,
        lineStyle: number,
        startLineShapeType?: number,
        endLineShapeType?: number): void {

        this._lineColor = lineColor;
        this._lineWeight = lineWeight;
        this._lineOpacity = lineOpacity;
        this._fillColor = fillColor;
        this._fillColorOption = fillColorOption;
        this._lineStyle = lineStyle;
        startLineShapeType && (this._startLineShapeType = startLineShapeType);
        endLineShapeType && (this._endLineShapeType = endLineShapeType)
    }
    setTextFormat(
        textFontSize: number,
        textColor: RGBColor,
        textFontFamily: string,
        _textIsBold: boolean,
        _textIsItalic: boolean,
        _textIsUnderline: boolean): void {
        this._textFontSize = textFontSize;
        this._textColor = textColor;
        this._textFontFamily = textFontFamily;
        this._textIsBold = _textIsBold;
        this._textIsItalic = _textIsItalic;
        this._textIsUnderline = _textIsUnderline;
    }
    getMarkupItem(): MarkupBaseItem | null {
        return this._markupItem;
    }
    setMoveGripPointItem(item: MarkupBaseItem): void {
        this._markupItem = item;
        this._markupItem.setMoveGripPointMode(true);
        this._moveGripPointMode = true;
    }

    cancelOperator(): void {
        if (this._viewer && this._markupItem) {
            if (this._moveGripPointMode) {
                if (this._markupItem) {
                    this._markupItem.setJiggingMode(false);
                    this._markupItem.setMoveGripPointMode(false);
                    this._viewer.markupManager.refreshMarkup();
                }
                if (this._operatorEndCallback != null) {
                    this._operatorEndCallback();
                }
            }
            else {
                const id = this._markupItem.getMarkupItemId();
                if (id) {
                    this._viewer.markupManager.unregisterMarkup(id);
                    this._viewer.markupManager.refreshMarkup();
                }
            }
        }
        this.removeCursorSprite();
        GlobalState.currentMarkupOperator$.next(null);
    }
    setNonViewMode(): void {
        this._addMarkupItemToCurrentView = false;
    }

    getDrawingState(): boolean {
        return this._drawing;
    }
    setDrawingState(drawing: boolean): void {
        this._drawing = drawing;
    }
    // GET SET markup line format
    setLineColor(lineColor: RGBColor): void {
        this._lineColor = lineColor;
    }
    getLineColor(): RGBColor {
        return this._lineColor;
    }
    setLineWeight(lineWeight: number): void {
        this._lineWeight = lineWeight;
    }
    getLineWeight(): number {
        return this._lineWeight;
    }
    setLineOpacity(lineOpacity: number): void {
        this._lineOpacity = lineOpacity;
    }
    getLineOpacity(): number {
        return this._lineOpacity;
    }
    setFillColor(fillColor: RGBColor): void {
        this._fillColor = fillColor;
    }
    getFillColor(): RGBColor {
        return this._fillColor;
    }
    setFillColorOption(fillColorOption: boolean): void {
        this._fillColorOption = fillColorOption;
    }
    getFillColorOption(): boolean {
        return this._fillColorOption;
    }
    setLineStyle(lineStyle: LineStyle): void {
        this._lineStyle = lineStyle;
    }
    getLineStyle(): LineStyle {
        return this._lineStyle;
    }

    // GET SET text format
    setTextFontSize(textFontSize: number): void {
        this._textFontSize = textFontSize;
    }
    getTextFontSize(): number {
        return this._textFontSize;
    }
    setTextColor(textColor: RGBColor): void {
        this._textColor = textColor;
    }
    getTextColor(): RGBColor {
        return this._textColor;
    }
    setTextFontFamily(textFontFamily: string): void {
        this._textFontFamily = textFontFamily;
    }
    getTextFontFamily(): string {
        return this._textFontFamily;
    }
    setTextIsBold(textIsBold: boolean): void {
        this._textIsBold = textIsBold;
    }
    getTextIsBold(): boolean {
        return this._textIsBold;
    }
    setTextIsItalic(textIsItalic: boolean): void {
        this._textIsItalic = textIsItalic;
    }
    getTextIsItalic(): boolean {
        return this._textIsItalic;
    }
    setTextIsUnderline(textIsUnderline: boolean): void {
        this._textIsUnderline = textIsUnderline;
    }
    getTextIsUnderline(): boolean {
        return this._textIsUnderline;
    }

    // GET SET start cap end cap
    setStartLineShape(type: number): void {
        this._startLineShapeType = type;
    }
    getStartLineShape(): number {
        return this._startLineShapeType;
    }
    setEndLineShape(type: number): void {
        this._endLineShapeType = type;
    }
    getEndLineShape(): number {
        return this._endLineShapeType;
    }

    public set cursorSprite(canUpdate: boolean) {
        this._cursorSprite = canUpdate;
    }
    public get cursorSprite(): boolean {
        return this._cursorSprite
    }
}