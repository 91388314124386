/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import BaseRedline from "./base.redline";

export default class RectangleRedline extends BaseRedline {
    firstPoint: Communicator.Point2 | undefined;

    seccondPoint: Communicator.Point2 | undefined;

    private _size = new Communicator.Point2(1, 1);

    private _position = new Communicator.Point2(0, 0);

    private _rectangleShape = new Communicator.Markup.Shape.Rectangle();

    private _isShow = false;
    private _direction: 'LeftWard' | 'RightWard' = 'LeftWard';
    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this._rectangleShape.setStrokeWidth(1);
        this._rectangleShape.setStrokeColor(Communicator.Color.red());
        this._rectangleShape.setFillColor(Communicator.Color.green());
        this._rectangleShape.setFillOpacity(0);
        this._rectangleShape.setSize(this._size);
        this._rectangleShape.setPosition(this._position);
    }
    setFirstPoint(point2: Communicator.Point2) {
        this.firstPoint = point2.copy();
        this.seccondPoint = point2.copy();
        this.updateColor();
        this._position.x = Math.min(this.firstPoint.x, this.seccondPoint.x);
        this._position.y = Math.min(this.firstPoint.y, this.seccondPoint.y);
        this._size.x = Math.abs(this.firstPoint.x - this.seccondPoint.x);
        this._size.y = Math.abs(this.firstPoint.y - this.seccondPoint.y);
        this._rectangleShape.setPosition(this._position);
        this._rectangleShape.setSize(this._size);
    }

    setSecondPoint(point2: Communicator.Point2) {
        this.seccondPoint = point2.copy();
        this.updateColor();
        if (this.firstPoint) {
            this._position.x = Math.min(this.firstPoint.x, this.seccondPoint.x);
            this._position.y = Math.min(this.firstPoint.y, this.seccondPoint.y);
            this._size.x = Math.abs(this.firstPoint.x - this.seccondPoint.x);
            this._size.y = Math.abs(this.firstPoint.y - this.seccondPoint.y);
            this._rectangleShape.setPosition(this._position);
            this._rectangleShape.setSize(this._size);
        }
    }
    setVisible(isShow: boolean) {
        this._isShow = isShow;
    }

    draw() {
        if (this._isShow) {
            const renderer = this._viewer.markupManager.getRenderer();
            renderer.drawRectangle(this._rectangleShape);
        }
    }
    /** private */
    private updateColor() {
        if (this.firstPoint && this.seccondPoint) {
            if (this.firstPoint.x < this.seccondPoint.x) this._rectangleShape.setStrokeColor(Communicator.Color.red());
            else this._rectangleShape.setStrokeColor(Communicator.Color.blue());
        }
    }
}