import MathHelper from "container/pdf-viewer/helper/math.helper";
import { MarkupBaseItem } from "../markup-items/markup.base.item";
import { MarkupAction } from "../markup.action";


export class NotepinTooltip extends MarkupBaseItem {

    private _tooltipElement: HTMLDivElement;
    private _tooltipId: string | undefined;

    private markupAction: MarkupAction;
    private _mouseEvent: Communicator.Event.MouseInputEvent;
    public _viewer: Communicator.WebViewer;
    public constructor(
        viewer: Communicator.WebViewer,
        event: Communicator.Event.MouseInputEvent,
        markupAction: MarkupAction) {
        super(viewer);
        this._viewer = viewer;
        this.markupAction = markupAction;
        this._mouseEvent = event;

        this._tooltipElement = document.createElement('div');
        this._tooltipElement.style.position = 'absolute';
        this._tooltipElement.style.height = '50px';
        this._tooltipElement.style.width = '150px';
        this._tooltipElement.style.textOverflow = 'ellipsis';
        this._tooltipElement.style.overflow = 'hidden';
        this._tooltipElement.style.whiteSpace = 'nowrap';
        this._tooltipElement.style.borderRadius = '3px';
        this._tooltipElement.style.opacity = '0.8';
        this._tooltipElement.style.backgroundColor = '#262626';
        this._tooltipElement.style.color = '#FAFAFA';
        this._tooltipElement.style.fontWeight = '400';
        this._tooltipElement.style.fontSize = '14px';
        this._tooltipElement.style.padding = '3px 6px';
        this._tooltipElement.style.zIndex = '2';
        this._tooltipElement.style.cursor = 'pointer';
        this._tooltipId = undefined;
    }
    drawTooltip(): string | undefined {
        if (this.markupAction.markupData) {
            this.markupAction.markupData.markupNotepins.forEach(pin => {
                const pinJson = pin.toJson();
                const pinPos3D = pinJson.selectionPosition;
                const pinPos2D = MathHelper.worldPointToScreenPoint(this._viewer, pinPos3D);
                if (pinPos2D) {
                    const { x, y } = this._mouseEvent.getPosition();
                    const mousePos = new Communicator.Point2(x, y);
                    const distance = Communicator.Point2.distance(pinPos2D, mousePos);
                    if (distance < 30) {

                        this._tooltipElement.style.left = `${x - 75}px`;
                        this._tooltipElement.style.top = `${y - 50}px`;
                        this._tooltipElement.innerHTML = `
                        <div style="width: inherit">
                            <div style="width: inherit; text-overflow: ellipsis; overflow: hidden; white-space: nowwrap">${pinJson.type}</div>
                            <div style="width: inherit; text-overflow: ellipsis; overflow: hidden; white-space: nowwrap">${pinJson.uniqueId}</div>
                        </div>
                        `;
                        this._tooltipId = this._viewer.markupManager.addMarkupElement(this._tooltipElement);
                    }
                }
            })
        }
        return this._tooltipId;
    }

}