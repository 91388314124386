import { createSelector } from "@reduxjs/toolkit";
import { selectSelfMarkup } from "./self.selector";

export const getMarkupState = createSelector(
    selectSelfMarkup,
    (state) => state
)
export const getMarkupModeStatus = createSelector(
    selectSelfMarkup,
    (state) => state.markupMode
)
export const getMarkupViewSelected = createSelector(
    selectSelfMarkup,
    (state) => state.markupViewSelected
)
export const getMarkupListMarkupViews = createSelector(
    selectSelfMarkup,
    (state) => state.listMarkupViews
)
export const getMarkupUndoRedoTrigger = createSelector(
    selectSelfMarkup,
    (state) => state.triggerUndoRedoUpdate
)
export const getMarkupUnsaved = createSelector(
    selectSelfMarkup,
    (state) => state.unsavedEntities
)
export const getMarkupListSelected = createSelector(
    selectSelfMarkup,
    (state) => state.listMarkupsSelected
)

