/* eslint-disable indent */
import { LineStyle, MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { BaseElementCanvas } from "./markup.base-canvas.element";

export class ArcElementCanvas extends BaseElementCanvas {
    private isCircle = false;
    protected startArc: number = (Math.PI * 3) / 2;
    protected endArc = 0;
    constructor(isCircle?: boolean) {
        super();
        this.offSet = 2;
        this.lineCap = "round";
        this.lineJoin = "round";
        if (isCircle) this.setIsCircle(isCircle);
        this.baseCanvas = document.createElement('canvas');
        this.baseCanvas.style.position = 'absolute';
        this.baseCanvas.style.zIndex = '1';
        this.baseCanvas.style.width = 'inherit';
        this.baseCanvas.style.height = 'inherit';
    }
    getArcCanvas(): HTMLElement {
        switch (this._lineStyle) {
            case LineStyle.cloud:
                this.drawCloudStyle();
                break;
            case LineStyle.zigzag:
                this.drawZizZacStyle();
                break;
            default:
                this.draw();
        }
        return this.baseCanvas;
    }

    draw(): void {
        if (!this.firstPoint) return;
        const width = this.size.x + (this.strokeWidth + this.offSet) * 2;
        const height = this.size.y + (this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;

        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return;
        ctx.lineJoin = this.lineJoin;
        ctx.lineCap = this.lineCap;
        MathHelper.applyLineStyle(ctx, this._lineStyle);
        ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

        ctx.beginPath();
        ctx.lineWidth = this.strokeWidth;
        ctx.ellipse(width / 2, height / 2, this.size.x / 2, this.size.y / 2, 0, this.startArc, this.endArc);
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
            if (color) {
                ctx.fillStyle = color;
                ctx.fill();
            }
        }
        if (this.strokeColor) {
            const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
            if (color) {
                ctx.strokeStyle = color;
            }
        }
        ctx.stroke();
        ctx.closePath();
    }

    drawZizZacStyle(): void {
        if (!this.firstPoint) return;
        const width = this.size.x + (this.strokeWidth + this.offSet) * 2;
        const height = this.size.y + (this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;

        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return;
        ctx.lineJoin = this.lineJoin;
        ctx.lineCap = this.lineCap;
        ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

        ctx.beginPath();

        this.zicZacArc(new Communicator.Point2(width / 2,
            height / 2), ctx);

        ctx.lineWidth = this.strokeWidth;
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
            if (color) {
                ctx.fillStyle = color;
                ctx.fill();
            }
        }
        if (this.strokeColor) {
            const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
            if (color) {
                ctx.strokeStyle = color;
            }
        }
        ctx.stroke();
        ctx.closePath();
    }

    zicZacArc(center: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        const strA = this.startArc < 0 ? this.startArc + 2 * Math.PI : this.startArc;
        const endA = this.endArc < 0 ? this.endArc + 2 * Math.PI : this.endArc;
        const deltaArg = endA < strA ? endA + Math.PI * 2 - strA : endA - strA;
        const radius = (this.size.x + this.size.y) / 4;
        const length = radius * deltaArg;
        const a = length / (this.offSet / 2);
        const step = Math.ceil(a);
        const args: number[] = [];

        const size = this.calculateStyleSize(center);

        for (let i = 0; i <= step; i++) {
            args.push(this.startArc + (deltaArg / step) * i);
        }
        for (let i = 0; i < args.length - 1; i++) {
            const strArg = args[i];
            const endArg = args[i + 1];

            const strPnt = new Communicator.Point2(center.x + (size.x / 2) * Math.cos(strArg),
                center.y + (size.y / 2) * Math.sin(strArg));
            const endPnt = new Communicator.Point2(center.x + (size.x / 2) * Math.cos(endArg),
                center.y + (size.y / 2) * Math.sin(endArg));

            if (i === 0) {
                ctx.moveTo(strPnt.x, strPnt.y);
            }

            const vec = endPnt.copy().subtract(strPnt.copy());
            const tmpVec = vec.copy().scale(1 / 4);
            const upVec = new Communicator.Point2(vec.y, -vec.x);
            upVec.scale(1 / 4);
            const downVec = new Communicator.Point2(-upVec.x, -upVec.y);

            let upPnt = strPnt.copy().add(tmpVec.copy());
            upPnt = upPnt.copy().add(upVec.copy());
            ctx.lineTo(upPnt.x, upPnt.y);

            let downPnt = strPnt.copy().add(tmpVec.copy().scale(3));
            downPnt = downPnt.copy().add(downVec.copy());
            ctx.lineTo(downPnt.x, downPnt.y);

            ctx.lineTo(endPnt.x, endPnt.y);
        }
    }

    drawCloudStyle(): void {
        if (!this.firstPoint) return;
        const width = this.size.x + (this.strokeWidth + this.offSet) * 2;
        const height = this.size.y + (this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;

        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return;
        ctx.lineJoin = this.lineJoin;
        ctx.lineCap = this.lineCap;
        ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

        ctx.beginPath();

        this.cloudArc(new Communicator.Point2(width / 2,
            height / 2), ctx);

        ctx.lineWidth = this.strokeWidth;
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
            if (color) {
                ctx.fillStyle = color;
                ctx.fill();
            }
        }
        if (this.strokeColor) {
            const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
            if (color) {
                ctx.strokeStyle = color;
            }
        }
        ctx.stroke();
        ctx.closePath();
    }

    cloudArc(center: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        const strA = this.startArc < 0 ? this.startArc + 2 * Math.PI : this.startArc;
        const endA = this.endArc < 0 ? this.endArc + 2 * Math.PI : this.endArc;
        const deltaArg = endA < strA ? endA + Math.PI * 2 - strA : endA - strA;
        const radius = (this.size.x + this.size.y) / 4;
        const length = radius * deltaArg;
        const a = length / (this.offSet / 2);
        const step = Math.ceil(a);
        const args: number[] = [];

        const size = this.calculateStyleSize(center);

        for (let i = 0; i <= step; i++) {
            args.push(this.startArc + (deltaArg / step) * i);
        }
        for (let i = 0; i < args.length - 1; i++) {
            const strArg = args[i];
            const endArg = args[i + 1];

            const strPnt = new Communicator.Point2(center.x + (size.x / 2) * Math.cos(strArg),
                center.y + (size.y / 2) * Math.sin(strArg));
            const endPnt = new Communicator.Point2(center.x + (size.x / 2) * Math.cos(endArg),
                center.y + (size.y / 2) * Math.sin(endArg));

            const vec = strPnt.copy().subtract(endPnt.copy());
            const b = Math.atan2(vec.y, vec.x);
            ctx.arc((strPnt.x + endPnt.x) / 2, (strPnt.y + endPnt.y) / 2, vec.length() / 2, b, b + Math.PI);
        }
    }

    calculateStyleSize(center: Communicator.Point2): Communicator.Point2 {
        const size = this.size.copy();

        const radius = (this.size.x + this.size.y) / 4;
        const length = radius * Math.PI * 2;
        const a = length / (this.offSet / 2);
        const step = Math.ceil(a);
        const spc = (Math.PI * 2) / step;

        let strArg = -(spc / 2);
        let endArg = spc / 2;

        let strPnt = new Communicator.Point2(center.x + (this.size.x / 2) * Math.cos(strArg),
            center.y + (this.size.y / 2) * Math.sin(strArg));
        let endPnt = new Communicator.Point2(center.x + (this.size.x / 2) * Math.cos(endArg),
            center.y + (this.size.y / 2) * Math.sin(endArg));
        let vec = strPnt.copy().subtract(endPnt.copy());

        if (this._lineStyle === LineStyle.cloud) {
            size.x -= vec.length();
        } else if (this._lineStyle === LineStyle.zigzag) {
            size.x -= vec.length() / 2;
        }

        strArg = Math.PI / 2 - spc / 2;
        endArg = Math.PI / 2 + spc / 2;

        strPnt = new Communicator.Point2(center.x + (this.size.x / 2) * Math.cos(strArg),
            center.y + (this.size.y / 2) * Math.sin(strArg));
        endPnt = new Communicator.Point2(center.x + (this.size.x / 2) * Math.cos(endArg),
            center.y + (this.size.y / 2) * Math.sin(endArg));
        vec = strPnt.copy().subtract(endPnt.copy());

        if (this._lineStyle === LineStyle.cloud) {
            size.y -= vec.length();
        } else if (this._lineStyle === LineStyle.zigzag) {
            size.y -= vec.length() / 2;
        }

        return size;
    }
    setStartArc(value: number): void {
        this.startArc = value;
    }

    setEndArc(value: number): void {
        this.endArc = value;
    }
    setIsCircle(isCircle: boolean): void {
        this.isCircle = isCircle;
    }
}
