import { MarkupBaseOperator } from './markup.base.operator';
import { MarkupPolygonItem } from '../markup-items/markup.polygon.item';

export class MarkupPolygonOperator extends MarkupBaseOperator {
    private _polygonEdge = 5;
    protected _startOperator = false;
    private _preTime = 0;
    private _prePos: Communicator.Point2 | null = null;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.cursorSprite = true;
    }
    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        this._markupItem = new MarkupPolygonItem(this._viewer);
        this._markupItem.setLineFormat(
            this._lineColor,
            this._lineWeight,
            this._lineOpacity,
            this._fillColor,
            this._fillColorOption,
            this._lineStyle);
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
        const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
        if (!cameraPoint) return;
        (this._markupItem as MarkupPolygonItem).addPoint(cameraPoint);
        (this._markupItem as MarkupPolygonItem).addPoint(cameraPoint);
        (this._markupItem as MarkupPolygonItem).setClose(false);
        if (!this._addMarkupItemToCurrentView) return;
        const activeView = this._viewer.markupManager.getActiveMarkupView();
        if (activeView && this._markupItem) {
            this._markupItem.setJiggingMode(true);
            activeView.addMarkupItem(this._markupItem);
            this._viewer.markupManager.refreshMarkup();
        }
    }

    public updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupPolygonItem).addPoint(cameraPoint);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }
    public updateMove(position: Communicator.Point2): void {
        if (this._moveGripPointMode) {
            this.updateMarkupItem(position);
        }
        else {
            if (this._markupItem) {
                const view = this._viewer.view;
                const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
                if (cameraPoint !== null) {
                    (this._markupItem as MarkupPolygonItem).updateLastPoint(cameraPoint);
                    this._viewer.markupManager.refreshMarkup();
                }
            }
        }
    }

    public onMouseDown(event: Communicator.Event.MouseInputEvent): void {
        if (event.getButton() === Communicator.Button.Left || this._primaryTouchId !== null) {
            this.setDrawingState(true);
            if (this._moveGripPointMode) {
                if (this._operatorEndCallback != null) {
                    this._operatorEndCallback();
                }
            }
            else {
                if (this._startOperator === false) {
                    this._startOperator = true;
                    this.createMarkupItem(event.getPosition());
                }
                else {
                    const currTime = Date.now();
                    const interval = currTime - this._preTime;
                    let distance;
                    const currPos = event.getPosition();
                    if (this._prePos !== null) {
                        distance = Communicator.Point2.distance(currPos, this._prePos);
                    }
                    if (interval < 400 && distance !== undefined && distance < 10) {
                        this.finishCommand();
                    } else {
                        this.updateMarkupItem(event.getPosition());
                    }
                    this._preTime = currTime;
                    this._prePos = currPos;
                }
            }

            event.setHandled(true);
        }
    }

    public onMouseMove(event: Communicator.Event.MouseInputEvent): void {
        this.updateCursorSprite(event);
        if (this._moveGripPointMode === true) {
            this.updateMove(event.getPosition());
        }
        else {
            if (this._startOperator) {
                this.updateMove(event.getPosition());
                event.setHandled(true);
            }
        }
    }

    finishCommand(): void {
        if (this._moveGripPointMode === false) {
            if (this._markupItem) {
                this._startOperator = false;
                if (this._operatorEndCallback != null) {
                    (this._markupItem as MarkupPolygonItem).popPoint();
                    (this._markupItem as MarkupPolygonItem).setClose(true);
                    this._operatorEndCallback();
                }
            }
        }
    }

}

