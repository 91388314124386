/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Redirect, Route } from "react-router-dom";
import useCheckLoggedIn from "hooks/useCheckLoggedIn";

type PropsPrivateRouter = Props & {com: React.FunctionComponent<any> | React.ComponentClass<any, any>};
const PrivateRoute = ({com: Component, ...rest }: PropsPrivateRouter): JSX.Element => {
    const isLoggedin = useCheckLoggedIn();
    return (
        <Route
            {...rest}
            render={(props) => isLoggedin ? (<Component {...props} />) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
            )}
        />
    );
}

export default PrivateRoute;