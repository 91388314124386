export enum selectionFlag {
    SelectByCode = 1,
    SelectByLink,
    SelectByTree,
    SelectByIsolate,
    SelectByProperty
}
export default class CSelectionManager extends Communicator.SelectionManager {
    private selectionResult: NodeId[] = []
    private selectionFlags: number[] = []
    constructor(
        callbackManager: Communicator.Internal.CallbackManager,
        engine: Communicator.Internal.ScEngine,
        view: Communicator.View,
        model: Communicator.Model,
        modelStructure: Communicator.Internal.Tree.ModelStructure) {
        super(callbackManager, engine, view, model, modelStructure)
    }

    addFlag(selectionFlag: selectionFlag): void {
        if (!this.selectionFlags.includes(selectionFlag))
            this.selectionFlags.push(selectionFlag);
    }

    removeFlag(selectionFlag: selectionFlag): void {
        if (this.selectionFlags.includes(selectionFlag)) {
            this.selectionFlags = this.selectionFlags.filter(flag => flag !== selectionFlag);
        }
    }

    getFlag(): number[] {
        return this.selectionFlags;
    }
    addNodeSelectWithoutTrigger(arr: NodeId[]): void {
        this.selectionResult = arr
    }
    getResultsExtends(): NodeId[] {
        if (this.selectionResult.length > 0) {
            return this.selectionResult
        }
        const selectionItems = this.getResults();
        return selectionItems.map((item) => item.getNodeId())
    }
}