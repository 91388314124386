import { MarkupGroupItem } from 'common/define';
import { MarkupEntity } from 'common/type-markup';
import { RGBColor } from 'react-color';
import { BehaviorSubject } from 'rxjs';
import { defaultLineFormatSetting, defaultTextFormatSetting } from 'utils/utils-extend';
import { MarkupPinMarkerItem } from './markup-3d/markup.pinmarker.item';
import { MarkupBaseItem } from './markup-items/markup.base.item';
export enum UndoRedoAction {
    Add = 'Add',
    Delete = 'Delete',
    Edit = 'Edit',
}
export interface DataEditMarkup {
    lineColor: RGBColor,
    lineWeight: number,
    lineOpacity: number,
    fillColor: RGBColor,
    fillColorOption: boolean,
    textFontSize: number,
    textColor: RGBColor,
    textFontFamily: string,
    textIsBold: boolean,
    textIsItalic: boolean,
    textIsUnderline: boolean,
    startLineShapeType: number,
    endLineShapeType: number,
    lineStyle: number
}
export interface UndoRedoActionInfor {
    action: UndoRedoAction;
    item: MarkupBaseItem[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    backUp?: any;
}

export class MarkupData {
    public markupItems: MarkupBaseItem[] = [];
    public markupUnsaved: MarkupEntity[] = [];
    public markupGroups: MarkupGroupItem[] = [];
    public markupNotepins: MarkupBaseItem[] = [];
    public markupPinMarker: MarkupPinMarkerItem[] = [];
    public lineColor: RGBColor;
    public lineWeight: number;
    public lineOpacity: number;
    public fillColor: RGBColor;
    public fillColorOption = false;
    public textFontSize: number;
    public textColor: RGBColor;
    public textFontFamily: string;
    public textIsBold = false;
    public textIsItalic = false;
    public textIsUnderline = false;
    public startLineShapeType = 0;
    public endLineShapeType = 0;
    public lineStyle = 0;
    private markupItems$: BehaviorSubject<MarkupBaseItem[]>;
    private markupNotepins$: BehaviorSubject<MarkupBaseItem[]>;
    private markupPinMarker$: BehaviorSubject<MarkupBaseItem[]>;
    private markupGroups$: BehaviorSubject<MarkupGroupItem[]>;
    private markupUnsaved$: BehaviorSubject<MarkupEntity[]>;
    private currentNotepinActive$: BehaviorSubject<MarkupBaseItem | undefined>;
    private currentPinMarkerActive$: BehaviorSubject<MarkupBaseItem | undefined>;
    public constructor(
        markupItems$: BehaviorSubject<MarkupBaseItem[]>,
        markupGroups$: BehaviorSubject<MarkupGroupItem[]>,
        markupUnsaved$: BehaviorSubject<MarkupEntity[]>,
        markupNotepins$: BehaviorSubject<MarkupBaseItem[]>,
        currentActiveNotpin$: BehaviorSubject<MarkupBaseItem | undefined>,
        markupPinMarker$: BehaviorSubject<MarkupBaseItem[]>,
        currentActivePinMarker$: BehaviorSubject<MarkupBaseItem | undefined>,
    ) {

        this.markupItems$ = markupItems$;
        this.markupGroups$ = markupGroups$;
        this.markupUnsaved$ = markupUnsaved$;
        this.markupNotepins$ = markupNotepins$;
        this.currentNotepinActive$ = currentActiveNotpin$;
        this.markupPinMarker$ = markupPinMarker$;
        this.currentPinMarkerActive$ = currentActivePinMarker$;


        const { lineColor, lineWeight, lineOpacity, fillColor, fillColorOption, startLineShapeType, endLineShapeType, lineStyle } = defaultLineFormatSetting;
        const { textFontSize, textColor, textFontFamily, textIsBold, textIsItalic, textIsUnderline } = defaultTextFormatSetting;
        this.lineColor = lineColor;
        this.lineWeight = lineWeight;
        this.lineOpacity = lineOpacity;
        this.fillColor = fillColor;
        this.fillColorOption = fillColorOption;
        this.textFontSize = textFontSize;
        this.textColor = textColor;
        this.textFontFamily = textFontFamily;
        this.textIsBold = textIsBold;
        this.textIsItalic = textIsItalic;
        this.textIsUnderline = textIsUnderline;
        this.startLineShapeType = startLineShapeType;
        this.endLineShapeType = endLineShapeType;
        this.lineStyle = lineStyle;
    }

    public updateMarkupItems(): void {
        this.markupItems$.next([...this.markupItems]);
    }

    public updateMarkupGroup(): void {
        this.markupGroups$.next([...this.markupGroups]);
    }
    public setMarkupItems(newMarkupItem: MarkupBaseItem[]): void {
        this.markupItems$.next(newMarkupItem);
    }
    public setMarkupGroup(newMarkupGroup: MarkupGroupItem[]): void {
        this.markupGroups$.next(newMarkupGroup);
    }
    public updateMarkupUnsaved(): void {
        this.markupUnsaved$.next([...this.markupUnsaved]);
    }
    public resetMarkupUnsaved(): void {
        this.markupUnsaved$.next([]);
    }

    //note pin
    public updateMarkupNotepins(): void {
        this.markupNotepins$.next([...this.markupNotepins]);
    }
    public updateCurrentActiveNotpin(notePin: MarkupBaseItem | undefined): void {
        this.currentNotepinActive$.next(notePin);
    }

    //end note pin
    getMarkupItems(): MarkupBaseItem[] {
        return this.markupItems;
    }

    //pin marker
    public updateMarkupPinMarker(): void {
        this.markupPinMarker$.next([...this.markupPinMarker]);
    }
    public updateCurrentActivePinMarker(pinMarker: MarkupBaseItem | undefined): void {
        this.currentPinMarkerActive$.next(pinMarker);
    }

    // end pin marker
}