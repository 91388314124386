import { AnyAction } from '@reduxjs/toolkit';
import { RootEpic } from 'common/type-state';
import { Subject } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

export * as BookmarkHelper from './bookmark.helper';
export * as FileListHelper from './filesList.helper';
export * as MultiViewerHelper from './multiViewer.helper';
export * as TreeHelper from './tree.helper';
export * as Viewer3DHelper from './viewer3D.helper';
export * as SheetHelper from './sheet.helper';
export * as PropertiesHelper from './properties.helper';
export * as SettingHelper from './setting.helper';
export * as CombineHelper from './combine.helper';

/** handle all action$ */
export const allAction$ = new Subject<AnyAction>();
export const handleAllAction$: RootEpic = (action$) => action$.pipe(
    tap(allAction$),
    concatMap(_ => [])
)