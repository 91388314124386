/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DataNode, EventDataNode } from "antd/lib/tree";
import { FlatTreeNode, NodeVisibility, RawFlatTreeNode, SearchOption, TreeNodeType } from "common/define";
import CModel from "container/viewer3d/extends/CModel";
import _ from "lodash";
import { Key } from "react";

const UNDEFINED_STRING = ['undefine', 'undefined', ''];
const ABSOLUTE_TREE_ROOT = ['root', '0'];
export type ScrollAlign = 'top' | 'bottom' | 'auto';
interface ScrollToProp {
    key: Key,
    align?: ScrollAlign,
    offset?: number
}
export interface CheckInfo {
    event: 'check';
    node: EventDataNode;
    checked: boolean;
    nativeEvent: MouseEvent;
    checkedNodes: DataNode[];
    checkedNodesPositions?: {
        node: DataNode;
        pos: string;
    }[];
    halfCheckedKeys?: Key[];
}
export default class ModelTreeHelper {
    static generateHashMapTree(treeData: RawFlatTreeNode[], rootId: string, fileName: string, modelFileId: ModelFileId) {
        const t0 = performance.now();
        const hashmapTree = new Map(treeData.map(item => {
            return [item.Id, {
                ...item,
                key: item.Id,
                ModelFileId: modelFileId,
                CategoryFamily: [item.CategoryName || '', item.FamilyName || ''].filter(x => x).join('-')
            }]
        }));
        ABSOLUTE_TREE_ROOT.forEach(id => {
            const rootNode = hashmapTree.get(id);
            if (rootNode) {
                hashmapTree.set(id, {
                    ...rootNode,
                    Name: fileName,
                    Id: rootId,
                    key: rootId,
                    Type: TreeNodeType.Root
                });
            }
        });
        // add parent for new combine logic
        const nodeRoot = hashmapTree.get(rootId);
        if (nodeRoot) {
            nodeRoot.ModelFileId = modelFileId;
            nodeRoot?.Childs?.forEach(id => {
                const node = hashmapTree.get(id);
                node && hashmapTree.set(id, {
                    ...node,
                    Parent: nodeRoot.Id
                });
            })
        }
        const t1 = performance.now();
        console.log(`generateHashMapTree tooks ${t1-t0}ms: `);
        return { treeMapData: hashmapTree }
    }

    static generateTreeMaps(treeData: RawFlatTreeNode[], rootId: string, fileName: string, modelFileId: ModelFileId): {
        treeMapData: Map<string, FlatTreeNode>,
        // treeMapPersistentId: Map<string, FlatTreeNode>,
    } {
        const resultFlatMap = new Map<string, FlatTreeNode>();
        // const resultPersistentMap = new Map<string, FlatTreeNode>();
        treeData.forEach(data => {
            const checkRoot = ABSOLUTE_TREE_ROOT.includes(data.Id);
            const name = { Name: checkRoot ? fileName : data.Name };
            const dataID = checkRoot ? rootId : data.Id;
            const dataType = checkRoot ? TreeNodeType.Root : data.Type;
            // const rootIdData = checkRoot && rootId !== null ? { rootId: rootId } : {};
            resultFlatMap.set(
                dataID,
                {
                    ...data,
                    ...name,
                    ...{
                        key: dataID,
                        Id: dataID,
                        Type: dataType,
                    },
                    ModelFileId: modelFileId,
                    CategoryFamily: data.CategoryName && data.FamilyName ?
                        `${data.CategoryName}-${data.FamilyName}`
                        : ''
                    // ...rootIdData
                }
            );
            // if (!data?.PersistentId) return; // linking 2d
            // resultPersistentMap.set(
            //     data.PersistentId,
            //     { ...data, ...{ key: data.Id } }
            // )
        });
        // add parent for new combine logic
        const nodeRoot = resultFlatMap.get(rootId);
        if (nodeRoot) {
            nodeRoot.ModelFileId = modelFileId;
            nodeRoot?.Childs?.forEach(id => {
                const node = resultFlatMap.get(id);
                if (!node) return;
                const newNode = { ...node, ...{ Parent: nodeRoot.Id } };
                resultFlatMap.set(id, newNode);
            })
        }
        //
        return {
            treeMapData: resultFlatMap,
            // treeMapPersistentId: resultPersistentMap,
        };
    }
    static getAllParentsId(id: string, treeMap: Map<string, FlatTreeNode>, withRoot = true): string[] {
        let parent = treeMap.get(id);
        const parentIds: string[] = [];
        while (parent) {
            parentIds.push(parent.Id);
            if (!parent.Parent || (!withRoot && Number(parent.Parent) < 0)) break;
            parent = treeMap.get(parent.Parent);
        }
        return Array.from(new Set(parentIds));
    }
    static updateTreeData(list: any[], key: number, treeMap: Map<string, FlatTreeNode>): any {
        return list.map((node) => {
            if (parseInt(node?.key) === key) {
                // return { ...node};
            }
            if (node?.Childs?.length) {
                const childs = node.Childs.map((id: string) => {
                    const object = treeMap.get(id);
                    if (!object) console.warn(`Flat tree doesnt have node ${id}`)
                    return object;
                }).filter((v: any) => !!v);
                return {
                    ...node,
                    children: this.updateTreeData(childs, key, treeMap)
                }
            } else {
                if (!Object.keys(node).length) {
                    console.log(node)
                }
                return {
                    ...node,
                    isLeaf: true,
                }
            }
            // return node;
        });
    }
    static getExpandedKeys(highlightNodes: number[], treeMap: Map<string, FlatTreeNode>): string[] {
        const expandedKeys = highlightNodes.map(String).map(nodeId => ModelTreeHelper.getAllParentsId(nodeId, treeMap)).flat();
        return Array.from(new Set(expandedKeys));
    }

    static parseTitle(item: FlatTreeNode, viewer: Communicator.WebViewer | null): string {
        if (item?.Name === undefined) return ''
        if (_.some(UNDEFINED_STRING, (v, i) => item.Name.toLowerCase() === v)) return (viewer && viewer.model.getNodeName(+item.Id)) ?? '{no name}';
        return item.Name;
    }

    static handleScrollTo(treeRefCurrent: any, key: Key, align: ScrollAlign = 'top'): void {
        const scrollTo: ScrollToProp = {
            key: String(key),
            align: align,
            offset: 0,
        }
        treeRefCurrent && treeRefCurrent.scrollTo(scrollTo);
    }

    static checkUncheckChildren(startNodeId: string, treeMap: Map<string, FlatTreeNode>, mapResult: Map<string, NodeVisibility>, visible: boolean) {
        const checkStatus = visible ?  NodeVisibility.Hidden : NodeVisibility.Visible;
        mapResult.set(startNodeId, checkStatus);
        const treeNode = treeMap.get(startNodeId);
        if (!treeNode || !treeNode.Childs || treeNode.Childs.length === 0) return;
        treeNode.Childs.forEach(nodeId => this.checkUncheckChildren(nodeId, treeMap, mapResult, visible));
    }

    static getAllVisibilityChild(startNodeId: string, treeMap: Map<string, FlatTreeNode>, mapResult: Map<string, NodeVisibility>, visible: boolean): Map<string, NodeVisibility> {
        // check/unchek children (includes check itself)
        this.checkUncheckChildren(startNodeId, treeMap, mapResult, visible);
        
        //  traverse up from target check
        let nodeStatus = visible ?  NodeVisibility.Hidden : NodeVisibility.Visible;
        let treeNode = treeMap.get(startNodeId.toString());
        while (treeNode && treeNode.Parent) {
            //  find parent and sibling checkboxes (quick'n'dirty)
            const parent = treeMap.get(treeNode.Parent);
            const siblings = parent ? parent.Childs || [] : [];

            siblings.forEach(id => mapResult.get(id) === undefined && mapResult.set(id, NodeVisibility.Visible));

            // eslint-disable-next-line
            const checkStatus = siblings.map(id => mapResult.get(id) === nodeStatus );
            const every  = checkStatus.every(Boolean);
            const some = checkStatus.some(Boolean);

            //  check parent if all siblings are checked
            //  set indeterminate if not all and not none are checked
            if (parent && every) mapResult.set(parent.Id, nodeStatus);
            if (parent && !every && every !== some) { 
                mapResult.set(parent.Id, NodeVisibility.Intermediate);
                nodeStatus = NodeVisibility.Intermediate;
            }

            //  prepare for nex loop
            treeNode = treeNode.Id !== parent?.Id ? parent : undefined;
        }

        const allParentIds = this.getAllParentsId(startNodeId, treeMap);
        const allChildIds = this.getAllChilds(startNodeId, treeMap);
        const cloneArr = Array.from(mapResult.keys());
        const difference = _.difference(cloneArr, [...allChildIds, ...allParentIds]);
        difference.forEach(key => mapResult.delete(key));
        
        return mapResult;
    }

    static loopGetAllVisibilityChild(startNodeId: string, treeMap: Map<string, FlatTreeNode>, mapResult: Map<string, NodeVisibility>) {
        // arrResult.push(startNodeId);
        mapResult.set(startNodeId, NodeVisibility.Hidden)
        const treeNode = treeMap.get(String(startNodeId));
        if (!treeNode || !treeNode?.Childs?.length) return;
        treeNode.Childs.forEach(nodeId =>
            this.loopGetAllVisibilityChild(nodeId, treeMap, mapResult)
        );
    }
    static loopGetAllVisibilityParent(startNodeId: string, treeMap: Map<string, FlatTreeNode>, mapResult: Map<string, NodeVisibility>) {
        const treeNode = treeMap.get(String(startNodeId));
        if (!treeNode) return;
        if (treeNode?.Parent) {
            const parentNode = treeMap.get(treeNode.Parent);
            if (!parentNode?.Childs?.length) return;
            if (_.every(parentNode.Childs, (v, i, c) => mapResult.get(v) !== NodeVisibility.Hidden)) {
                mapResult.set(treeNode.Parent, NodeVisibility.Hidden)
                this.loopGetAllVisibilityParent(parentNode.Id, treeMap, mapResult)
            } else if (_.some(parentNode.Childs, (v, i, c) => mapResult.get(v) !== NodeVisibility.Visible)) {
                mapResult.set(treeNode.Parent, NodeVisibility.Intermediate)
                this.loopGetAllVisibilityParent(parentNode.Id, treeMap, mapResult)
            }
        }
    }

    static getAllRealNodes(nodeId: string, treeMap: Map<string, FlatTreeNode>, isDeepFind = true): number[] {
        const arrResult: number[] = [];
        this.loopGetAllRealNodes(nodeId, treeMap, arrResult, isDeepFind);
        return arrResult;
    }
    static loopGetAllRealNodes(nodeId: string, treeMap: Map<string, FlatTreeNode>, arrResult: number[], isDeepFind: boolean) {
        // if (nodeId > -100) arrResult.push(nodeId);
        const treeNode = treeMap.get(nodeId);
        if (!treeNode) return;
        if (!isNaN(Number(treeNode.Id)) && this.checkIsRealNode(treeNode)) {
            arrResult.push(Number(treeNode.Id));
            if (!isDeepFind) return;
        }
        const childrenId = treeMap.get(`${nodeId}`)?.Childs;
        if (!childrenId?.length) return arrResult;
        // const [realNodeIds, virtualNodeIds] = Utils.arrayPartition(
        //     childrenId,
        //     (id) => {
        //         const node = treeMap.get(id);
        //         return node?.Type === TreeNodeType.Normal || node?.Type === TreeNodeType.Component;

        //     });
        // realNodeIds.forEach(v => arrResult.push(parseInt(v)));
        // realNodeIds.forEach(id => this.loopGetAllRealNodes(id, treeMap, arrResult, isDeepFind));
        // if (!virtualNodeIds.length) return arrResult;
        // virtualNodeIds.forEach(id => this.loopGetAllRealNodes(id, treeMap, arrResult, isDeepFind));
        childrenId.forEach(id => this.loopGetAllRealNodes(id, treeMap, arrResult, isDeepFind));
    }

    static getAllChilds(nodeId: string, treeMap: Map<string, FlatTreeNode>): string[] {
        const arrResult: string[] = [];
        this.loopGetAllChilds(nodeId, treeMap, arrResult);
        return arrResult;
    }
    static loopGetAllChilds(nodeId: string, treeMap: Map<string, FlatTreeNode>, arrResult: string[]) {
        arrResult.push(nodeId);
        const childrenId = treeMap.get(nodeId)?.Childs;
        if (!childrenId?.length) return arrResult;
        childrenId.forEach(v => this.loopGetAllChilds(v, treeMap, arrResult));
    }
    static setNodesVisibility(nodeIds: number[], viewer: Communicator.WebViewer | null, visible: boolean) {
        if (!viewer) return;
        viewer.model.setNodesVisibility(nodeIds, visible);
    }

    static getTreeNodesByTreeNodeId(treeMapData: Map<string, FlatTreeNode>, selectionArray: number[]): FlatTreeNode[] {
        const resultTreeNodes: FlatTreeNode[] = [];
        selectionArray.forEach(nodeId => {
            const treeNode = treeMapData.get(String(nodeId));
            if (treeNode) resultTreeNodes.push(treeNode)
        });
        return resultTreeNodes;
    }

    static getNodeTreeByPersistentId(treeMapPersistentId: Map<string, FlatTreeNode>, persistentId: string): FlatTreeNode | undefined {
        const treeNode = treeMapPersistentId.get(persistentId);
        return treeNode;
    }

    static getRegex(value: string, reg: SearchOption) {
        switch (reg) {
            case SearchOption.Case:
                return new RegExp(value, 'g');
            case SearchOption.Whole:
                return new RegExp(`\\b${value}\\b`, 'gi');
            case SearchOption.Both:
                return new RegExp(`\\b${value}\\b`, 'g');
            default:
                return new RegExp(value, 'gi');
        }
    }
    static handleToggleShowHide(nodeId: string, treeMap: Map<string, FlatTreeNode>, viewer: Communicator.WebViewer, visible: boolean) {
        if (Number(nodeId) >= 0) {
            ModelTreeHelper.setNodesVisibility([Number(nodeId)], viewer, !visible);
        } else {
            const allRealNodes = ModelTreeHelper.getAllRealNodes(nodeId, treeMap);
            ModelTreeHelper.setNodesVisibility(allRealNodes, viewer, !visible);
        }

        const cmodel = (viewer.model as CModel);
        // const hiddenArr = Array.from(cmodel.getExtraNodeVisibility().keys());
        const hiddenMap = cmodel.getExtraNodeVisibility();
        const allChildVisibility = ModelTreeHelper.getAllVisibilityChild(nodeId, treeMap, hiddenMap, visible);
        cmodel.setExtraNodeVisibility(allChildVisibility, visible);

        // handle eye icon intermediate
        // if (visible) return;
        // const allParentIds = this.getAllParentsId(nodeId, treeMap);
        // const hiddenMapClone = cmodel.getExtraNodeVisibility();
        // allParentIds.forEach(id => {
        //     const node = treeMap.get(String(id));
        //     if (!node?.Childs?.length) return;
        //     if (_.every(node.Childs, (v, i) => !hiddenMapClone.has(v))) {
        //         cmodel.deleteKey(id);
        //         hiddenMapClone.delete(id)
        //     } else if (_.some(node.Childs, (v, i) => hiddenMapClone.get(v) !== NodeVisibility.Hidden)) {
        //         cmodel.setKey(id, NodeVisibility.Intermediate);
        //         hiddenMapClone.set(id, NodeVisibility.Intermediate);
        //     }
        // })
    }

    static keyWithOffset(treeNode: FlatTreeNode, offset: number): string {
        const { key } = treeNode;
        return !treeNode.Parent || isNaN(Number(key)) ? key : `${Number(key) + offset}`;
    }
    static valueWithOffset(treeNode: FlatTreeNode, offset: number, nodeRootId: number | null): FlatTreeNode {
        const offsetID = !treeNode.Parent || isNaN(Number(treeNode.Id)) ? treeNode.Id : `${Number(treeNode.Id) + offset}`;
        const childMap = treeNode?.Childs?.map(id => `${Number(id) + offset}`);
        const childs = childMap ? { Childs: childMap } : {};
        const parent = treeNode.Parent && treeNode.Parent !== String(nodeRootId) ? { Parent: !isNaN(Number(treeNode.Parent)) ? `${Number(treeNode.Parent) + offset}` : treeNode.Parent } : {};
        const node: FlatTreeNode = {
            ...treeNode,
            Id: offsetID,
            key: offsetID,
            ...childs,
            ...parent,
        }
        return node;
    }

    static getNodeOnTree(nodes: number[], treeMap: Map<string, FlatTreeNode>, viewer: Communicator.WebViewer) {
        const hoopsNodes = nodes.map(String);
        const result = hoopsNodes.map(nodeId => this.getClosestParent(String(nodeId), treeMap, viewer)).filter(v => v !== undefined) as string[];
        return Array.from(new Set(result));
    }

    static getClosestParent(nodeId: string, treeMap: Map<string, FlatTreeNode>, viewer: Communicator.WebViewer): string | undefined {
        const parentNode = treeMap.get(nodeId);
        if (parentNode) {
            if (isNaN(Number(parentNode.Id))) return undefined;
            return Number(parentNode.Id) > 0 ? parentNode.Id : undefined;
        } else {
            const hoopsParentId = viewer.model.getNodeParent(Number(nodeId));
            if (hoopsParentId !== null) return this.getClosestParent(String(hoopsParentId), treeMap, viewer);
            return undefined;
        }
    }

    static getNodeFromType(startNodeId: string, treeMap: Map<string, FlatTreeNode>, traverseLevel: number): FlatTreeNode | undefined {
        let tempNode = treeMap.get(startNodeId);
        let count = 0;
        while (tempNode && count < traverseLevel) {
            if (!tempNode.Parent) {
                tempNode = undefined;
                break;
            }
            count++;
            tempNode = treeMap.get(tempNode.Parent);
        }
        return tempNode;
    }

    static getLevelFromTree(pos: string) {
        const level = (pos.match(/-/g) || []).length - 1;
        return level;
    }

    static checkIsRealNode(nodeData: FlatTreeNode): boolean {
        const id = Number(nodeData.Id);
        const idWithoutOffset = id % Math.pow(2, 32);
        return idWithoutOffset > -100;
    }

    // Flat data transfer tree Shape data
    static buildShapeTree(treeMap: Map<string, FlatTreeNode>) {
        if (!treeMap || treeMap.size === 0) return [];
        (window as any).treeMap = treeMap;
        const rootNode = treeMap.get('absoluteRoot');
        if (!rootNode?.Childs?.length) return [];
        const subNode: FlatTreeNode[] = [];
        rootNode.Childs.forEach((id: string) => {
            const node = treeMap.get(id);
            node && subNode.push(node);
        });
        return this.getShapTree(subNode, treeMap);
    }

    static getShapTree(list: FlatTreeNode[], treeMap: Map<string, FlatTreeNode>): any {
        return list.map(item => {
            if (!item.Childs?.length) {
                return {
                    ...item,
                    isLeaf: true
                }
            }
            const childNodes: FlatTreeNode[] = [];
            item.Childs.forEach((id: string) => {
                const node = treeMap.get(id);
                node && childNodes.push(node);
            });
            return {
                ...item,
                children: this.getShapTree(childNodes, treeMap)
            }
        });
    }

    static getFlatData(data: any[]) {
        const flatData: FlatTreeNode[] = [];
        data.forEach(item => {
            const {children, ...node} = item
            flatData.push(node);
            children && flatData.push(...this.getFlatData(children));
        });
        return flatData;
    }
}