/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import { LineCapStyle, MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { BaseElementCanvas } from "./markup.base-canvas.element";
export class LineElementCanvas extends BaseElementCanvas {
    private _firstPoint: Communicator.Point2 = Communicator.Point2.zero();
    private _secondPoint: Communicator.Point2 = Communicator.Point2.zero();
    private sizeExt = new Communicator.Point2(110, 110);
    private _canvasContext: CanvasRenderingContext2D | null = null;
    private _startLineCapStyle = LineCapStyle.None;
    private _endLineCapStyle = LineCapStyle.None;
    private _lineCapLengthScale = 4;
    private listPointsStart: Communicator.Point2[] = [];
    private listPointsEnd: Communicator.Point2[] = [];
    constructor() {
        super();
        this.offSet = 2;
        this.baseCanvas = document.createElement('canvas');
        this.baseCanvas.style.position = 'absolute';
        this.baseCanvas.style.zIndex = '1';
        this._canvasContext = this.baseCanvas.getContext('2d');
    }

    createLine(): void {
        if (!this._canvasContext) return;
        this.updateSize();
        this.updatePosition();
        this._canvasContext.lineWidth = this.strokeWidth;
        if (this.strokeColor) this._canvasContext.strokeStyle = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
        this._canvasContext.lineJoin = this.lineJoin;
        this._canvasContext.lineCap = this.lineCap;
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
            if (color) {
                this._canvasContext.fillStyle = color;
                this._canvasContext.fill();
            }
        }
        MathHelper.applyLineStyle(this._canvasContext,
            this._lineStyle);
        const size = new Communicator.Point2(this.baseCanvas.width, this.baseCanvas.height);
        this._canvasContext.beginPath();
        this._canvasContext.clearRect(0, 0,
            size.x,
            size.y);
        const tempPoint = new Communicator.Point2(this.sizeExt.x / 2, this.sizeExt.y / 2);
        const maxWidth = size.x;
        const maxHeight = size.y;
        let tempStartPoint = tempPoint;
        let tempEndPoint = new Communicator.Point2(maxWidth - tempPoint.x, maxHeight - tempPoint.y);
        if (this._firstPoint && this._secondPoint) {
            if (this._firstPoint.x > this._secondPoint.x
                || this._firstPoint.y > this._secondPoint.y) {
                if (this._firstPoint.x > this._secondPoint.x
                    && this._firstPoint.y > this._secondPoint.y) {
                    tempStartPoint = new Communicator.Point2(maxWidth - tempPoint.x, maxHeight - tempPoint.y);
                    tempEndPoint = tempPoint;
                } else {
                    if (this._firstPoint.x > this._secondPoint.x) {
                        tempStartPoint = new Communicator.Point2(maxWidth - tempPoint.x, tempPoint.y);
                        tempEndPoint = new Communicator.Point2(tempPoint.x, maxHeight - tempPoint.y);
                    }
                    if (this._firstPoint.y > this._secondPoint.y) {
                        tempStartPoint = new Communicator.Point2(tempPoint.x, maxHeight - tempPoint.y);
                        tempEndPoint = new Communicator.Point2(maxWidth - tempPoint.y, tempPoint.y);
                    }
                }
            }
        }
        const startPoint = new Communicator.Point2(tempStartPoint.x, tempStartPoint.y);
        const endPoint = new Communicator.Point2(tempEndPoint.x, tempEndPoint.y);

        this.drawLineWithCaps(
            this._canvasContext,
            startPoint,
            endPoint);
    }
    private calType1(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokeWidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        const vec1 = MathHelper.calVector(vec, Math.PI / 4);
        const vec2 = MathHelper.calVector(vec, -Math.PI / 4);
        const vec3 = MathHelper.calVector(vec, Math.PI * 3 / 4);
        const vec4 = MathHelper.calVector(vec, -Math.PI * 3 / 4);

        let arrLght = this.strokeWidth * this._lineCapLengthScale / 1.5;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const p3 = pointStart.copy().add(vec3.scale(arrLght / vec3.length()));
        const p4 = pointStart.copy().add(vec4.scale(arrLght / vec4.length()));
        const points: Communicator.Point2[] = [p1, p2, p4, p3, p1];

        return points;
    }
    private calType2(pointStart: Communicator.Point2, pointEnd: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }

        let arrLght = this.strokeWidth * this._lineCapLengthScale / 2;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        ctx.beginPath();
        ctx.arc(pointStart.copy().x, pointStart.copy().y, arrLght, 0, 2 * Math.PI);
        ctx.stroke();
    }
    private calType3(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokeWidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        const vec1 = MathHelper.calVector(vec, Math.PI / 2);
        const vec2 = MathHelper.calVector(vec, -Math.PI / 2);
        const vec3 = MathHelper.calVector(vec, Math.PI);
        const vec4 = MathHelper.calVector(vec, 0);

        let arrLght = this.strokeWidth * this._lineCapLengthScale / 1.5;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const p3 = pointStart.copy().add(vec3.scale(arrLght / vec3.length()));
        const p4 = pointStart.copy().add(vec4.scale(arrLght / vec4.length()));
        const points: Communicator.Point2[] = [p1, p3, p2, p4, p1];

        return points;
    }
    private calType4(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokeWidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        const vec1 = MathHelper.calVector(vec, Math.PI / 6);
        const vec2 = MathHelper.calVector(vec, - Math.PI / 6);

        let arrLght = this.strokeWidth * this._lineCapLengthScale;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy();
        const p3 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const points: Communicator.Point2[] = [p1, p2, p3]
        return points;
    }
    private calType5(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokeWidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        const vec1 = MathHelper.calVector(vec, Math.PI / 6);
        const vec2 = MathHelper.calVector(vec, -Math.PI / 6);
        const vec3 = MathHelper.calVector(vec, 0);

        let arrLght = this.strokeWidth * this._lineCapLengthScale;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy();
        const p3 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const p4 = pointStart.copy().add(vec3.scale(arrLght * Math.sqrt(3) / 2 / vec3.length()));
        const points: Communicator.Point2[] = [p4, p1, p2, p3, p4]
        return points;
    }
    private calType6(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        // Arrow
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokewidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        // Arrow
        const vec1 = MathHelper.calVector(vec, Math.PI / 2);
        const vec2 = MathHelper.calVector(vec, -Math.PI / 2);

        let arrLght = this.strokeWidth * this._lineCapLengthScale / 2;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p3 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const points: Communicator.Point2[] = [p1, p3]
        return points;
    }
    private calType7(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokeWidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        const vec1 = MathHelper.calVector(vec, Math.PI * 5 / 6);
        const vec2 = MathHelper.calVector(vec, -Math.PI * 5 / 6);

        let arrLght = this.strokeWidth * this._lineCapLengthScale;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy();
        const p3 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const points: Communicator.Point2[] = [p1, p2, p3]
        return points;
    }
    private calType8(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokeWidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        const vec1 = MathHelper.calVector(vec, Math.PI * 5 / 6);
        const vec2 = MathHelper.calVector(vec, -Math.PI * 5 / 6);
        const vec3 = MathHelper.calVector(vec, Math.PI);

        let arrLght = this.strokeWidth * this._lineCapLengthScale;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy();
        const p3 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const p4 = pointStart.copy().add(vec3.scale(arrLght * Math.sqrt(3) / 2 / vec3.length()));
        const points: Communicator.Point2[] = [p4, p1, p2, p3, p4]
        return points;
    }
    private calType9(pointStart: Communicator.Point2, pointEnd: Communicator.Point2): Communicator.Point2[] {
        // Arrow
        const vec = pointEnd.copy().subtract(pointStart.copy());
        if (!vec.equals(Communicator.Point2.zero())) {
            // Move first point by vec with distance = strokewidth/2
            const delta = -(this.strokeWidth / 2) / vec.length();
            pointStart.add(new Communicator.Point2(vec.x * delta, vec.y * delta));
        }
        // Arrow
        const rad = Math.PI * 2 / 3;
        const vec1 = MathHelper.calVector(vec, rad);
        const vec2 = MathHelper.calVector(vec, Math.PI + rad);

        let arrLght = this.strokeWidth * this._lineCapLengthScale / 2;
        const lineLght = vec.length();
        if (lineLght < arrLght * 3) arrLght = lineLght / 3;
        const p1 = pointStart.copy().add(vec1.scale(arrLght / vec1.length()));
        const p2 = pointStart.copy().add(vec2.scale(arrLght / vec2.length()));
        const points: Communicator.Point2[] = [p1, p2]
        return points;
    }
    drawLineWithCaps(ctx: CanvasRenderingContext2D, startPoint: Communicator.Point2, endpoint: Communicator.Point2): void {
        const gap = 0
        const dx = endpoint.x - startPoint.x;
        const dy = endpoint.y - startPoint.y;
        const angle = Math.atan2(dy, dx);
        const length = Math.sqrt(dx * dx + dy * dy) - gap;
        ctx.translate(startPoint.x, startPoint.y);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(gap, 0);
        ctx.lineTo(length, 0);
        const p1 = new Communicator.Point2(gap, 0);
        const p2 = new Communicator.Point2(length, 0);
        ctx.stroke();
        switch (this._startLineCapStyle) {
            case LineCapStyle.None:
                this.listPointsStart = [];
                break;
            case LineCapStyle.Square:
                this.listPointsStart = this.calType1(p1, p2);
                break;
            case LineCapStyle.Circle:
                this.calType2(p1, p2, ctx);
                this.listPointsStart = [];
                break;
            case LineCapStyle.Diamond:
                this.listPointsStart = this.calType3(p1, p2);
                break;
            case LineCapStyle.OpenArrow:
                this.listPointsStart = this.calType4(p1, p2);
                break;
            case LineCapStyle.ClosedArrow:
                this.listPointsStart = this.calType5(p1, p2);
                break;
            case LineCapStyle.Butt:
                this.listPointsStart = this.calType6(p1, p2);
                break;
            case LineCapStyle.ReverseOpenArrow:
                this.listPointsStart = this.calType7(p1, p2);
                break;
            case LineCapStyle.ReverseClosedArrow:
                this.listPointsStart = this.calType8(p1, p2);
                break;
            case LineCapStyle.Slash:
                this.listPointsStart = this.calType9(p1, p2);
                break;
            default: break;
        }
        switch (this._endLineCapStyle) {
            case LineCapStyle.None:
                break;
            case LineCapStyle.Square:
                this.listPointsEnd = this.calType1(p2, p1);
                break;
            case LineCapStyle.Circle:
                this.calType2(p2, p1, ctx);
                this.listPointsEnd = [];
                break;
            case LineCapStyle.Diamond:
                this.listPointsEnd = this.calType3(p2, p1);
                break;
            case LineCapStyle.OpenArrow:
                this.listPointsEnd = this.calType4(p2, p1);
                break;
            case LineCapStyle.ClosedArrow:
                this.listPointsEnd = this.calType5(p2, p1);
                break;
            case LineCapStyle.Butt:
                this.listPointsEnd = this.calType6(p2, p1);
                break;
            case LineCapStyle.ReverseOpenArrow:
                this.listPointsEnd = this.calType7(p2, p1);
                break;
            case LineCapStyle.ReverseClosedArrow:
                this.listPointsEnd = this.calType8(p2, p1);
                break;
            case LineCapStyle.Slash:
                this.listPointsEnd = this.calType9(p2, p1);
                break;
            default: break;
        }
        if (this._startLineCapStyle !== LineCapStyle.Circle) MathHelper.drawLineCap(this.listPointsStart, ctx);
        if (this._endLineCapStyle !== LineCapStyle.Circle) MathHelper.drawLineCap(this.listPointsEnd, ctx);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    private updateSize() {
        if (this._firstPoint && this._secondPoint) {
            const b = new Communicator.Point2(
                Math.min(this._firstPoint.x, this._secondPoint.x),
                Math.min(this._firstPoint.y, this._secondPoint.y),
            );
            const a = new Communicator.Point2(
                Math.max(this._firstPoint.x, this._secondPoint.x),
                Math.max(this._firstPoint.y, this._secondPoint.y),
            );
            const temp = Communicator.Point2.subtract(a, b);
            const size = new Communicator.Point2(temp.x + this.sizeExt.x, temp.y + this.sizeExt.y);
            this.baseCanvas.width = size.x;
            this.baseCanvas.height = size.y;
        }
    }

    private updatePosition() {
        const position = new Communicator.Point2(0, 0);
        if (this._firstPoint) {
            position.y = this._firstPoint.y - this.sizeExt.y / 2;
            position.x = this._firstPoint.x - this.sizeExt.x / 2;
        }
        if (this._firstPoint && this._secondPoint) {
            const size = new Communicator.Point2(this.baseCanvas.width - this.sizeExt.x, this.baseCanvas.height - this.sizeExt.y);
            if (this._firstPoint.x > this._secondPoint.x) {
                position.x -= size.x;
            }
            if (this._firstPoint.y > this._secondPoint.y) {
                position.y -= size.y;
            }
        }

        this.setPosition(position);
    }

    private setPosition(p: Communicator.Point2) {
        if (p) {
            this.baseCanvas.style.top = `${p.y}px`;
            this.baseCanvas.style.left = `${p.x}px`;
        }
    }

    getLine(): HTMLCanvasElement {
        return this.baseCanvas;
    }

    setSecondPoint(secondPoint: Communicator.Point2): void {
        this._secondPoint = secondPoint.copy();
    }

    setFirstPoint(firstPoint: Communicator.Point2): void {
        if (firstPoint) this._firstPoint = firstPoint.copy();
    }
    getSecondPoint(): Communicator.Point2 {
        return this._secondPoint.copy();
    }

    getSize(): Communicator.Point2 {
        return new Communicator.Point2(this.baseCanvas.width - this.sizeExt.x, this.baseCanvas.height - this.sizeExt.y);
    }

    getPosition(): Communicator.Point2 {
        return this._firstPoint;
    }

    getPoints(): Communicator.Point2[] {
        const points = [];
        if (this._firstPoint) points.push(this._firstPoint);
        if (this._secondPoint) points.push(this._secondPoint);
        return points;
    }
    // Line cap style GET SET
    setStartLineCapStyle(style: LineCapStyle): void {
        this._startLineCapStyle = style;
    }
    getStartLineCapStyle(): LineCapStyle {
        return this._startLineCapStyle;
    }
    setEndLineCapStyle(style: LineCapStyle): void {
        this._endLineCapStyle = style;
    }
    getEndLineCapStyle(): LineCapStyle {
        return this._endLineCapStyle;
    }
}
