import { GlobalState } from "common/global";
import GeneralOperator from "../custom-operator/general.operator";
import CPanOperator from "../extends/CPanOperator";
import BaseOperator from "./base.operator";

export default class PanOperator extends BaseOperator {
    generalOperator: GeneralOperator;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    generalHandle: any;
    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.Name = 'pan';
        this.generalOperator = new GeneralOperator(viewer);
        this.generalHandle = viewer.operatorManager.registerCustomOperator(
            this.generalOperator,
        );
    }
    /**
     * Active
     */
    public Active(): void {
        if (this.Status === false) {
            this.Viewer.operatorManager.push(this.generalHandle);
            if(this.Viewer.sheetManager.isDrawingSheetActive()) {
                this.Viewer.operatorManager.push(Communicator.OperatorId.Zoom);
            }
            const pan = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan);
            if (pan instanceof CPanOperator) {
                pan.setInPanMode(true)
            }
            pan.setMapping(Communicator.Button.Left);
            pan.addMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            pan.addMapping(Communicator.Button.Right)
            this.Viewer.operatorManager
                .getOperator(Communicator.OperatorId.Orbit)
                .setMapping(
                    Communicator.Button.Middle,
                    Communicator.KeyModifiers.Shift,
                );
            this.Status = true;
            GlobalState.mapOperatorCursor.set('pan', 'grab');
            GlobalState.primaryOperator.set(this.Viewer, 'pan');
            this.SetCursor('grab')
        }
        if (this.Status === true) { // using for update the mode changed
        }
    }

    /**
     * InActive
     *
     */
    public InActive(): void {
        if (this.Status === true) {
            this.Viewer.operatorManager.remove(this.generalHandle);
            this.Viewer.operatorManager.push(Communicator.OperatorId.Select);
            this.Viewer.operatorManager.unregisterCustomOperator(this.generalHandle);
            const pan = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan);
            if (pan instanceof CPanOperator) {
                pan.setInPanMode(false)
            }
            pan.setMapping(Communicator.Button.Right);
            this.Viewer.operatorManager
                .getOperator(Communicator.OperatorId.Orbit)
                .setMapping(Communicator.Button.Left);
            this.Status = false;
            this.SetCursor('')
        }
    }
}
