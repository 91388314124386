/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPinMarkerData, MarkupToolbarAction, NotepinCategory } from 'common/define';
import { GlobalState } from 'common/global';
import { MarkupEntity, MarkupMode, MarkupStatus } from 'common/type-markup';
import ModelHelper from '../model/model.helper';
import { MarkupNotepinItem } from './markup-3d/markup.notepin.item';
import { MarkupNotePinOperator } from './markup-3d/markup.notepin.operator';
import { MarkupPinMarkerItem } from './markup-3d/markup.pinmarker.item';
import { MarkupPinMarkerOperator } from './markup-3d/markup.pinmarker.operator';
import { MarkupBaseItem } from './markup-items/markup.base.item';
import { MarkupCalloutItem } from './markup-items/markup.callout.item';
import { MarkupNoteItem } from './markup-items/markup.note.item';
import { MarkupTextBoxItem } from './markup-items/markup.textbox.item';
import { MarkupArrowOperator } from './markup-operators/markup.arrow.operator';
import { MarkupBaseOperator } from './markup-operators/markup.base.operator';
import { MarkupCalloutOperator } from './markup-operators/markup.callout.operator';
import { MarkupCircleOperator } from './markup-operators/markup.circle.operator';
import { MarkupCloudOperator } from './markup-operators/markup.cloud.operator';
import { MarkupEllipseOperator } from './markup-operators/markup.ellipse.operator';
import { MarkupLineOperator } from './markup-operators/markup.line.operator';
import { MarkupNoteOperator } from './markup-operators/markup.note.operator';
import { MarkupPolygonOperator } from './markup-operators/markup.polygon.operator';
import { MarkupPolylineOperator } from './markup-operators/markup.polyline.operator';
import { MarkupRectangleOperator } from './markup-operators/markup.rectangle.operator';
import { MarkupSignatureOperator } from './markup-operators/markup.signature.operator';
import { MarkupTextBoxOperator } from './markup-operators/markup.textbox.operator';
import { MarkupAction } from './markup.action';
import { UndoRedoAction, UndoRedoActionInfor } from './markup.data';

export class MarkupInsert {
    public viewer: Communicator.WebViewer | null;
    public markupAction: MarkupAction | null;
    public operatorId: Communicator.OperatorId | null = null;
    public operatorItem: MarkupBaseOperator | null = null;
    public constructor(viewer: Communicator.WebViewer | null, action: MarkupAction) {
        this.viewer = viewer;
        this.markupAction = action;
    }

    InsertShapes(action: MarkupToolbarAction, secondaryParam?: NotepinCategory | IPinMarkerData | string | number): void {
        if (this.viewer && this.markupAction && this.markupAction.markupData) {
            // cancel the current operator to start new operator
            this.markupAction.cancelOperator();
            this.viewer.getViewElement().style.cursor = 'crosshair';
            const { startLineShapeType, endLineShapeType } = this.markupAction.markupData;
            if (action === MarkupToolbarAction.DrawLine) {
                const operatorItem = new MarkupLineOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                operatorItem.setStartLineShape(startLineShapeType);
                operatorItem.setEndLineShape(endLineShapeType);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawArrow) {
                const operatorItem = new MarkupArrowOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawCircle) {
                const operatorItem = new MarkupCircleOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawEllipse) {
                const operatorItem = new MarkupEllipseOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawPolygon) {
                const operatorItem = new MarkupPolygonOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawPolyline) {
                const operatorItem = new MarkupPolylineOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawSignature) {
                const operatorItem = new MarkupSignatureOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawRectangle) {
                const operatorItem = new MarkupRectangleOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawTextBox) {
                const operatorItem = new MarkupTextBoxOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                this.setTextRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawNote) {
                const operatorItem = new MarkupNoteOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setTextRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawCallout) {
                const operatorItem = new MarkupCalloutOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                this.setTextRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawNotePin3d && secondaryParam) {
                const notepinCategory = secondaryParam as NotepinCategory;
                const operatorItem = new MarkupNotePinOperator(this.viewer, this.viewer.noteTextManager, notepinCategory);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawCloud) {
                const operatorItem = new MarkupCloudOperator(this.viewer);
                operatorItem.setNonViewMode();
                this.setLineRelatedAttribute(operatorItem);
                this.setFillRelatedAttribute(operatorItem);
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            else if (action === MarkupToolbarAction.DrawPinMarker) {
                const pinMarkerData = secondaryParam as IPinMarkerData;
                if (pinMarkerData.nodes.length === 0)
                    this.viewer.selectPart(null)
                const operatorItem = new MarkupPinMarkerOperator(this.viewer, pinMarkerData);
                operatorItem.setNonViewMode();
                operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                this.operatorId = this.viewer.registerCustomOperator(operatorItem);
                operatorItem.setMarkupOperatorId(this.operatorId);
                this.viewer.operatorManager.push(this.operatorId);
                this.operatorItem = operatorItem;
            }
            GlobalState.currentMarkupOperator$.next(this.operatorItem);
        }
    }

    setLineRelatedAttribute(operatorItem: MarkupBaseOperator): void {
        const { lineColor, lineWeight, lineStyle } = this.markupAction!.markupData!;
        operatorItem.setLineColor(lineColor);
        operatorItem.setLineWeight(lineWeight);
        operatorItem.setLineStyle(lineStyle);
    }
    setFillRelatedAttribute(operatorItem: MarkupBaseOperator): void {
        const { lineOpacity, fillColor, fillColorOption } = this.markupAction!.markupData!;
        operatorItem.setLineOpacity(lineOpacity);
        operatorItem.setFillColor(fillColor);
        operatorItem.setFillColorOption(fillColorOption);
    }
    setTextRelatedAttribute(operatorItem: MarkupBaseOperator): void {
        const { textFontSize, textColor, textFontFamily, textIsBold, textIsItalic, textIsUnderline } = this.markupAction!.markupData!;
        operatorItem.setTextFontSize(textFontSize);
        operatorItem.setTextColor(textColor);
        operatorItem.setTextFontFamily(textFontFamily);
        operatorItem.setTextIsBold(textIsBold);
        operatorItem.setTextIsItalic(textIsItalic);
        operatorItem.setTextIsUnderline(textIsUnderline);
    }
    operatorEndCallback(oper: Communicator.Operator.OperatorBase): void {
        if (oper instanceof MarkupBaseOperator) {
            // end operator and remove it from operator manager
            if (this.viewer) {
                const operatorItem = oper as MarkupBaseOperator
                this.viewer.operatorManager.unregisterCustomOperator(operatorItem.getOperatorId());
                this.viewer.operatorManager.remove(operatorItem.getOperatorId());
                ModelHelper.setCursorViewer(this.viewer, 'default')
                GlobalState.mapActiveDraw.set(this.viewer, false);
            }
            // append new markup to list
            const item = oper.getMarkupItem();
            GlobalState.currentMarkupOperator$.next(null);
            if (!item) return;
            this.appendMarkupItem(item);
            item.setSelected(true);

            // remove cursor sprite
            if (this.operatorItem) this.operatorItem.removeCursorSprite();

            // append new markup to undo/redo stack
            if (!item.isMarkup3D && this.markupAction && this.markupAction.markupUndoRedo) {
                const addMarkup: UndoRedoActionInfor = {
                    action: UndoRedoAction.Add,
                    item: [item]
                };
                this.markupAction.markupUndoRedo.pushToUndoStack(addMarkup);
                // refresh UI state
                if (this.markupAction.markupData) {
                    this.markupAction.markupData.markupItems.forEach(markup => {
                        if (markup.uniqueId !== item.uniqueId)
                            markup.setSelected(false);
                    })
                    this.markupAction.markupData.updateMarkupItems();
                }
                this.markupAction.setActiveDrawingState(false);
            }
            // edit text right after insert
            this.actionCallbackAfterInsert(item);
            GlobalState.activeOperator$.next(null);
        }
    }

    actionCallbackAfterInsert(item: MarkupBaseItem): void {
        if (item instanceof MarkupTextBoxItem || item instanceof MarkupCalloutItem) {
            setTimeout(() => {
                item.focusTextArea();
            }, 100)
        }
        else if (item instanceof MarkupNoteItem) {
            GlobalState.currentActiveNote$.next(item);
        }
    }

    appendMarkupItem(item: MarkupBaseItem): void {
        if (item && this.markupAction && this.markupAction.markupData) {
            if (item instanceof MarkupNotepinItem) {
                this.markupAction.markupData.markupNotepins.push(item);
                this.markupAction.markupData.updateMarkupNotepins();
                this.markupAction.markupData.updateCurrentActiveNotpin(item);
                return;
            }
            if (item instanceof MarkupPinMarkerItem) {
                this.markupAction.markupData.markupPinMarker.push(item);
                this.markupAction.markupData.updateMarkupPinMarker();
                this.markupAction.markupData.updateCurrentActivePinMarker(item);
                GlobalState.triggerSavePinMarker$.next(item);
                return;
            }
            this.markupAction.markupData.markupItems.push(item);
            this.markupAction.markupData.updateMarkupItems();
            this.markupAction.markupData.markupUnsaved.push(this.createMarkupItem(item));
            this.markupAction.markupData.updateMarkupUnsaved();
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    createMarkupItem(originData: any): any {
        if (this.viewer && this.markupAction) {
            const newItem: MarkupEntity = {
                uniqueId: originData.uniqueId,
                originData: originData,
                createdDate: new Date().toISOString(),
                modifiedDate: new Date().toISOString(),
                createdBy: {
                    id: '',
                    userName: 'anonymous'
                },
                status: MarkupStatus.Open,
                type: originData.type,
                uniqueViewId: '',
                uniqueGroupId: '',
                modeMarkup: originData.markupMode ? originData.markupMode : MarkupMode.Mode2d,
                ModelFileId: this.markupAction.modelFileId,
            };
            return newItem;
        }
    }
}