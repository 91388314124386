import { SystemConstants } from "common/system-constants";
import { CMICProperty } from "common/type-state";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import Utils from "utils/utils";
import HttpClient from "./http-client";

export default class CMICPropertyApi {

    static getCostCode(): Observable<CMICProperty> {
        const api = SystemConstants.CMIC_PROPERTY.COST_CODE.GET();
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('CostCode'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as CMICProperty));
    }

    static getCostTypeJob(): Observable<CMICProperty> {
        const api = SystemConstants.CMIC_PROPERTY.COST_TYPE.GET_JOB();
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('CostType'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as CMICProperty));
    }

    static getCostTypeCat(): Observable<CMICProperty> {
        const api = SystemConstants.CMIC_PROPERTY.COST_TYPE.GET_CAT();
        const options = {
            headers: {
                'Authorization': Utils.getAuthField('CostType'),
            }
        }
        return HttpClient.get(api, options).pipe(map(res => res as CMICProperty));
    }
}