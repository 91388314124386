import { createSelector } from "reselect";
import { selectSelfLogin } from "./self.selector";

export const getLogInState = createSelector(
    selectSelfLogin,
    (state) => state
)
export const getLogInToken = createSelector(
    selectSelfLogin,
    (state) => state.token
)
export const getLogInStatus = createSelector(
    selectSelfLogin,
    (state) => {
        return {
            loading: state.loading,
            success: state.success
        }
    }
)