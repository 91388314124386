import { Subject } from 'rxjs';
import { Normal, SectionStatus } from './cutting-plane.helper';

export interface Info {
    isCutting?: boolean;
    isExplode?: boolean;
    magnitude?: number;
    isVisibility?: boolean;
    isToggle?: boolean;
    isDisabledToggle?: boolean;
    isDisabledVisibility?: boolean;
    statusX?: SectionStatus;
    statusY?: SectionStatus;
    statusZ?: SectionStatus;
    modeMultipleSides?: boolean;
    isDisabledMultipleSides?: boolean;
    drawMode?: Communicator.DrawMode;
}

export class ActiveButtonControlService {
    Viewer: Communicator.WebViewer | undefined;

    private info = new Subject<Info>();

    private _info: Info | undefined;

    Info$ = this.info.asObservable();

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setInfo(info: Info) {
        this.info.next(info);
        this._info = info;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setViewer(viewer: Communicator.WebViewer) {
        this.Viewer = viewer;
        this.setInfo(this.initStatus());
    }

    private isMultipleSides = false;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setMultipleSidesMode(value: boolean) {
        this.isMultipleSides = value;
        this.setInfo(this.initStatus());
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { }

    initStatus(): Info {
        // eslint-disable-next-line no-debugger
        const section1 = this.Viewer?.cuttingManager.getCuttingSection(0);
        const section2 = this.Viewer?.cuttingManager.getCuttingSection(1);
        const section3 = this.Viewer?.cuttingManager.getCuttingSection(2);
        const section4 = this.Viewer?.cuttingManager.getCuttingSection(3);
        const section5 = this.Viewer?.cuttingManager.getCuttingSection(4);
        const section6 = this.Viewer?.cuttingManager.getCuttingSection(5);
        let statusX = SectionStatus.NoFaceCutOff;
        let statusY = SectionStatus.NoFaceCutOff;
        let statusZ = SectionStatus.NoFaceCutOff;
        let isToggle = false;
        let modeMultipleSides = this.isMultipleSides;
        if (section1) {
            if (
                section1.isActive()
                && section1.getCount() !== 0
            ) {
                const planeX = section1.getPlane(0);
                if (planeX?.normal.equals(Normal.NormalX)) {
                    statusX = SectionStatus.PresentCutOff;
                } else {
                    statusX = SectionStatus.ReversesTheCutOfTheCutters;
                }
            } else {
                statusX = SectionStatus.NoFaceCutOff;
            }
        }
        if (section2) {
            if (
                section2.isActive()
                && section2.getCount() !== 0
            ) {
                const planeY = section2.getPlane(0);
                if (planeY?.normal.equals(Normal.NormalY)) {
                    statusY = SectionStatus.PresentCutOff;
                } else {
                    statusY = SectionStatus.ReversesTheCutOfTheCutters;
                }
            } else {
                statusY = SectionStatus.NoFaceCutOff;
            }
        }
        if (section3) {
            if (
                section3.isActive()
                && section3.getCount() !== 0
            ) {
                const planeZ = section3.getPlane(0);
                if (planeZ?.normal.equals(Normal.NormalZ)) {
                    statusZ = SectionStatus.PresentCutOff;
                } else {
                    statusZ = SectionStatus.ReversesTheCutOfTheCutters;
                }
            } else {
                statusZ = SectionStatus.NoFaceCutOff;
            }
        }
        if (section4) {
            if (
                section4.isActive()
                && section4.getCount() !== 0
            ) {
                const count = section4.getCount();
                if (count === 1) {
                    const plane = section4.getPlane(0);
                    if (plane?.normal.equals(Normal.NormalMinusX)) {
                        modeMultipleSides = true;
                    }
                } else {
                    isToggle = true;
                    for (let i = 0; i < count; i += 1) {
                        const temp = section4.getPlane(i);
                        if (temp?.normal.equals(Normal.NormalX)) {
                            statusX = SectionStatus.PresentCutOff;
                        } else if (temp?.normal.equals(Normal.NormalY)) {
                            statusY = SectionStatus.PresentCutOff;
                        } else if (temp?.normal.equals(Normal.NormalZ)) {
                            statusZ = SectionStatus.PresentCutOff;
                        } else if (temp?.normal.equals(Normal.NormalMinusX)) {
                            statusX = SectionStatus.ReversesTheCutOfTheCutters;
                        } else if (temp?.normal.equals(Normal.NormalMinusY)) {
                            statusY = SectionStatus.ReversesTheCutOfTheCutters;
                        } else if (temp?.normal.equals(Normal.NormalMinusZ)) {
                            statusZ = SectionStatus.ReversesTheCutOfTheCutters;
                        }
                    }
                }
            } else {
                isToggle = false;
            }
        }
        if (section5 && section5.getCount() !== 0) {
            modeMultipleSides = true;
        }
        if (section6 && section6.getCount() !== 0) {
            modeMultipleSides = true;
        }
        let isDisabledToggle = (statusX === SectionStatus.NoFaceCutOff && statusY === SectionStatus.NoFaceCutOff)
            || (statusX === SectionStatus.NoFaceCutOff && statusZ === SectionStatus.NoFaceCutOff)
            || (statusY === SectionStatus.NoFaceCutOff && statusZ === SectionStatus.NoFaceCutOff);
        if (modeMultipleSides) {
            isDisabledToggle = true;
        }
        const isCutting = statusX !== SectionStatus.NoFaceCutOff
            || statusY !== SectionStatus.NoFaceCutOff
            || statusZ !== SectionStatus.NoFaceCutOff;
        const isDisabledVisibility = !isCutting;
        const isVisibility = this.statusIsVisibility();
        let isDisabledMultipleSides = false;
        isDisabledMultipleSides = isToggle;
        const magnitude = this.Viewer?.explodeManager.getMagnitude();
        const isExplode = magnitude !== 0;
        const drawmode: Communicator.DrawMode | undefined = this.Viewer?.view.getDrawMode();

        return {
            isCutting,
            isExplode,
            magnitude,
            isVisibility,
            isToggle,
            isDisabledToggle,
            isDisabledVisibility,
            statusX,
            statusY,
            statusZ,
            drawMode: drawmode,
            modeMultipleSides,
            isDisabledMultipleSides,
        };
    }

    statusIsVisibility(): boolean {
        const count = this.Viewer?.cuttingManager.getCuttingSectionCount();
        if(count) {
            for (let i = 0; i < count; i += 1) {
                const section = this.Viewer?.cuttingManager.getCuttingSection(i);
                if (section?.isActive() && section.getCount() !== 0) {
                    const countplane = section.getCount();
                    for (let j = 0; j < countplane; j += 1) {
                        const refgeo = section.getReferenceGeometry(j);
                        if (refgeo === null) { return true; }
                    }
                }
            }
        }
        return false;
    }
}
