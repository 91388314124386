import { KeyCode } from "common/define";
import Utils from "utils/utils";
import { KeysGroup, MapKey } from "./type";

const ARR_MODIFIER = ['Meta', 'Control', 'Alt', 'Shift'];
export default class KeyMappingHelper {
    static convertKeyGroup(keyGroup: KeysGroup): string {
        const {modified, key} = keyGroup;
        let result: string[] = [];
        if (modified) {
            result = modified.map(modi => {
                if (modi === KeyCode.ctrl) return 'ctrl'
                if (modi === KeyCode.meta) return 'win'
                return modi
            })
        }
        key && result.push(key);
        return result.join('+')?.toLowerCase();
    }
    static convertArrayKeyGroup(arrKeyGroup: KeysGroup[]): string {
        return arrKeyGroup.map(keyGroup => KeyMappingHelper.convertKeyGroup(keyGroup)).join(' or ')
    }
    static convertKeyEvent(keyEvent: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>): {keyConvert: string, arrKeyConvertWithTextFull: string[]} {
        const arrKeyConvert = [];
        const arrKeyConvertWithTextFull = [];
        let keyPrimary = keyEvent.key; 
        keyEvent.metaKey && arrKeyConvert.push('win') && arrKeyConvertWithTextFull.push('Window');
        keyEvent.ctrlKey && arrKeyConvert.push('ctrl') && arrKeyConvertWithTextFull.push('Ctrl');
        keyEvent.shiftKey && arrKeyConvert.push('shift') && arrKeyConvertWithTextFull.push('Shift');
        keyEvent.altKey && arrKeyConvert.push('alt') && arrKeyConvertWithTextFull.push('Alt');
        if (arrKeyConvert.length > 0 && ARR_MODIFIER.includes(keyPrimary)) {
            keyPrimary = ''
        }
        keyPrimary && arrKeyConvert.push(keyPrimary) && arrKeyConvertWithTextFull.push(Utils.firstUpperCase(keyPrimary));
        const keyConvert = arrKeyConvert.join('+');

        return {
            keyConvert,
            arrKeyConvertWithTextFull
        }
    }

    static convertKeyEventToKeyGroup(keyEvent: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>): KeysGroup {
        const result: KeysGroup = {};
        const arrModifier: KeyCode[] = [];
        keyEvent.metaKey && arrModifier.push(KeyCode.meta);
        keyEvent.ctrlKey && arrModifier.push(KeyCode.ctrl);
        keyEvent.shiftKey && arrModifier.push(KeyCode.shift);
        keyEvent.altKey && arrModifier.push(KeyCode.alt);
        keyEvent.key && (result.key = keyEvent.key as KeyCode);
        arrModifier.length > 0 && (result.modified = arrModifier);
        return result
    }
    
    static filterKeyMapping(textKey: string, data: MapKey[]): Promise<MapKey[]> {
        return new Promise<MapKey[]>((resolve) => {
            if (data && data.length > 0) {
                if (!textKey) {
                    resolve(data);
                    return
                }
                const textSearch = textKey.replace(/\s+/g, '')?.toLowerCase();
                const result = data.filter(mapKey => mapKey.keyConvertString.includes(textSearch))
                resolve(result);
                return
            }
            resolve([])
        });
    }
    /**
     * Transform key input event -> string. 
     * @param keyEvent KeyboardEvent
     * @returns string with format: 'meta.ctrl.shift.alt.key'
     */
    static transformKeyEventValueToString(keyEvent: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>): string {
        const {key, ctrlKey, shiftKey, altKey, metaKey} = keyEvent;
        const booleanToString = (status: boolean) => {return status ? '1': '0'};
        const hasMeta = booleanToString(metaKey);
        const hasCtrl = booleanToString(ctrlKey);
        const hasShift = booleanToString(shiftKey);
        const hasAlt = booleanToString(altKey);
        const hasKey = key ? key.toLowerCase() : '0';
        return `${hasMeta}.${hasCtrl}.${hasShift}.${hasAlt}.${hasKey}`
    }
    /**
     * Transform key group -> string. 
     * Ex: {key: f1, modified: [ctrl, alt]} -> '0.1.0.1.f1'
     * @param keyGroup Ex: {key: f1, modified: [ctrl, alt]}
     * @returns string with format: 'meta.ctrl.shift.alt.key'
     */
    static transformKeyGroupToString(keyGroup: KeysGroup | KeysGroup[]): string[] {
        const listKeyGroup = Array.isArray(keyGroup) ? keyGroup : [keyGroup];
        const result = listKeyGroup.map(keyG => {
            const {key, modified} = keyG;
            const hasKey = key ? key.toLowerCase() : '0';
            if (Array.isArray(modified)) {
                const hasMeta = checkKey(KeyCode.meta, modified);
                const hasCtrl = checkKey(KeyCode.ctrl, modified);
                const hasShift = checkKey(KeyCode.shift, modified);
                const hasAlt = checkKey(KeyCode.alt, modified);
                return `${hasMeta}.${hasCtrl}.${hasShift}.${hasAlt}.${hasKey}`
            }
            return `0.0.0.0.${hasKey}`
        })
        return Array.from(new Set(result))
    }
}

function checkKey(key: KeyCode, keys: KeyCode[]): string {
    let hasKey = '0';
    if (keys.includes(key)) hasKey = '1';
    return hasKey
}