import React, { ComponentClass, FunctionComponent } from "react";

interface RouterItem {
    path: string;
    component: ComponentClass | FunctionComponent;
    exact?: boolean;
    isNotPrivate?: boolean;
}

const LoginModule = React.lazy(() => import("../pages/login"));
const RegisterModule = React.lazy(() => import("../pages/register"));
const DashboardModule = React.lazy(() => import("../pages/dashboard"));
const MainViewerModule = React.lazy(() => import("../pages/main-viewer"));
const IconPreview = React.lazy(() => import("../components/IconPreview"));

const RouterArr: RouterItem[] = [
    {
        path: "/",
        component: DashboardModule,
        exact: true,
        isNotPrivate: true
    },
    {
        path: "/login",
        component: LoginModule,
        exact: true,
        isNotPrivate: true
    },
    {
        path: "/register",
        component: RegisterModule,
        exact: true,
        isNotPrivate: true
    },
    {
        path: "/open",
        component: DashboardModule,
        exact: true,
        isNotPrivate: true
    },
    {
        path: "/mainviewer",
        component: MainViewerModule,
        exact: true,
        isNotPrivate: true
    },
    {
        path: "/icons",
        component: IconPreview,
        exact: true,
        isNotPrivate: true
    },
]

export default RouterArr;