
type nameWorker = 'tree';
const mapWorker = new Map<nameWorker, Worker>();
function createWorker(name: nameWorker): void {
    if (typeof Worker !== 'undefined') {
        if (!mapWorker.get(name)) {
            const newWorker = new Worker(`${process.env.PUBLIC_URL}/workers/${name}.worker.js`, {type: 'module'});
            mapWorker.set(name, newWorker);
        }
    }
}

export function createAllWorker(): void {
    const allName: Record<nameWorker, nameWorker> = {
        'tree': 'tree'
    };
    Object.keys(allName).forEach(key => {
        createWorker(key as nameWorker);
    })
}

export const workers: Record<nameWorker, () => Worker | undefined> = {
    tree: () => mapWorker.get('tree')
}