/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GlobalState } from "common/global";
import BaseOperator from "./base.operator";

export default class TurntableOperator extends BaseOperator {
    axis: Communicator.Axis;
    constructor(viewer: Communicator.WebViewer, axis: Communicator.Axis) {
        super(viewer);
        this.Name = 'rotateZ';
        this.axis = axis;
    }
    /**
   * Active
   */
    public Active() {
        if (this.Status === false) {
            this.Viewer.operatorManager.remove(Communicator.OperatorId.Navigate)
            this.Viewer.operatorManager.push(Communicator.OperatorId.Pan);
            this.Viewer.operatorManager.push(Communicator.OperatorId.Turntable);
            const turnTableOperator = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Turntable);
            turnTableOperator.setRotationAxis(this.axis);
            turnTableOperator.setMapping(Communicator.Button.Left);
            const panOperator = this.Viewer.operatorManager.getOperator(Communicator.OperatorId.Pan);
            panOperator.addMapping(Communicator.Button.Left, Communicator.KeyModifiers.Shift);
            panOperator.addMapping(Communicator.Button.Right);
            this.Viewer.operatorManager.push(Communicator.OperatorId.Zoom)
            this.Status = true;
            GlobalState.mapOperatorCursor.set('rotateZ', 'url("/assets/cursor/rotate.png"), default');
            GlobalState.primaryOperator.set(this.Viewer, 'rotateZ');
            super.SetCursor('url("/assets/cursor/rotate.png"), default')
        }
    }

    /**
     * InActive
     */
    public InActive() {
        if (this.Status === true) {
            super.ResetDefautOperators();
            this.Status = false;
            super.SetCursor('');
        }
    }

    GetIdOperator(): number {
        return Communicator.OperatorId.Turntable;
    }

}
