/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LineStyle, MarkupBaseJson } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { CustomGrippointElement } from '../markup-canvas-elements/markup.custom-grippoint.element';
import { PolylineElementCanvas } from '../markup-canvas-elements/markup.polyline-canvas.element';
import { MarkupBaseItem } from './markup.base.item';
export class MarkupPolylineItem extends MarkupBaseItem {
    private points: Communicator.Point3[] = [];
    private polylineHTML: PolylineElementCanvas = new PolylineElementCanvas();
    private _uniqueId: string;

    // private arrowShapeStart = new PolylineElementCanvas();

    // private arrowStartId: string | null = null;

    // private arrowShapeEnd = new PolylineElementCanvas();
    // private arrowEndId: string | null = null;
    private customGrippoints: CustomGrippointElement[] = [];
    private customGrippointIndex = -1;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupPolyline';
        this.shapeName = 'Polyline';
        this._uniqueId = this.uniqueId;
        const polylineItem = this;
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(false);
        this.redlineBounding.setGripDirection([]);
        this.redlineBounding.setBoudingboxCallback(
            (point: Communicator.Point2) => {
                polylineItem.onDragStart(point);
            },
            (point: Communicator.Point2) => {
                polylineItem.onDragMove(point);
            },
            (point: Communicator.Point2) => {
                polylineItem.onBoundingBoxDragEndCallback(point);
            },
        );
        this.redlineBounding.setRotateGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                polylineItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2, tooltipPos: Communicator.Point2) => {
                this.rotateGripPointDragMoveCallback(point, tooltipPos);
            },
            (point: Communicator.Point2) => {
                polylineItem.rotateGripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }

    removeElement(id: string) {
        if (id) this._viewer.markupManager.removeMarkupElement(id);
        return null;
    }
    addPoint(point: Communicator.Point3) {
        this.points.push(point.copy());
        const index = this.points.length - 1;
        const grPoint = new CustomGrippointElement(
            this._viewer,
            (pnt: Communicator.Point2) => {
                this.onCustomGrippointDragStart(pnt, index);
            },
            (pnt: Communicator.Point2) => {
                this.onCustomGrippointDragMove(pnt);
            },
            (pnt: Communicator.Point2) => {
                this.onCustomGrippointDragEnd(pnt);
            },
        );
        this.customGrippoints.push(grPoint);
    }
    private _update(): void {
        const strokeWidth = this._lineWeight;
        // if (this.isSelected) strokeWidth += 2;
        this.polylineHTML.setStrokeWidth(strokeWidth);
        this.polylineHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        if (this._lineStyle !== LineStyle.cloud && this._lineStyle !== LineStyle.zigzag) {
            this.polylineHTML.setLineStyle(this._lineStyle);
        }

        this.polylineHTML.setRotation(this._rotation);
        if (this.redlineBounding) {
            this.redlineBounding.setRotation(this._rotation);
            this.redlineBounding.updateGripPoints();
            this.redlineBounding.updateRotationTransform(this._rotation);
        }
        this.updateRotateTransform(this._rotation);

        const { view } = this._viewer;
        this.polylineHTML.clearPoints();
        this.points.forEach((value) => {
            const pnt = Communicator.Point2.fromPoint3(view.projectPoint(value));
            this.polylineHTML.pushPoint(pnt);
        });
        if (this.points.length > 1) this._isReady = true;
        this.updateBoundingBox();
        this.updateCustomGrippoint();
    }

    updateBoundingBox() {
        const points = this.polylineHTML.getPoints();
        if (!points || !points.length) return;
        const stroke = this.polylineHTML.getStrokeWidth();
        let maxX = points[0].x;
        let maxY = points[0].y;
        let minX = points[0].x;
        let minY = points[0].y;

        points.forEach((value) => {
            if (value.x > maxX) maxX = value.x;
            if (value.y > maxY) maxY = value.y;
            if (value.x < minX) minX = value.x;
            if (value.y < minY) minY = value.y;
        });
        if (!this.redlineBounding) return;
        const pos = new Communicator.Point2(minX - stroke / 2, minY - stroke / 2);
        const size = new Communicator.Point2(maxX - minX + stroke, maxY - minY + stroke);
        this.redlineBounding.setCenter(
            new Communicator.Point2(minX, minY),
            new Communicator.Point2(maxX, maxY)
        );
        this.redlineBounding.setPosition(pos);
        this.redlineBounding.setSize(size);
        this.center = this.redlineBounding.center;
    }

    updateCustomGrippoint() {
        const points = this.polylineHTML.getPoints();
        if (!points || !this.customGrippoints || points.length !== this.customGrippoints.length) return;
        points.forEach((v, i) => {
            const gripPt = this.customGrippoints[i];
            gripPt.center = this.center;
            gripPt.rotation = this._rotation;
            const point = MathHelper.convertPointWithRotation(v.copy(), this.center, this._rotation, gripPt.gripSize);
            gripPt.setPosition(point);
        })
    }

    public draw(): void {
        this._update();

        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.polylineHTML.getCanvas(),
                );
            }
        } else {
            this._redlineElementId = this.removeElement(this._redlineElementId!);
        }
        this.handleBoundingRectInteraction(() => {
            this.customGrippoints && this.customGrippoints.forEach((grPnt) => {
                if (grPnt && this.boundingIds) {
                    const id = this._viewer.markupManager.addMarkupElement(grPnt.getGrippointElement());
                    this.boundingIds.push(id);
                }
            });
        });
    }

    public getClassName(): string {
        return "Communicator.Markup.MarkupPolylineItem";
    }
    popPoint() {
        this.points.pop();
        this.customGrippoints.pop();
    }

    updateLastPoint(point: Communicator.Point3) {
        if (!point) return;
        this.points.pop();
        this.points.push(point);
    }

    getPoints() {
        const rets = this.points.map(v => v.copy());
        return rets;
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineStyle: this._lineStyle === LineStyle.cloud || this._lineStyle === LineStyle.zigzag ? LineStyle.continous : this._lineStyle,
            lineWeight: this._lineWeight,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            uniqueId: this.uniqueId,
            points: this.points.map(val => val.toJson()),
            rotation: this._rotation,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineStyle = data.lineStyle;
        this._lineWeight = data.lineWeight
        const points = data.points as any[];
        this.points = [];
        if (points && points.length > 1) {
            points.forEach(val => this.addPoint(Communicator.Point3.fromJson(val)))
        }
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._rotation = data.rotation;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }

    rotateGripPointDragMoveCallback(point: Communicator.Point2, tooltipPos: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const boundRects = this.getHTMLElement().getBoundingClientRect();
            const pointX = boundRects.left + boundRects.width / 2;
            const pointY = boundRects.top + boundRects.height / 2;
            const rotateAngle = ((Math.atan2(point.y - pointY, point.x - pointX) + Math.PI / 2) * 180 / Math.PI - 180) % 360;
            this.setRotation(rotateAngle);
            this._update();
            if (this.redlineBounding) {
                this.redlineBounding.setRotation(rotateAngle);
                // this.redlineBounding.updateGripPoints();
            }
        }
    }

    onDragStart(point: Communicator.Point2) {
        this.isUpdate = false;
        if (!this._isCanEdit) return false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return !0;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c: any = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this.points.forEach((value) => value.add(c));
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();

        return !0;
    }

    // onBoundingBoxDragEndCallback(point: Communicator.Point2) {
    //     if (this.isUpdate) {
    //         this.isUpdate = false;
    //         this.triggerOnMarkupUpdated();
    //     }
    // }

    onCustomGrippointDragStart(point: Communicator.Point2, index: number) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.customGrippointIndex = index;
    }

    onCustomGrippointDragMove(point: Communicator.Point2) {
        if (!this._isCanEdit) return;
        this.isUpdate = true;
        const { view } = this._viewer;
        const newPoint = MathHelper.convertPointWithRotation(point, this.center, 360 - this._rotation);
        const newPos = view.getCamera().getCameraPlaneIntersectionPoint(newPoint, view);
        if (this.customGrippointIndex >= 0 && this.customGrippointIndex < this.points.length) {
            this.points[this.customGrippointIndex].assign(newPos!);
            this._viewer.markupManager.refreshMarkup();
        }
    }

    onCustomGrippointDragEnd(point: Communicator.Point2) {
        if (this.isUpdate) {
            this.triggerOnMarkupUpdated();
        }
        this.isUpdate = false;
        this.customGrippointIndex = -1;
    }
    hit(point: Communicator.Point2) {
        return this.polylineHTML.hit(point);
    }
    setMarkupVisible(visible: boolean): void {
        this.polylineHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        this.customGrippoints.forEach(v => v.setVisibleGripPoints(visible));
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.polylineHTML.baseCanvas;
    }
    setBoundingGripPointVisible(vis: boolean): void {
        this.customGrippoints.forEach(point => point.setVisible(vis));
    }
}
