import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModeLayerMarkupPanel, TypeGroup } from "common/type-state";

interface MarkupPanelState {
    modeLayer: ModeLayerMarkupPanel;
    typeGroup: TypeGroup;
}

const initState: MarkupPanelState = {
    modeLayer: 'main',
    typeGroup: 'full'
};

const markupPanelSlice = createSlice({
    name: 'markupPanel',
    initialState: initState,
    reducers: {
        changeTypeGroupMarkup(state, action: PayloadAction<TypeGroup>) {
            state.typeGroup = action.payload;
        },
        changeModeLayerMarkup(state, action: PayloadAction<ModeLayerMarkupPanel>) {
            state.modeLayer = action.payload;
        }
    }
});

export const { changeTypeGroupMarkup, changeModeLayerMarkup } = markupPanelSlice.actions;
export default markupPanelSlice.reducer;