/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import RFIAPI from "api/rfi.api";
import { PinMarkerType } from "common/define";
import { RFIData, RootEpic } from "common/type-state";
import dummyText from "pages/main-viewer/float-panel/linked-objects/RFIdummyData";
import { merge, of, zip } from "rxjs";
import { catchError, concatMap, filter, map, mergeMap, withLatestFrom, switchMap } from "rxjs/operators";
interface RFIstatusState {
    data: {
        RFI: RFIData | undefined | null,
        PCI: RFIData | undefined | null,
        SUB: RFIData | undefined | null,
        DOC: RFIData | undefined | null,
    }
    isFetching: boolean,
    itemInfo: string | undefined,
    isDummyData: boolean,
}

const initState: RFIstatusState = {
    data: {
        RFI: undefined,
        SUB: undefined,
        PCI: undefined,
        DOC: undefined,
    },
    isFetching: false,
    itemInfo: undefined,
    isDummyData: true,
}

const RFIstatusSlice = createSlice({
    name: 'RFIstatus',
    initialState: initState,
    reducers: {
        fetchLinkedObject(state, action: PayloadAction<undefined>) { return },
        setIsFetching(state, action: PayloadAction<boolean>) {
            state.isFetching = action.payload;
        },
        setData(state, action: PayloadAction<any>) {
            state.data = action.payload;
        },
        toggleDummy(state, action: PayloadAction<undefined>) { return },
        toggleIsDummy(state, action: PayloadAction<undefined>) {
            state.isDummyData = !state.isDummyData;
        },
        setIsDummy(state, action: PayloadAction<boolean>) {
            state.isDummyData = action.payload;
        },
    }
})

const fetchLinkedObject$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchLinkedObject.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
        const apiCall = zip(
            RFIAPI.getRFI().pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.map((item: any) => item._type = PinMarkerType.RFI);
                    return { RFI: newVal }
                }),
                catchError(e => of({ RFI: null }))
            ),
            RFIAPI.getSUB().pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.map((item: any) => item._type = PinMarkerType.SUB);
                    return { SUB: newVal }
                }),
                catchError(e => of({ SUB: null }))
            ),
            RFIAPI.getPCI().pipe(
                map(val => {
                    const newVal = { ...val };
                    newVal.items.map((item: any) => item._type = PinMarkerType.PCI);
                    return { PCI: newVal }
                }),
                catchError(e => of({ PCI: null }))
            ),
        );
        return merge(
            [setIsFetching(true)],
            apiCall.pipe(
                concatMap(val => {
                    const result = Object.assign({}, ...val) as any;
                    return [
                        RFIstatusSlice.actions.setData(result),
                        setIsFetching(false)
                    ]
                }),
                catchError(e => {
                    console.log(e);
                    return [
                        setIsFetching(false)
                    ]
                })
            )
        )
    }
    ))

const toggleDummy$: RootEpic = (action$, state$) => action$.pipe(
    filter(toggleDummy.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const curState = state.RFIstatus.isDummyData;
        if (curState) {
            const dataDummy = {
                RFI: JSON.parse(dummyText),
                PCI: null,
                SUB: null,
                DOC: null,
            }
            return [
                RFIstatusSlice.actions.setData(dataDummy),
                RFIstatusSlice.actions.toggleIsDummy()
            ]
        }
        return [
            RFIstatusSlice.actions.setData(initState.data),
            RFIstatusSlice.actions.fetchLinkedObject(),
            RFIstatusSlice.actions.toggleIsDummy(),
        ]
    })
)
export const RFIstatusEpics = [
    // getRFI$,
    // getByCompany$,
    // getPmSub$,
    // getPCI$,
    fetchLinkedObject$,
    // getPMSubByCompCode$,
    // getDocument$,
    // getSubmittal$
    toggleDummy$,
]

export const {
    fetchLinkedObject,
    setIsFetching,
    toggleDummy,
    setIsDummy,

} = RFIstatusSlice.actions;
export default RFIstatusSlice.reducer;