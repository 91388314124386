import { ContextMenuState as BoostrapState, ResultConfirm, TitleContentDialog } from "common/define";
import { GlobalState } from "common/global";
import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import Utils from "utils/utils";

export interface BoostrapContextType {
   state: BoostrapState;
   dispatch: React.Dispatch<ActionReducer>;
   openConfirm: (prop: TitleContentDialog) => Observable<ResultConfirm>
}

type ActionReducer = 
{type: 'SET_VISIBLE_CONTEXT', payload: [React.MouseEvent, React.ReactNode, string?]} | 
{type: 'CLOSE_CONTEXT'} |
{type: 'HANDLE_RESULT_CONFIRM', payload: ResultConfirm} |
{type: 'TITLE_CONTENT_CONFIRM', payload: TitleContentDialog};

/** define, functions */
export const BoostrapContext = React.createContext<BoostrapContextType | null>(null);
export function useContextBoostrap(): BoostrapContextType {
    return useContext(BoostrapContext) as BoostrapContextType;
}

const initState: BoostrapState = {
    visibleContext: false,
    mouseEventContext: null,
    contentContext: null,
    contextMenuId: '',
    visibleConfirm: false,
    titleContent: {}
};

const reducer = (state: BoostrapState, action: ActionReducer) => {
    switch(action.type) {
    case 'SET_VISIBLE_CONTEXT':{
        const [mouseEvent, content, contextId] = action.payload;
        const visibleState: Partial<BoostrapState> = {
            visibleContext: content ? true : false,
            mouseEventContext: mouseEvent,
            contentContext: content,
            contextMenuId: contextId ?? Communicator.UUID.create()
        };
        return Utils.setPartialState(state, visibleState)
    }
    case 'CLOSE_CONTEXT': {
        return Utils.setPartialState(state, {
            visibleContext: false,
            mouseEventContext: null
        })
    }
    case 'HANDLE_RESULT_CONFIRM': {
        const newState = Utils.setPartialState(state, {visibleConfirm: false});
        GlobalState.resultConfirm$.next(action.payload);
        return newState
    }
    case 'TITLE_CONTENT_CONFIRM': {
        return Utils.setPartialState(state, {
            visibleConfirm: true,
            titleContent: action.payload
        })
    }
    default:
        return state;
    }
};

export function useReducerBoostrap(): BoostrapContextType {
    const [state, dispatch] = useReducer(reducer, initState);

    const openConfirmDialog = useCallback((prop: TitleContentDialog): Observable<ResultConfirm> => {
        dispatch({type: 'TITLE_CONTENT_CONFIRM', payload: prop});
        return GlobalState.resultConfirm$.pipe(take(1))
    }, []);

    useEffect(() => {
        const sub = GlobalState.confirmDialog$.subscribe(val => {
            openConfirmDialog(val);
        })
        return () => {
            sub && sub.unsubscribe();
        }
    }, [openConfirmDialog]);  
    
    return {
        state,
        dispatch,
        openConfirm: openConfirmDialog
    }
}