import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BimApi } from "api/bim.api"
import { GlobalState } from "common/global"
import { MarkupEntity, MarkupViewEntities } from "common/type-markup"
import { RootEpic } from "common/type-state"
import { merge } from "rxjs"
import { catchError, filter, switchMap, withLatestFrom } from "rxjs/operators"



interface Markup3dActionState {
    listMarkup3d: MarkupEntity[];
    fetchLoading: boolean;
    saveLoading: boolean;
}

const initState: Markup3dActionState = {
    listMarkup3d: [],
    fetchLoading: false,
    saveLoading: false,
}

const markup3dActiveSlice = createSlice({
    name: 'pinMarkerAction',
    initialState: initState,
    reducers: {
        setListMarkup3d(state, action: PayloadAction<MarkupEntity[]>) {
            state.listMarkup3d = action.payload;
        },
        fetchMarkup3d(state, action: PayloadAction<undefined>) { return },
        setFetchLoading(state, action: PayloadAction<boolean>) {
            state.fetchLoading = action.payload;
        },
        saveMarkup3d(state, action: PayloadAction<MarkupEntity>) { return },
        setSaveLoading(state, action: PayloadAction<boolean>) {
            state.saveLoading = action.payload;
        },
        updateMarkup3d(state, action: PayloadAction<MarkupEntity>) { return },
        deleteMarkup3d(state, action: PayloadAction<MarkupEntity>) { return },
    }
})

const fetchMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchMarkup3d.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const viewer = GlobalState.getViewer3D(viewActive.viewId);
        return merge(
            [setFetchLoading(true)],
            BimApi.getMarkup3D(viewActive.modelFileId).pipe(
                switchMap(value => {
                    if (!value) return [setFetchLoading(false)];
                    const parseValue: MarkupEntity[] = JSON.parse(value);
                    parseValue.forEach(markup => {
                        if (!markup.originData?.markupView || !viewer) return;
                        const views: MarkupViewEntities[] = [markup.originData.markupView];
                        const markupData = {
                            views: views,
                            notes: [],
                            measurement: [],
                            lines: [],
                        };
                        viewer.markupManager.loadMarkupData(markupData)
                    })
                    return [setListMarkup3d(parseValue)]
                }),
                catchError(err => [setFetchLoading(false)])
            ),
        )
    }))

const saveMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(saveMarkup3d.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const payload = action.payload
        const nodeIds = payload.originData.pinMarkerData.nodes;
        const currentTreeMap = state.tree.currentTreeMap;
        const persistentIds: string[] = [];
        nodeIds.forEach((node: number) => {
            const treeNode = currentTreeMap.get(node.toString());
            if (treeNode?.PersistentId) 
                persistentIds.push(treeNode.PersistentId)
        })
        const finalPayload = {
            ...payload,
            persistentIds
        };
        return merge(
            [setSaveLoading(true)],
            BimApi.updateMarkup3D(viewActive.modelFileId, finalPayload).pipe(
                switchMap(value => {
                    return [
                        setSaveLoading(false),
                        fetchMarkup3d()
                    ]
                }),
                catchError(err => [setSaveLoading(false)])
            ),
        )
    }))

const updateMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateMarkup3d.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const payload = action.payload;
        return merge(
            [setSaveLoading(true)],
            BimApi.updateMarkup3D(viewActive.modelFileId, payload).pipe(
                switchMap(value => {
                    return [setSaveLoading(false)]
                }),
                catchError(err => [setSaveLoading(false)])
            ),
        )
    }))

const deleteMarkup3d$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteMarkup3d.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const payload = action.payload;

        return merge(
            [setSaveLoading(true)],
            BimApi.deleteMarkup3D(viewActive.modelFileId, [payload.uniqueId]).pipe(
                switchMap(value => {
                    return [
                        setSaveLoading(false),
                        fetchMarkup3d()
                    ]
                }),
                catchError(err => [setSaveLoading(false)])

            ),
        )
    }))

export const Markup3dActionEpics = [
    fetchMarkup3d$,
    saveMarkup3d$,
    updateMarkup3d$,
    deleteMarkup3d$,
]

export const {
    setListMarkup3d,
    fetchMarkup3d,
    setFetchLoading,
    saveMarkup3d,
    setSaveLoading,
    updateMarkup3d,
    deleteMarkup3d,

} = markup3dActiveSlice.actions;

export default markup3dActiveSlice.reducer;
