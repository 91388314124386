/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IsolateInfo } from "common/type-viewer";
import { selectionFlag } from "../extends/CSelectionManager";
import ModelHelper from "./model.helper";

export interface ResultActionContext {
    clearSelection?: boolean;
}

export default class ModelState {
    isIsolate = false;
    didShowHide = false;
    isolateInfo: IsolateInfo | undefined;
    constructor(private viewer: Communicator.WebViewer) { }

    async zoomFit(): Promise<ResultActionContext> {
        return ModelHelper.zoomFit(this.viewer)
    }
    async zoomSelect(selectionArray: NodeId[]): Promise<ResultActionContext> {
        // await this.viewer.view.fitNodes(selectionArray, 0);
        return ModelHelper.zoomSelect(this.viewer, selectionArray);
    }
    async isolate(selectionArray: NodeId[]): Promise<ResultActionContext> {
        this.viewer.selectionManager.addFlag(selectionFlag.SelectByIsolate)
        this.unDoNotXRayNodes();
        await this.viewer.model.setNodesVisibility(selectionArray, true);
        this.doNotXRayNodes(selectionArray);
        this.isIsolate = true;
        return {
            clearSelection: true
        }
    }
    async showHideNode(selectionArray: NodeId[], type: 'show' | 'hide'): Promise<ResultActionContext> {
        if (selectionArray.length > 0) {
            const visibility = type === 'show' ? true : false;
            await this.viewer.model.setNodesVisibility(selectionArray, visibility);
            this.didShowHide = true;
            return {
                clearSelection: true
            }
        }
        return {}
    }
    async showAll(): Promise<ResultActionContext> {
        await this.resetNodesVisibility();
        if (this.isIsolateInfo()) {
            this.unDoNotXRayNodes()
        }
        if (this.isIsolate || this.didShowHide) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const arrNode = this.viewer.sheetManager.isDrawingSheetActive()
                ? this.showNodeSheetActive()
                : this.showNode3D();
            this.resetState();
            return {
                clearSelection: true
            }
        }
        return {}
    }
    async showHandles(selectionArray: NodeId[]): Promise<void> {
        if (this.viewer.sheetManager.isDrawingSheetActive()) return
        const handleOperator = this.viewer.operatorManager.getOperator(Communicator.OperatorId.Handle)
        await handleOperator.removeHandles()
        const box = await ModelHelper.getBoundingBox(this.viewer, selectionArray);
        if (box) {
            const center = box.center()
            handleOperator.addHandles(selectionArray, center);
        }
    }
    async reset(): Promise<ResultActionContext> {
        const handleOperator = this.viewer.operatorManager.getOperator(Communicator.OperatorId.Handle);
        await handleOperator.removeHandles();
        await this.resetNodesVisibility();
        !this.viewer.sheetManager.isDrawingSheetActive() && this.viewer.model.reset()
        this.resetExplode();
        this.resetGhostMode(this.viewer);
        return this.showAll();
    }

    unDoNotXRayNodes(): void {
        if (this.isolateInfo) {
            let rootNodeId;
            if (this.viewer.sheetManager.isDrawingSheetActive())
                rootNodeId = this.viewer.sheetManager.getActiveSheetId();
            else rootNodeId = this.viewer.model.getAbsoluteRootNode();
            this.viewer.model.setInstanceModifier(
                Communicator.InstanceModifier.DoNotXRay,
                [rootNodeId!],
                false
            );
            this.viewer.view.setDrawMode(this.isolateInfo.drawMode);
            this.isolateInfo = undefined;
        }
    }
    doNotXRayNodes(nodeIds: number[]): void {
        if (nodeIds && nodeIds.length > 0) {
            if (this.isolateInfo) {
                let rootNodeId = null;
                if (this.viewer.sheetManager.isDrawingSheetActive())
                    rootNodeId = this.viewer.sheetManager.getActiveSheetId();
                else rootNodeId = this.viewer.model.getAbsoluteRootNode();
                this.viewer.model.setInstanceModifier(
                    Communicator.InstanceModifier.DoNotXRay,
                    [rootNodeId!],
                    false
                );
                this.isolateInfo.nodeIds = nodeIds;
            } else {
                this.isolateInfo = {
                    nodeIds,
                    drawMode: this.viewer.view.getDrawMode(),
                };
            }
            this.viewer.model.setInstanceModifier(
                Communicator.InstanceModifier.DoNotXRay,
                nodeIds,
                true
            );
            this.viewer.view.setDrawMode(Communicator.DrawMode.XRay);
        }
    }

    /** private */
    private isIsolateInfo(): boolean {
        return !!(this.isolateInfo && this.isolateInfo.nodeIds?.length > 0)
    }
    private resetState() {
        this.isIsolate = false;
        this.didShowHide = false
    }
    private showNodeSheetActive(): NodeId[] {
        const sheetIdActive = this.viewer.sheetManager.getActiveSheetId();
        if (sheetIdActive) {
            this.viewer.view.isolateNodes([sheetIdActive], Communicator.DefaultTransitionDuration, false);
            return [sheetIdActive];
        }
        return [];
    }
    private showNode3D(): NodeId[] {
        const arrNode3D = this.viewer.sheetManager.get3DNodes();
        if (arrNode3D.length > 0) {
            this.viewer.view.isolateNodes(arrNode3D, Communicator.DefaultTransitionDuration, false);
            return arrNode3D;
        }
        return []
    }
    private resetExplode() {
        this.viewer.explodeManager.setMagnitude(0);
        this.viewer.explodeManager.stop()
    }
    private resetGhostMode(viewer: Communicator.WebViewer) {
        const rootNodeId = ModelHelper.getRootNodeId(viewer);
        rootNodeId && viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, [rootNodeId], true);
    }
    private async resetNodesVisibility() {
        await this.viewer.model.resetNodesColor();
        this.viewer.model.resetModelOpacity();
        let rootNodeId;
        if (this.viewer.sheetManager.isDrawingSheetActive())
            rootNodeId = this.viewer.sheetManager.getActiveSheetId();
        else rootNodeId = this.viewer.model.getAbsoluteRootNode();
        this.viewer.model.setNodesVisibility([rootNodeId!], true)
    }
}