/* eslint-disable @typescript-eslint/no-explicit-any */
import { BimApi } from "api/bim.api";
import { CadView, MapValueProperties, SaveView, TabTreeViewsKeys, ValueProperties, Views } from "common/define";
import { GlobalState } from "common/global";
import { format as fnsFormat } from 'date-fns';
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { Lodash } from "utils/utils";

type KeySaveView = keyof SaveView;
const arrkeySaveViewGet: KeySaveView[] = [
    'Name', 'SheetId', 'drawMode', 'explodeMagnitude', 'createdBy', 'createdDate', 'modifiedDate'
]
const mapDrawMode: {[key: number]: string} = {
    0: 'Wireframe',
    1: 'Shaded',
    2: 'WireframeOnShaded',
    3: 'HiddenLine',
    4: 'XRay',
    5: 'Ghost'
}
const mapKeyTabValue: Partial<Record<TabTreeViewsKeys, keyof MapValueProperties>> = {
    Models: 'models',
    Sheets: 'sheets',
    Views: 'views'
}

function getValuePropertiesFromCadViewSaveView(obj: CadView | SaveView, type: keyof Views): ValueProperties {
    if (type === 'cadViews') {
        const {Id, Name, Camera} = obj as CadView;
        return {
            Id, Name, Camera
        }
    } else {
        const arrPropSaveView = Object.entries(obj as SaveView);
        const newArr = arrPropSaveView.reduce<[string, any]>((pre, curr) => {
            const [key, value] = curr;
            const keySaveView = key as KeySaveView;
            const newCurr = curr;
            if (arrkeySaveViewGet.includes(keySaveView)) {
                switch (keySaveView) {
                case 'createdDate':
                case 'modifiedDate': {
                    const dateAs = new Date(value);
                    const date = fnsFormat(dateAs, 'MM/dd/yyyy');
                    newCurr[1] = date
                    break
                }
                case 'drawMode': {
                    newCurr[1] = mapDrawMode[value as number];
                    break
                }
                default:
                    break
                }
                pre.push(newCurr)
            }
            return pre
        }, [] as any);
        return Object.fromEntries(newArr)
    }
}
export function getValuePropertiesFromViews(views: Views | null, idViewSelected: string): ValueProperties {
    if (views !== null && typeof views === 'object') {
        let viewSelected;
        let typeViews: keyof Views = 'cadViews';
        Object.keys(views).some(viewType => {
            const viewTypeFinal = viewType as keyof Views;
            const temp = views[viewTypeFinal].some((view: SaveView | CadView) => {
                if (view.Id === idViewSelected) {
                    viewSelected = view;
                    return true
                }
                return false
            })
            if (temp) {
                typeViews = viewTypeFinal;
            }
            return temp;
        })
        if (viewSelected && typeof viewSelected === 'object') {
            return getValuePropertiesFromCadViewSaveView(viewSelected, typeViews);
        }
    }
}
export function getValuePropertiesByMap(map: MapValueProperties | undefined, tabKey = TabTreeViewsKeys.Models): ValueProperties[] | undefined {
    const key = mapKeyTabValue[tabKey];
    if (key && map) {
        return map[key]
    }
}
function setValuePropertiesByMap(map: MapValueProperties, tabKey: TabTreeViewsKeys, value: ValueProperties[]): MapValueProperties {
    const key = mapKeyTabValue[tabKey];
    if (key) {
        map[key] = value
    }
    return map;
}
export function setMapProperties(viewId: ViewId, tabKey: TabTreeViewsKeys, value: ValueProperties[]): MapValueProperties {
    const viewIdFinal = GlobalState.getViewId(viewId);
    const preMap = GlobalState.mapPropertiesData.get(viewIdFinal);
    let newMap: MapValueProperties;
    if (!preMap) {
        newMap = {
            models: [],
            sheets: [],
            views: []
        };
    } else {
        newMap = Lodash.cloneDeep(preMap);
    }
    newMap = setValuePropertiesByMap(newMap, tabKey, value);
    GlobalState.mapPropertiesData.set(viewIdFinal, newMap);
    return newMap
}

export function getPropertiesExtend$(
    modelFileIdParent: ModelFileId,
    persistentId: number,
    currentValueProperties: ValueProperties[] | undefined,
    modelFileIdSheet?: ModelFileId
): Observable<ValueProperties[]> {
    return BimApi.getPropertiesNode(modelFileIdParent, persistentId, modelFileIdSheet).pipe(
        map(re => {
            if (currentValueProperties) {
                const cloneCurrent = Lodash.cloneDeep(currentValueProperties);
                return [...cloneCurrent, re]
            }
            return [re]
        })
    )
}
function getPropertiesFromNodeId$(nodeId: NodeId, webViewer: Communicator.WebViewer): Observable<ValueProperties> {
    return new Observable(sub => {
        if (webViewer) {
            webViewer.model.getNodeProperties(nodeId).then(val => {
                const result = val && typeof val === 'object' && Object.keys(val).length > 0 ? val : undefined;
                sub.next(result);
                sub.complete();
            }).catch(err => sub.error(err))
        } else {
            sub.error();
        }
    })
}
export function getFristPropertiesFromNode$(
    nodeId: NodeId,
    webViewer: Communicator.WebViewer,
    webViewerActive: Communicator.WebViewer,
    modelFileIdParent: ModelFileId
): Observable<ValueProperties> {
    return getPropertiesFromNodeId$(nodeId, webViewer).pipe(
        mergeMap(re => getPropertiesFromNodeId$(nodeId, webViewerActive).pipe(
            map(re2 => {
                let result: ValueProperties = {...re, ...re2};
                const resultFromStore =  GlobalState.mapTreeData.get(modelFileIdParent);
                if (!result && resultFromStore) {
                    const tempResultFromStore = resultFromStore.extra.mapAttr?.get(nodeId);
                    result = tempResultFromStore
                }
                return result && Object.entries(result).length > 0 ? result: undefined
            })
        ))
    )
}
