/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { GlobalState } from "common/global";
import CursorSprite from "container/viewer3d/custom-operator/cursor-sprite.operator";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { filter, first } from "rxjs/operators";
import { primaryBoundingColor } from "utils/utils";
import { MarkupBaseItem } from "../markup-items/markup.base.item";
import { UndoRedoAction, UndoRedoActionInfor } from "../markup.data";

/* eslint-disable @typescript-eslint/no-this-alias */
export class CustomGrippointElement {
    private gripPoint: HTMLDivElement | null = null;
    private viewer: Communicator.WebViewer;
    private currentPosition: Communicator.Point2 = Communicator.Point2.zero();

    private dragStartCallback;

    private dragMoveCallback;

    private dragEndCallback;
    private dragging = false;
    protected _gripSize = 10;
    private _center = Communicator.Point2.zero();
    private _rotation = 0;
    protected cursorSpriteOpe: CursorSprite | null = null;
    private _cursorSprite = true;
    constructor(viewer: Communicator.WebViewer, dragStartCallback?: (point: Communicator.Point2) => void,
        dragMoveCallback?: (point: Communicator.Point2, isForcing: boolean) => void,
        dragEndCallback?: (point: Communicator.Point2) => void,) {
        this.dragStartCallback = dragStartCallback;
        this.dragMoveCallback = dragMoveCallback;
        this.dragEndCallback = dragEndCallback;
        this.createGrippoint();
        this.viewer = viewer;
        this.cursorSpriteOpe = new CursorSprite(this.viewer);
        this.enableCursorSprite(false);
    }

    createGrippoint(): void {
        this.gripPoint = document.createElement('div');
        this.gripPoint.style.position = 'absolute';
        this.gripPoint.style.display = 'initial';
        this.gripPoint.style.zIndex = '5';
        this.gripPoint.style.cursor = 'grab';
        this.gripPoint.style.width = `${this._gripSize}px`;
        this.gripPoint.style.height = `${this._gripSize}px`;
        this.gripPoint.style.border = '1px solid gray';
        this.gripPoint.style.borderRadius = '50%';
        this.gripPoint.style.pointerEvents = 'auto';
        this.gripPoint.style.backgroundColor = '#fff';
        this.gripPoint.style.boxShadow = 'inset 0px 0px 0px 1px white';

        const customGrippoint = this;
        const grPoint = this.gripPoint;
        grPoint.onmousedown = (event) => {
            if (event) {
                event.preventDefault();
                grPoint.style.cursor = 'grabbing';
                customGrippoint.dragging = true;
                const parent = grPoint.parentElement!;
                const spParent = parent.parentElement!;
                const crPrCursor = spParent.style.cursor;
                parent.style.pointerEvents = 'auto';
                grPoint.style.backgroundColor = `${primaryBoundingColor.hex}`;
                const backup = this.getAllSelectedMarkup().map(markup => markup.toJson());
                // For grabbing
                spParent.style.cursor = 'grabbing';
                const point = new Communicator.Point2(
                    parseInt(grPoint.style.left, 10) + event.offsetX,
                    parseInt(grPoint.style.top, 10) + event.offsetY);
                if (customGrippoint.dragStartCallback) {
                    customGrippoint.dragStartCallback(point);
                    GlobalState.isGripDragging$.next(true);
                }
                parent.onmousemove = (async (ev) => {
                    if (ev && customGrippoint.dragging) {
                        ev.stopPropagation();
                        if (customGrippoint.dragMoveCallback) {
                            const mousePos: Communicator.Point2 = customGrippoint.getMousePostion(parent, ev);
                            customGrippoint.dragMoveCallback(mousePos, ev.shiftKey);
                            await this.updateCursorSprite(mousePos);
                        }
                    }
                    parent.onmouseup = (ev) => {
                        if (ev && customGrippoint.dragging) {
                            ev.stopPropagation();
                            grPoint.style.backgroundColor = '#fff';
                            customGrippoint.dragging = false;
                            parent.style.pointerEvents = 'none';
                            spParent.style.cursor = crPrCursor;
                            grPoint.style.cursor = 'grab';
                            if (customGrippoint.dragEndCallback) {
                                const edittedMarkup: UndoRedoActionInfor = {
                                    action: UndoRedoAction.Edit,
                                    item: this.getAllSelectedMarkup(),
                                    backUp: backup,
                                }
                                GlobalState.undoStack$.next(edittedMarkup);
                                customGrippoint.dragEndCallback(customGrippoint.getMousePostion(parent, ev));
                                GlobalState.isGripDragging$.next(false);
                            }
                            this.enableCursorSprite(false);
                        }
                    };
                });
                // Add for case "user mouse up in different view"
                fromEvent<MouseEvent>(document, 'mouseup').pipe(first(), filter(e => customGrippoint.dragging))
                    .subscribe(mouseUp => {
                        const parent = grPoint.parentElement!;
                        mouseUp.stopPropagation();
                        grPoint.style.backgroundColor = '#fff';
                        customGrippoint.dragging = false;
                        parent.style.pointerEvents = 'none';
                        spParent.style.cursor = crPrCursor;
                        grPoint.style.cursor = 'grab';

                        if (customGrippoint.dragEndCallback) {
                            const edittedMarkup: UndoRedoActionInfor = {
                                action: UndoRedoAction.Edit,
                                item: this.getAllSelectedMarkup(),
                                backUp: backup,
                            }
                            GlobalState.undoStack$.next(edittedMarkup);
                            customGrippoint.dragEndCallback(customGrippoint.getMousePostion(parent, mouseUp));
                            GlobalState.isMarkupDragging$.next(false);
                        }
                        this.enableCursorSprite(false);
                    });
                parent.onmouseup = (event) => {
                    event.stopPropagation();
                    grPoint.style.backgroundColor = '#fff';
                    customGrippoint.dragging = false;
                    parent.style.pointerEvents = 'none';
                    spParent.style.cursor = crPrCursor;
                    grPoint.style.cursor = 'grab';
                    this.enableCursorSprite(false);
                }

                // parent.onmouseleave = (ev) => {
                //     if (ev && customGrippoint.dragging) {
                //         ev.stopPropagation();
                //         customGrippoint.dragging = false;
                //         parent.style.pointerEvents = 'none';
                //         spParent.style.cursor = crPrCursor;
                //         grPoint.style.cursor = 'grab';
                //         if (customGrippoint.dragEndCallback) {
                //             customGrippoint.dragEndCallback(customGrippoint.getMousePostion(parent, ev));
                //         }
                //         this.enableCursorSprite(false);
                //     }
                // };
            }
        };
    }

    async updateCursorSprite(event: MouseEvent | Communicator.Point2): Promise<void> {
        if (this._cursorSprite && this.cursorSpriteOpe) {
            this.enableCursorSprite(true);
            await this.cursorSpriteOpe.updateCursorSpriteImpl(
                new Communicator.Point2(event.x, event.y),
                true);
        }
    }

    enableCursorSprite(enable: boolean): void {
        if (this.cursorSpriteOpe) {
            this.cursorSpriteOpe.enableCursorMarkup(enable);
        }
    }
    getMousePostion(elem: HTMLElement, event: MouseEvent): Communicator.Point2 {
        const rect = elem.getBoundingClientRect();
        const left = rect.x + window.pageXOffset;
        const top = rect.y + window.pageYOffset;

        return new Communicator.Point2(event.clientX - left, event.clientY - top);
    }

    setPosition(point: Communicator.Point2): void {
        if (!this.gripPoint) return;
        this.gripPoint.style.left = `${point.x}px`;
        this.gripPoint.style.top = `${point.y}px`;
        this.currentPosition = point;
    }
    getGrippointElement(): HTMLElement {
        return this.gripPoint!;
    }
    setVisibleGripPoints(vis: boolean): void {
        if (this.gripPoint) this.gripPoint.style.display = vis ? 'initial' : 'none';
    }
    public get center(): Communicator.Point2 {
        return this._center;
    }
    public set center(point: Communicator.Point2) {
        this._center = point;
    }
    public get rotation(): number {
        return this._rotation;
    }
    public set rotation(rotation: number) {
        this._rotation = rotation;
    }
    public get gripSize(): number {
        return this._gripSize;
    }
    getAllSelectedMarkup(): MarkupBaseItem[] {
        const allSelectedMarkups: MarkupBaseItem[] = this.viewer.markupManager._getItemManager()._markupItems.toJSON()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((v: any[]) => v[1])
            .filter((v: MarkupBaseItem) => v.isSelected);
        return allSelectedMarkups;
    }
    setVisible(vis: boolean): void {
        if (!this.gripPoint) return;
        this.gripPoint.style.display = vis ? 'initial' : 'none';
    }
}
