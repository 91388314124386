import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IconName } from "common/define-name-icon";
import { ModeRightPanel } from "common/type-state";

export interface ModeRender {
    nameIcon: IconName;
    mode: ModeRightPanel;
    title: string;
    active: boolean
}

const initState: ModeRender[] = [
    { nameIcon: 'objProperty', mode: 'ObjectProperties', title: 'Object Properties', active: true },
    { nameIcon: 'sheets', mode: 'sheets', title: 'Sheets', active: true },
    { nameIcon: 'edit', mode: 'markups', title: 'Markups', active: true },
    { nameIcon: 'drive', mode: 'drive', title: 'Drive', active: false },
    { nameIcon: 'bidsters', mode: 'bidsters', title: 'Bidsters', active: false },
    { nameIcon: 'grit', mode: 'grit', title: 'Grit', active: false },
    { nameIcon: 'measureP2P', mode: 'measurement', title: 'Measurement', active: true },
    { nameIcon: 'markupStamp', mode: 'stamp', title: 'Stamp', active: true },
    { nameIcon: 'markupSignature', mode: 'signatures', title: 'Signature', active: true },
    { nameIcon: 'markupLineFormat', mode: 'formBuilder', title: 'Form Builder', active: false },
    { nameIcon: 'pushpin', mode: 'pinMarkers', title: 'Pin Marker', active: true },
]
const panelAppSlice = createSlice({
    name: 'panelApp',
    initialState: initState,
    reducers: {
        setPanelApp: (state, action: PayloadAction<ModeRightPanel[]>) => {
            state.forEach(app => {
                if (action.payload.includes(app.mode)) {
                    app.active = true
                } else {
                    app.active = false
                }
            })
        }
    }
})

export const {
    setPanelApp
} = panelAppSlice.actions;

export default panelAppSlice.reducer
export { }
