/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileInfo, FilesTree, TreeNode } from "common/define";
import { GlobalState } from "common/global";
import { TreeDataExtra } from "common/type-state";
import { workers } from "helper/worker.helper";
import { Observable } from "rxjs";
import Utils from "utils/utils";

export type GetExpandedKeysType = {
    node: number,
    modelFileId: ModelFileId,
    viewId: ViewId,
    isModeCombine: boolean
}
export type TreeConvertType = 'default' | 'category';
type TypeTreeWorker = 
'CONVERT' | 
'GET_PARENT_LIST' | 
'OFFSET_TREE_COMBINE' |
'FIND_NODE_TREE' |
'CHECK_ARR_NODE';

interface TypeCreatorTreeWorker {
    type: TypeTreeWorker;
    props: {[key: string]: unknown};
}
const creatorTreeWorker: {[key: string]: (data: any, modelFileId?: ModelFileId) => TypeCreatorTreeWorker} = {
    convertFromResponse: (data: any, modelFileId?: ModelFileId) => ({
        type: 'CONVERT',
        props: {
            modelFileId,
            data
        }
    }),
    offsetTreeCombine: (offset: number, modelFileId?: ModelFileId) => ({
        type: 'OFFSET_TREE_COMBINE',
        props: {
            modelFileId,
            offset
        }
    }),
    getParentList: (data: GetParentListCombineByNode) => ({
        type: 'GET_PARENT_LIST',
        props: {data}
    }),
    findNode: (data: FindNode) => ({
        type: 'FIND_NODE_TREE',
        props: {data}
    }),
    checkArrNode: (data: CheckArrNode) => ({
        type: 'CHECK_ARR_NODE',
        props: {data}
    })
}

function postTreeWorker<T>(type: TypeTreeWorker, creator: TypeCreatorTreeWorker): Observable<T> {
    return new Observable(obser => {
        const treeWorker = workers.tree();
        if (treeWorker) {
            treeWorker.postMessage(creator);
            treeWorker.onmessage = ((res) => {
                const resultWorker = res.data;
                if (resultWorker && resultWorker.type === type) {
                    let emit = resultWorker.data;
                    if (resultWorker.extra) {
                        emit = {
                            data: resultWorker.data,
                            extra: resultWorker.extra
                        }
                    }
                    obser.next(emit);
                    obser.complete()
                } else {
                    obser.complete()
                }
            });
            treeWorker.onerror = (err) => {
                obser.error(err);
                obser.complete()
            }
        } else {
            obser.error();
            obser.complete()
        }
    })
}

/** function exports */
export function mapTreeWorker(treeResponse: FilesTree, modelFileId: ModelFileId, type: TreeConvertType = 'default'): Observable<TreeDataExtra> {
    const data = {
        treeInput: Array.isArray(treeResponse) ? treeResponse : [treeResponse],
        type
    }
    const creator = creatorTreeWorker.convertFromResponse(data, modelFileId);
    return postTreeWorker(creator.type, creator)
}
export function offsetTreeCombine(offset: number, modelFileId: ModelFileId): Observable<TreeNode[]> {
    const creator = creatorTreeWorker.offsetTreeCombine(offset, modelFileId);
    return postTreeWorker(creator.type, creator)
}
export function getParentList(data: GetParentListCombineByNode): Observable<number[]> {
    const creator = creatorTreeWorker.getParentList(data);
    return postTreeWorker(creator.type, creator)
}
export function findNode(data: FindNode): Observable<number | null> {
    const creator = creatorTreeWorker.findNode(data);
    return postTreeWorker(creator.type, creator)
}
export function checkArrNode(data: CheckArrNode): Observable<number[]> {
    const creator = creatorTreeWorker.checkArrNode(data);
    return postTreeWorker(creator.type, creator)
}
export function treeActiveId(viewId: string, fileListOrigin: FileInfo[]) {
    if (fileListOrigin.length > 0) {
        const fileInfo = fileListOrigin.find(f => f.viewId === viewId);
        if (fileInfo) {
            const extension = Utils.getFileExtension(fileInfo.originalFile);
            if (extension === 'rvt') {
                const viewIdFinal = GlobalState.getViewId(viewId);
                const fileInfoFinal = fileListOrigin.find(f => f.viewId === viewIdFinal);
                return {
                    modelFileId: fileInfoFinal?.modelFileId,
                    fileInfo: fileInfoFinal
                }
            }
            return {
                modelFileId: fileInfo?.modelFileId,
                fileInfo: fileInfo
            }
        }
    }
    return {
        modelFileId: undefined,
        fileInfo: undefined
    }
}
